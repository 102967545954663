
import { defineComponent } from '@vue/composition-api';
import DaypartTable from './components/planning/dayparts/DaypartTable.vue';
import { useEstablishments } from '@/util/establishments';

export default defineComponent({
  components: {
    DaypartTable,
  },
  setup() {
    const { establishment } = useEstablishments();

    return {
      establishment,
    };
  },
});
