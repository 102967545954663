

























































































import { Vue, Component } from 'vue-property-decorator';
import { format, getWeek, subWeeks, addWeeks, startOfWeek, endOfWeek } from 'date-fns';
import { nl } from 'date-fns/locale';
import journalApi from '@/api/journalApi';
import { findByJournal } from '@/api/shiftReviews';
import * as shiftApi from '@/api/shifts';
import { Journal, ShiftReview, JournalNote, Shift, IncidentReport } from '@/common';

interface JournalsTableRow {
  date: string;
  day: string;
  daypart: string;
  rating: {
    icon: string;
    variant: string;
  };
  labour: number;
  incidents: number;
  notes: {
    danger: number;
    warning: number;
    success: number;
  };
  employees: {
    danger: number;
    success: number;
  };
}

@Component
export default class JournalsView extends Vue {
  public loading = false;
  public errorMessage = '';
  public date = new Date();
  public today = this.formatDate(this.date);
  public week = getWeek(new Date(this.today));
  public startWeek = this.formatDate(startOfWeek(this.date, { weekStartsOn: 1 }));
  public endWeek = this.formatDate(endOfWeek(this.date, { weekStartsOn: 1 }));

  public journals: Journal[] = [];

  public shiftReviews: Record<number, ShiftReview[]> = {};

  public shifts: Record<number, Shift[]> = {};

  public notes: Record<number, JournalNote[]> = {};

  public incidents: Record<number, IncidentReport[]> = {};

  public establishmentId!: number;

  public fields = [
    {
      key: 'rating',
      label: '',
      sortable: false,
    },
    {
      key: 'date',
      label: 'Datum',
      sortable: true,
    },
    {
      key: 'day',
      label: 'Dag',
      sortable: true,
    },
    {
      key: 'daypart',
      label: 'Dagdeel',
      sortable: true,
    },
    {
      key: 'employees',
      label: 'Medewerkers',
      sortable: false,
    },
    {
      key: 'notes',
      label: 'Opmerkingen',
      sortable: false,
    },
    {
      key: 'incidents',
      label: 'Acties',
      sortable: false,
    },
    {
      key: 'open',
      label: '',
      sortalbe: false,
    },
  ];

  public created() {
    this.establishmentId = Number(this.$route.params?.establishmentId);
    if (!this.establishmentId) {
      this.$router.push({ name: 'LandingsPage' });
    }
  }

  public async mounted() {
    try {
      this.loading = true;
      await this.getJournals();
    } catch (e) {
      this.errorMessage = e;
    } finally {
      this.loading = false;
    }
  }

  get last() {
    return this.week === getWeek(new Date()) ? true : false;
  }

  public getDay(dob: string) {
    return format(new Date(dob), 'EEEE', { locale: nl });
  }

  public getDate(dob: string) {
    return format(new Date(dob), 'yyyy-MM-dd');
  }

  public formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd');
  }

  public async getJournals() {
    this.journals = await journalApi.getByDateRange(
      this.establishmentId,
      this.startWeek,
      this.endWeek,
    );
    this.shiftReviews = {};
    this.shifts = {};
    this.notes = {};
    for (const journal of this.journals) {
      const id = journal.id;
      this.shiftReviews[id] = await findByJournal(id);
      this.shifts[id] = await shiftApi.search({
        establishmentId: this.establishmentId,
        date: journal.dob,
      });
      this.notes[id] = await journalApi.getNotes(id);
      this.incidents[id] = await journalApi.getIncidents(id);
    }
  }

  get chart() {
    const chart: JournalsTableRow[] = [];
    this.journals.forEach(journal => {
      const tableRow: JournalsTableRow = {
        date: this.formatDate(new Date(journal.dob)),
        day: this.getDay(journal.dob),
        daypart: journal.name,
        rating: this.getRating(journal.rating),
        incidents: this.incidentsCount(this.incidents[journal.id]),
        notes: this.getNotesOverview(this.notes[journal.id]),
        employees: this.getShiftReviewsOverview(this.shiftReviews[journal.id]),
        labour: 30,
      };
      chart.push(tableRow);
    });
    return chart;
  }

  public incidentsCount(incidents: IncidentReport[]) {
    let incidentCount = 0;
    if (incidents != undefined) {
      incidentCount = incidents.length;
    }
    return incidentCount;
  }

  public getShiftReviewsOverview(reviews: ShiftReview[]) {
    const overview = {
      danger: 0,
      success: 0,
    };
    reviews.forEach(review => {
      if (review.rating === 0) {
        overview.danger = overview.danger + 1;
      }
      if (review.rating === 2) {
        overview.success = overview.success + 1;
      }
    });
    return overview;
  }

  public getNotesOverview(notes: JournalNote[]) {
    const overview = {
      danger: 0,
      warning: 0,
      success: 0,
    };
    notes.forEach(note => {
      if (note.rating === 0) {
        overview.danger = overview.danger + 1;
      }
      if (note.rating === 1) {
        overview.warning = overview.warning + 1;
      }
      if (note.rating === 2) {
        overview.success = overview.success + 1;
      }
    });
    return overview;
  }

  public getRating(rating: number | undefined | null) {
    const result = {
      icon: '',
      variant: '',
    };

    if (rating === 0) {
      result.icon = 'emoji-frown';
      result.variant = 'danger';
    }
    if (rating === 1 || rating === null || rating === undefined) {
      result.icon = 'emoji-smile';
      result.variant = 'warning';
    }
    if (rating === 2) {
      result.icon = 'emoji-laughing';
      result.variant = 'success';
    }
    return result;
  }

  public prevWeek() {
    this.date = subWeeks(new Date(this.today), 1);
    this.today = this.formatDate(this.date);
    this.week = getWeek(this.date);
    this.startWeek = this.formatDate(startOfWeek(this.date, { weekStartsOn: 1 }));
    this.endWeek = this.formatDate(endOfWeek(this.date, { weekStartsOn: 1 }));
    this.getJournals();
  }

  public nextWeek() {
    this.date = addWeeks(new Date(this.today), 1);
    this.today = this.formatDate(this.date);
    this.week = getWeek(this.date);
    this.startWeek = this.formatDate(startOfWeek(this.date, { weekStartsOn: 1 }));
    this.endWeek = this.formatDate(endOfWeek(this.date, { weekStartsOn: 1 }));
    this.getJournals();
  }
}
