












































































































































































































































































































import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { InvoiceableReceipt, Reservation } from '@/common/types';
import InvoiceFormReservations from './InvoiceFormReservations.vue';
import CheckInfo from './CheckInfo.vue';
import { Form } from './types';

@Component({
  components: { ValidationObserver, ValidationProvider, CheckInfo, InvoiceFormReservations },
})
export default class InvoiceForm extends Vue {
  @Prop({ required: true })
  public form!: Form;

  @Prop({ required: true })
  public establishmentId!: number;

  @Prop({ required: true })
  public dob!: string;

  @Prop({ required: true })
  public receipt!: InvoiceableReceipt;

  @Emit('submit')
  public submit() {
    return this.form;
  }

  public selectReservation(reservation: Reservation) {
    if (reservation !== null) {
      this.form.name = reservation.companyName;
      this.form.contact = reservation.firstName + ' ' + reservation.lastName;
      this.form.phone = reservation.phoneNumber;
      this.form.email = reservation.email;
      this.form.costCenter = reservation.costCenter;
      this.form.department = reservation.department;
    }
  }
}
