
import { Establishment, OccupationEmployeePlanning } from '@/common';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useOccupationEmployeePlanning } from '@/composables';
import { useEstablishments } from '@/util/establishments';

interface Week {
  name: string;
  dayNumber: number;
}

interface Ranges {
  id?: number;
  name: string;
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
}

export default defineComponent({
  props: {
    week: {
      type: Array as PropType<Week[]>,
      required: true,
    },
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
    rangeName: {
      type: String,
      required: true,
    },
    rangeId: {
      type: Number,
      required: true,
    },
    departmentId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { error, isLoading, create, update } = useOccupationEmployeePlanning();
    const { reload } = useEstablishments();
    const edit = ref<boolean>(false);
    const ranges = ref<Ranges>({
      name: props.rangeName,
      sun: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
    });

    function resetRanges() {
      if (props.establishment.occupationPlanning) {
        const rangesValues = props.establishment.occupationPlanning.find(range => {
          if (
            range.occupanceRangeId === props.rangeId &&
            range.departmentId === props.departmentId
          ) {
            return range;
          }
        });
        if (rangesValues) {
          ranges.value.id = rangesValues.id;
          ranges.value.sun = rangesValues.sun;
          ranges.value.mon = rangesValues.mon;
          ranges.value.tue = rangesValues.tue;
          ranges.value.wed = rangesValues.wed;
          ranges.value.thu = rangesValues.thu;
          ranges.value.fri = rangesValues.fri;
          ranges.value.sat = rangesValues.sat;
        }
      }
    }
    resetRanges();

    const data = ref<OccupationEmployeePlanning>();
    async function submit() {
      data.value = {
        id: ranges.value.id ?? undefined,
        establishmentId: props.establishment.id,
        departmentId: props.departmentId,
        occupanceRangeId: props.rangeId,
        sun: ranges.value.sun ?? 0,
        mon: ranges.value.mon ?? 0,
        tue: ranges.value.tue ?? 0,
        wed: ranges.value.wed ?? 0,
        thu: ranges.value.thu ?? 0,
        fri: ranges.value.fri ?? 0,
        sat: ranges.value.sat ?? 0,
      };
      if (data.value.id) {
        await update(props.establishment.id, data.value);
      } else {
        await create(props.establishment.id, data.value);
      }
      if (error.value.search) {
        emit('error', error.value.search);
      } else {
        reload();
        emit('success', 'succesvol opgeslagen');
        edit.value = false;
      }
    }

    return {
      ranges,
      props,
      edit,
      error,
      isLoading,
      submit,
    };
  },
});
