
import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    message: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const show = ref(false);
    const dismissSecs = ref<number>(3);
    const dismissCountdown = ref<number>(0);

    watch(
      () => props.message,
      message => {
        if (message) {
          dismissCountdown.value = dismissSecs.value;
        }
      },
    );

    function countDownChanged(countdown: number) {
      dismissCountdown.value = countdown;
    }

    return {
      show,
      props,
      dismissCountdown,
      dismissSecs,
      countDownChanged,
    };
  },
});
