






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Reservation } from '@/common';

interface ReservationData {
  dinner: number;
  lunch: number;
  drinks: number;
  highTea: number;
  highWineBeer: number;
  other: number;
  reservations: Reservation[];
}

@Component
export default class ReservationComponent extends Vue {
  @Prop({ required: true })
  public reservations!: ReservationData;

  public error = '';
}
