

















import { defineComponent, computed } from '@vue/composition-api';
import zip from 'lodash/zip';

export default defineComponent({
  model: {
    prop: 'rating',
    event: 'update',
  },
  props: {
    rating: {
      type: Number,
      default: undefined,
    },
    icons: {
      type: Array,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    fontScale: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => {
        return props.rating;
      },
      set: value => {
        emit('update', value);
      },
    });

    const options = computed(() => {
      return zip(props.values, props.icons, props.variants);
    });

    return {
      model,
      options,
    };
  },
});
