import client from '@/api/client';
import { ref } from '@vue/composition-api';
import { Journal } from '@/common';

const API_URL = '/journals';

export default function useJournals() {
  const loading = ref(false);
  const error = ref('');
  const journal = ref<Journal | null>(null);

  const fetchJournal = async (id: number) => {
    if (loading.value) {
      return;
    }

    loading.value = true;
    error.value = '';
    journal.value = null;

    try {
      const response = await client.get(`${API_URL}/${id}`);
      journal.value = response.data;
    } catch (e) {
      error.value = `Error fetching data. ${e}`;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    error,
    journal,
    fetchJournal,
  };
}
