import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import { Incident } from '@/common';

export interface IncidentState {
  incidents: Record<number, Incident>;
  cache: Record<string, number[]>;
}

const state: IncidentState = {
  incidents: {},
  cache: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
} as Module<IncidentState, RootState>;
