





























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import WeatherWidget from './widgets/WeatherWidget.vue';
import ReservationWidget from './widgets/ReservationWidget.vue';
import PlanningWidget from './widgets/PlanningWidget.vue';
import Sidebar from './sidebar/Sidebar.vue';
import Startup from './startup/Startup.vue';
import ShiftReviews from './shiftReview/ShiftReviews.vue';
import IncidentReports from './incidentReports/IncidentReports.vue';
import JournalNotes from './journalNotes/JournalNotes.vue';
import Shutdown from './shutdown/Shutdown.vue';
import { findById } from '@/api/journals';
import { Journal } from '@/common';

@Component({
  components: {
    WeatherWidget,
    ReservationWidget,
    PlanningWidget,
    Sidebar,
    Startup,
    ShiftReviews,
    IncidentReports,
    JournalNotes,
    Shutdown,
  },
})
export default class JournalOverview extends Vue {
  @Prop({ required: true })
  public journalId!: number;

  public journal: Journal | null = null;

  @Watch('journalId', { immediate: true })
  public async onJournalIdChange() {
    this.journal = await findById(this.journalId);
  }

  get establishment() {
    return this.journal?.establishment;
  }
}
