import { computed, ref, watch } from '@vue/composition-api';
import { useGetters, useMutations } from '@u3u/vue-hooks';
import { MutationTypes } from '@/store/modules/tags/mutation-types';
import {
  createFloorplanTag,
  createIncidentReportTag,
  createShiftReviewTag,
  findAllFloorplanTags,
  findAllIncidentReportTags,
  findAllShiftReviewTags,
  mergeTags,
} from '@/api/tags';
import { Tag, TagType } from '@/common';
import { UseTags, UseTagsErrors, UseTagsSearchParams, UseTagsMergeCommand } from './types';

export function useTags(cacheId: string): UseTags {
  const { all, fromCache } = useGetters('tags', ['all', 'fromCache']);
  const { setTags, addTags, removeTags } = useMutations('tags', {
    setTags: MutationTypes.SET_TAGS,
    addTags: MutationTypes.ADD_TAGS,
    removeTags: MutationTypes.REMOVE_TAGS,
  });

  const tags = ref<Tag[]>([]);
  const isLoading = ref(false);
  const error = ref<UseTagsErrors>({});

  watch(all, () => (tags.value = fromCache.value(cacheId)), { immediate: true });

  const search = async (searchParams: UseTagsSearchParams) => {
    try {
      isLoading.value = true;
      let payload: Tag[] = [];
      switch (searchParams.tagType) {
        case 'INCIDENT_REPORT':
          payload = await findAllIncidentReportTags(searchParams.establishmentId);
          break;
        case 'SHIFT_REVIEW':
          payload = await findAllShiftReviewTags(searchParams.establishmentId);
          break;
        case 'FLOORPLAN':
          payload = await findAllFloorplanTags(searchParams.establishmentId);
          break;
      }
      setTags({ cacheId, payload });
      error.value.search = undefined;
    } catch (e) {
      error.value.search = e.response.data;
    } finally {
      isLoading.value = false;
    }
  };

  const create = async (establishmentId: number, tag: string, type: TagType) => {
    try {
      isLoading.value = true;
      error.value.create = undefined;

      let payload: Tag | undefined;
      switch (type) {
        case 'INCIDENT_REPORT':
          payload = await createIncidentReportTag(establishmentId, tag);
          addTags({ cacheId, payload });
          break;
        case 'SHIFT_REVIEW':
          payload = await createShiftReviewTag(establishmentId, tag);
          addTags({ cacheId, payload });
          break;
        case 'FLOORPLAN':
          payload = await createFloorplanTag(establishmentId, tag);
          addTags({ cacheId, payload });
          break;
        default:
          payload = undefined;
      }
      return payload;
    } catch (e) {
      error.value.create = e.response.data;
    } finally {
      isLoading.value = false;
    }
  };

  const merge = async (mergeCommand: UseTagsMergeCommand) => {
    try {
      isLoading.value = true;
      await mergeTags(mergeCommand);
      removeTags({ cacheId, payload: mergeCommand.tagIds });
      error.value.merge = undefined;
    } catch (e) {
      error.value.merge = e.response.data;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    tags: computed(() => [...tags.value].sort((a, b) => a.name.localeCompare(b.name))),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
    create,
    merge,
  };
}
