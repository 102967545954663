import establishments from './establishments';
import employees from './employees';
import tags from './tags';
import incidents from './incidents';

export default {
  establishments,
  employees,
  tags,
  incidents,
};
