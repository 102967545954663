









import { defineComponent, PropType } from '@vue/composition-api';
import { Tag } from '@/common';
import { useTags } from '@/util/tags';

export default defineComponent({
  props: {
    tag: {
      type: Object as PropType<Tag>,
      required: true,
    },
  },
  setup() {
    const { isLoading, create, error } = useTags('settings');

    return {
      isLoading,
      error,
      create,
    };
  },
});
