import { computed } from '@vue/composition-api';

interface SizeableProps {
  xSmall: boolean;
  small: boolean;
  large: boolean;
  xLarge: boolean;
}

export const sizableProps = {
  xSmall: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  xLarge: {
    type: Boolean,
    default: false,
  },
};

export const useSizable = (props: SizeableProps) => {
  const medium = computed(() =>
    Boolean(!props.xSmall && !props.small && !props.large && !props.xLarge),
  );

  const sizableClasses = computed(() => ({
    'w-size--x-small': props.xSmall,
    'w-size--small': props.small,
    'w-size--medium': medium.value,
    'w-size--large': props.large,
    'w-size--x-large': props.xLarge,
  }));

  return {
    medium,
    sizableClasses,
  };
};
