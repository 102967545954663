

































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Journal } from '@/common/types';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import { DailyBalanceDto, DailyBalance } from '@/api/dailyBalanceApi';

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
}

interface TableRow {
  date: string;
  day: string;
  startup: IconObject;
  misc: IconObject;
  employees: IconObject;
  kitchen: IconObject;
  guests: IconObject;
  diff: string;
}

interface IconObject {
  icon: string;
  variant: string;
  comment: string;
}

const currency = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

@Component
export default class JournalsOverview extends Vue {
  @Prop({ required: true })
  public journals!: Journal[];

  @Prop({ required: true })
  public dailyBalanceData!: DailyBalanceDto[];

  public loading = false;
  public error = '';

  public day = true;
  public evening = false;
  public selectedDay = '';

  public fields: TableColumns[] = [
    {
      key: 'day',
      label: 'Dag',
      sortable: true,
    },
    {
      key: 'date',
      label: 'Datum',
      sortable: true,
    },
    {
      key: 'startup',
      label: 'Start',
      sortable: false,
    },
    {
      key: 'open',
      label: '',
      sortable: false,
    },
  ];

  public items: TableRow[] = [];

  @Watch('journals', { immediate: true })
  public onJournalsChange() {
    this.loadJournals('dag');
  }

  public async loadJournals(daypart: string) {
    this.selectedDay = daypart;
    if (daypart === 'dag') {
      this.day = true;
      this.evening = false;
    } else {
      this.day = false;
      this.evening = true;
    }
    this.items = [];
    this.journals.forEach(journal => {
      if (journal.name === daypart) {
        this.items.push({
          date: format(new Date(journal.dob), 'yyyy-MM-dd', { locale }),
          day: format(new Date(journal.dob), 'EEEE', { locale }),
          startup: this.getRatingIcon(journal.startUpRating, journal.startUpComment),
          misc: this.getRatingIcon(journal.closeMiscRating, journal.closeMiscComment),
          employees: this.getRatingIcon(journal.closeStaffRating, journal.closeStaffComment),
          kitchen: this.getRatingIcon(journal.closeKitchenRating, journal.closeKitchenComment),
          guests: this.getRatingIcon(journal.closeGuestsRating, journal.closeGuestsComment),
          diff: this.getDailyBalance(new Date(journal.dob)),
        });
      }
    });
  }

  public formatCurrency(val: number) {
    return currency.format(val);
  }

  public getDailyBalance(date: Date): string {
    const dob = format(date, 'yyyy-MM-dd') + 'T00:00:00.000Z';
    const balance = this.dailyBalanceData.filter(d => d.dob === dob)?.[0];
    if (balance) {
      const wrapper = new DailyBalance(balance);
      if (wrapper.getStoredDiffAmount()) {
        return String(wrapper.getDifferenceAmount());
      }
    }
    return '';
  }

  public getRatingIcon(rating: number | undefined | null, comment: string | undefined) {
    const result: IconObject = {
      icon: '',
      variant: '',
      comment: comment !== undefined ? comment : '',
    };

    if (rating === undefined || rating === null || rating < 0) {
      result.icon = 'emoji-smile';
      result.variant = 'secondary';
    } else if (rating === 0) {
      result.icon = 'emoji-frown';
      result.variant = 'danger';
    } else if (rating === 1) {
      result.icon = 'emoji-laughing';
      result.variant = 'success';
    }
    return result;
  }

  public isToday(date: string) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const checkDate = format(new Date(date), 'yyyy-MM-dd');
    return today === checkDate;
  }
}
