




















import { defineComponent } from '@vue/composition-api';
import { useEstablishments } from '@/util/establishments';

export default defineComponent({
  setup() {
    const { establishments } = useEstablishments();

    return {
      establishments,
    };
  },
});
