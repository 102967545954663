
import { Establishment, OccupanceRange } from '@/common';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import PlanningLimitTableRow from './PlanningLimitTableRow.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import SuccessMessage from '@/components/SuccessMessage.vue';

interface Week {
  name: string;
  day: string;
}

export default defineComponent({
  components: {
    PlanningLimitTableRow,
    ErrorMessage,
    SuccessMessage,
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
    daypartId: {
      type: Number,
      required: true,
    },
    departmentId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ranges = ref<OccupanceRange[]>([]);
    const success = ref('');
    const error = ref('');

    const week = ref<Week[]>([
      {
        name: 'zondag',
        day: 'sun',
      },
      {
        name: 'maandag',
        day: 'mon',
      },
      {
        name: 'dinsdag',
        day: 'tue',
      },
      {
        name: 'woensdag',
        day: 'wed',
      },
      {
        name: 'donderdag',
        day: 'thu',
      },
      {
        name: 'vrijdag',
        day: 'fri',
      },
      {
        name: 'zaterdag',
        day: 'sat',
      },
    ]);

    function reset() {
      if (props.establishment.occupanceRanges) {
        ranges.value = props.establishment.occupanceRanges?.filter(
          range => range.daypartId === props.daypartId,
        );
      }
    }
    reset();

    function submit() {
      emit('submit', ranges);
    }

    function updateSuccess(message: string) {
      success.value = message;
    }

    function updateError(message: string) {
      error.value = message;
    }

    return {
      week,
      props,
      ranges,
      submit,
      updateSuccess,
      updateError,
      success,
      error,
    };
  },
});
