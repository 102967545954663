












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Journal, Tag, IncidentReport } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';

interface IncidentObject {
  date: Date;
  subject: string;
  tags: Tag[] | undefined;
  incident: IncidentReport;
  name: string;
  establishmentId: number;
  dob: string;
}

interface IconObject {
  icon: string;
  variant: string;
}

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
}

@Component
export default class IncidentsOverview extends Vue {
  @Prop({ required: true })
  public journals!: Journal[];

  public loading = false;
  public error = '';

  public fields: TableColumns[] = [
    {
      key: 'date',
      label: 'Datum',
      sortable: true,
    },
    {
      key: 'subject',
      label: 'Onderwerp',
      sortable: false,
    },
    {
      key: 'tags',
      label: '',
      sortable: false,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
    },
  ];

  public async mounted() {
    try {
      this.loading = true;
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  get incidents() {
    const incidents: IncidentObject[] = [];
    this.journals.forEach(journal => {
      if (journal.incidentReports) {
        journal.incidentReports.forEach(report => {
          incidents.push({
            date: new Date(journal.dob),
            subject: report.incident?.name ?? '',
            tags: report.tags,
            incident: report,
            name: journal.name,
            establishmentId: journal.establishmentId,
            dob: format(new Date(journal.dob), 'yyyy-MM-dd'),
          });
        });
      }
    });
    return incidents;
  }

  public getRatingIcon(rating: number | undefined | null) {
    const result: IconObject = {
      icon: '',
      variant: '',
    };

    if (rating === undefined || rating === null) {
      result.icon = 'emoji-smile';
      result.variant = 'secondary';
    } else if (rating === 0) {
      result.icon = 'emoji-frown';
      result.variant = 'danger';
    } else if (rating === 1) {
      result.icon = 'emoji-laughing';
      result.variant = 'success';
    }
    return result;
  }

  public formatDate(date: Date) {
    return format(date, 'EEEE', { locale });
  }

  public formatDateTime(date: string) {
    return format(new Date(date), 'dd-MM-yyyy H:mm:s');
  }
}
