




import { defineComponent, computed } from '@vue/composition-api';
import { sizableProps, useSizable } from '@/components/composables/sizable';

export default defineComponent({
  props: {
    icon: {
      required: true,
      type: String,
    },
    ...sizableProps,
  },
  setup(props) {
    const { sizableClasses } = useSizable(props);

    const classes = computed(() => ({
      wi: true,
      [`wi-${props.icon}`]: true,
      ...sizableClasses.value,
    }));

    return {
      classes,
    };
  },
});
