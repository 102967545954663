









































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { getReservations } from '@/api/reservations';
import { Reservation } from '@/common/types';
import { format, addDays } from 'date-fns';

@Component
export default class InvoiceFormReservations extends Vue {
  @Prop({ required: true })
  public establishmentId!: number;

  @Prop({ required: true })
  public dob!: string;

  public loading = false;
  public error = '';

  public reservations: Reservation[] = [];
  public filter: string | null = null;
  public perPage = 10;
  public currentPage = 1;

  public selectedReservation: Reservation | null = null;

  public fields = [
    {
      key: 'select',
      sortable: false,
    },
    {
      key: 'reservationType',
      sortable: true,
    },
    {
      key: 'reservationType',
      sortable: true,
    },
    {
      key: 'lastName',
      sortable: true,
    },
    {
      key: 'company',
      sortable: true,
    },
  ];

  public async mounted() {
    try {
      this.loading = true;
      await this.loadReservations();
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  public async loadReservations() {
    const from = format(new Date(this.dob), 'yyyy-MM-dd');
    const to = format(addDays(new Date(this.dob), 1), 'yyyy-MM-dd');
    this.reservations = await getReservations(this.establishmentId, from, to);
  }

  @Emit('selectedReservation')
  public selectReservation(reservation: Reservation) {
    return reservation;
  }
}
