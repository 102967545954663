
import { defineComponent } from '@vue/composition-api';
import OccupancyView from './components/planning/occupancy/OccupancyView.vue';
import PlanningLimitView from './components/planning/limits/PlanningLimitView.vue';
import DaypartTable from './components/planning/dayparts/DaypartTable.vue';
import { useEstablishments } from '@/util/establishments';

export default defineComponent({
  components: {
    DaypartTable,
    OccupancyView,
    PlanningLimitView,
  },
  setup() {
    const { establishment } = useEstablishments();

    return {
      establishment,
    };
  },
});
