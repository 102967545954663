























































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { useAsync } from 'vue-async-function';
import useInterval from '@/util/interval';
import useAxios from '@/util/axios';
import { distanceToNow } from '@/util/date';
import useNumber from '@/util/number';
import useWeather from '@/util/weather';
import { getCity, getCurrentWeather } from '@/api/weather';
import { CurrentForecastWeatherData } from '@/api/weather/types';
import { Establishment } from '@/common';

export default defineComponent({
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props) {
    const { isCancelError } = useAxios();
    const { round } = useNumber();
    const { ms2Kmh, ms2Bft } = useWeather();

    const cityId = ref();
    const lastUpdated = ref<Date>();
    const lastUpdatedText = ref('');
    const weatherData = ref<CurrentForecastWeatherData>();

    const { data: city } = useAsync(getCity, cityId);

    watch(
      () => props.establishment,
      id => {
        if (id) {
          cityId.value = props.establishment.weatherCityId;
        }
      },
      { immediate: true },
    );

    function updateLastUpdatedText() {
      if (lastUpdated.value) {
        const distance = distanceToNow(lastUpdated.value, { addSuffix: true });
        lastUpdatedText.value = `${distance} geüpdatet`;
      }
    }

    async function loadWeatherData(id?: number) {
      if (!id) return;
      return await getCurrentWeather(id);
    }

    const { isLoading, error, data, retry } = useAsync(loadWeatherData, props.establishment.id);

    useInterval(() => retry(), 1000 * 60 * 10);
    useInterval(() => updateLastUpdatedText(), 1000 * 60);

    watch(data, data => {
      lastUpdated.value = new Date();
      weatherData.value = data;
      updateLastUpdatedText();
    });

    const errorMessage = computed(() => {
      if (!error.value || isCancelError(error.value)) {
        return '';
      }
      return error.value.message;
    });

    const precipitation = computed(() => {
      if (!weatherData.value) {
        return null;
      }

      const rain = weatherData.value.current.rain?.['1h'] ?? 0;
      const snow = weatherData.value.current.snow?.['1h'] ?? 0;

      return Math.ceil(Math.max(rain, snow));
    });

    const weatherIcon = computed(() => {
      if (!weatherData.value) {
        return null;
      }
      return `owm-${weatherData.value.current.weather[0].id}`;
    });

    const radarUrl = computed(() => {
      return `https://gadgets.buienradar.nl/gadget/zoommap/?lat=${city.value?.lat}&lng=${city.value?.lon}&overname=2&zoom=11&size=3&voor=1`;
    });

    const wind = computed(() => {
      const val = weatherData.value?.current.wind_speed;
      const unit = props.establishment.weatherWindUnit ?? 'MS';

      if (!val) {
        return 'nb';
      }

      switch (unit) {
        case 'KMH':
          return ms2Kmh(val);
        case 'BFT':
          return ms2Bft(val);
        default:
          return Math.round(val * 10) / 10;
      }
    });

    const windUnit = computed(() => {
      const unit = props.establishment.weatherWindUnit ?? 'MS';

      switch (unit) {
        case 'KMH':
          return 'km/h';
        case 'BFT':
          return 'bft';
        default:
          return 'ms';
      }
    });

    return {
      city,
      round,
      wind,
      windUnit,
      isLoading,
      errorMessage,
      weatherData,
      precipitation,
      weatherIcon,
      lastUpdatedText,
      radarUrl,
    };
  },
});
