

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Journal, JournalNote } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';

interface NoteObject {
  date: Date;
  id: number;
  subject: string;
  comment: string;
  rating: {
    rating: number;
    icon: IconObject;
  };
  note: JournalNote;
}

interface IconObject {
  icon: string;
  variant: string;
}

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
}

interface TableRow {
  date: string;
  subject: string;
  comment: string;
  rating: number;
}

@Component
export default class NotesOverview extends Vue {
  @Prop({ required: true })
  public journals!: Journal[];

  public loading = false;
  public error = '';
  public showModal = false;
  public selectedNote: JournalNote = {
    id: 0,
    journalId: 0,
    subject: '',
    comment: '',
    rating: 0,
  };

  public fields: TableColumns[] = [
    {
      key: 'date',
      label: 'Datum',
      sortable: true,
    },
    {
      key: 'rating',
      label: '',
      sortable: true,
    },
    {
      key: 'subject',
      label: 'Onderwerp',
      sortable: false,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
    },
  ];

  get notes() {
    const notes: NoteObject[] = [];
    this.journals.forEach(journal => {
      if (journal.journalNotes) {
        journal.journalNotes.forEach(note => {
          notes.push({
            id: note.id,
            date: new Date(journal.dob),
            subject: note.subject,
            comment: note.comment,
            rating: {
              rating: note.rating,
              icon: this.getRatingIcon(note.rating),
            },
            note: note,
          });
        });
      }
    });
    return notes;
  }

  public openModal(data: JournalNote) {
    this.selectedNote = data;
    this.showModal = true;
  }

  public getRatingIcon(rating: number | undefined | null) {
    const result: IconObject = {
      icon: '',
      variant: '',
    };

    if (rating === undefined || rating === null) {
      result.icon = 'emoji-smile';
      result.variant = 'secondary';
    } else if (rating === 1) {
      result.icon = 'emoji-frown';
      result.variant = 'danger';
    } else if (rating === 5) {
      result.icon = 'emoji-laughing';
      result.variant = 'success';
    }
    return result;
  }

  public formatDate(date: Date) {
    return format(date, 'EEEE', { locale });
  }
}
