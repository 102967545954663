
import { defineComponent, PropType } from '@vue/composition-api';

export interface ScoreIcon {
  icon: string;
  class?: string;
  comment?: string;
}

export default defineComponent({
  props: {
    score: {
      type: Object as PropType<ScoreIcon>,
    },
  },
  setup() {
    return {};
  },
});
