
import { useRouter } from '@u3u/vue-hooks';
import { computed, defineComponent } from '@vue/composition-api';
import TagsTable from './components/tags/TagsTable.vue';

export default defineComponent({
  components: {
    TagsTable,
  },
  setup() {
    const { route } = useRouter();
    const establishmentId = computed(() => Number(route.value.params?.establishmentId ?? 0));

    return {
      establishmentId,
    };
  },
});
