import Vue from 'vue';
import { Tag } from '@/common';
import { MutationTree } from 'vuex';
import { TagState } from '.';
import { MutationTypes } from './mutation-types';

export default {
  [MutationTypes.SET_TAGS](state, { cacheId, payload }: { cacheId: string; payload: Tag | Tag[] }) {
    if (state.cache[cacheId]) {
      state.cache[cacheId].forEach(id => {
        Vue.delete(state.tags, id);
      });
    }

    const cache = new Set<number>();
    payload = ([] as Tag[]).concat(payload);

    payload.forEach(tag => {
      Vue.set(state.tags, tag.id, tag);
      cache.add(tag.id);
    });
    state.cache[cacheId] = Array.from(cache);
  },

  [MutationTypes.ADD_TAGS](state, { cacheId, payload }: { cacheId: string; payload: Tag | Tag[] }) {
    const cache = new Set(state.cache[cacheId]);
    payload = ([] as Tag[]).concat(payload);

    payload.forEach(tag => {
      Vue.set(state.tags, tag.id, tag);
      cache.add(tag.id);
    });
    state.cache[cacheId] = Array.from(cache);
  },

  [MutationTypes.REMOVE_TAGS](state, { payload }: { payload: number | number[] }) {
    payload = ([] as number[]).concat(payload);

    payload.forEach(id => {
      Object.values(state.cache).forEach(cache => {
        const idx = cache.indexOf(id);
        if (idx !== -1) {
          cache.splice(idx, 1);
        }
      });
      Vue.delete(state.tags, id);
    });
  },
} as MutationTree<TagState>;
