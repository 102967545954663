import Vue from 'vue';
import { MutationTree } from 'vuex';
import { IncidentState } from '.';
import { MutationTypes } from './mutation-types';
import { Incident } from '@/common';

export default {
  [MutationTypes.ADD_INCIDENTS](
    state,
    { cacheId, payload }: { cacheId: string; payload: Incident | Incident[] },
  ) {
    const cache = new Set(state.cache[cacheId]);
    payload = ([] as Incident[]).concat(payload);

    payload.forEach(incident => {
      Vue.set(state.incidents, incident.id, incident);
      cache.add(incident.id);
    });
    state.cache[cacheId] = Array.from(cache);
  },

  [MutationTypes.REMOVE_INCIDENTS](
    state,
    { cacheId, payload }: { cacheId: string; payload: number | number[] },
  ) {
    const cache = new Set(state.cache[cacheId]);
    payload = ([] as number[]).concat(payload);

    payload.forEach(id => cache.delete(id));
    state.cache[cacheId] = Array.from(cache);

    const cached = new Set();
    Object.values(state.cache).forEach(cache => cache.forEach(id => cached.add(id)));
    const orphans = Object.keys(state.incidents).filter(id => !cached.has(Number(id)));
    orphans.forEach(id => Vue.delete(state.incidents, id));
  },
} as MutationTree<IncidentState>;
