











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Journal } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';

interface QuestionObject {
  date: Date;
  misc: IconObject;
  guest: IconObject;
  staff: IconObject;
}

interface IconObject {
  icon: string;
  variant: string;
}

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
}

@Component
export default class QuestionsOverview extends Vue {
  @Prop({ required: true })
  public journals!: Journal[];

  public loading = false;
  public error = '';

  public day = true;
  public dinner = false;

  public questions: QuestionObject[] = [];

  public fields: TableColumns[] = [
    {
      key: 'date',
      label: 'Datum',
      sortable: true,
    },
    {
      key: 'misc',
      label: 'Algemeen',
      sortable: false,
    },
    {
      key: 'guest',
      label: 'Gasten',
      sortable: false,
    },
    {
      key: 'staff',
      label: 'Personeel',
      sortable: false,
    },
    {
      key: 'open',
      label: '',
      sortable: false,
    },
  ];

  public async mounted() {
    try {
      this.loading = true;
      if (this.day) {
        this.loadQuestions('lunch');
      }
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  public loadQuestions(daypart: string) {
    if (daypart === 'lunch') {
      this.day = true;
      this.dinner = false;
    } else {
      this.day = false;
      this.dinner = true;
    }
    this.questions = [];
    this.journals.forEach(journal => {
      if (journal.name === daypart) {
        this.questions.push({
          date: new Date(journal.dob),
          misc: this.getRatingIcon(journal.closeMiscRating),
          staff: this.getRatingIcon(journal.closeStaffRating),
          guest: this.getRatingIcon(journal.closeGuestsRating),
        });
      }
    });
  }

  public getRatingIcon(rating: number | undefined | null) {
    const result: IconObject = {
      icon: '',
      variant: '',
    };

    if (rating === undefined || rating === null) {
      result.icon = 'emoji-smile';
      result.variant = 'secondary';
    } else if (rating === 0) {
      result.icon = 'emoji-frown';
      result.variant = 'danger';
    } else if (rating === 1) {
      result.icon = 'emoji-laughing';
      result.variant = 'success';
    }
    return result;
  }

  public formatDate(date: Date) {
    return format(date, 'EEEE', { locale });
  }
}
