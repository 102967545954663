import client from '@/api/client';
import { OccupationEmployeePlanning } from '@/common';

const searchOccupanceDepartments = async (
  establishmentId: number,
): Promise<OccupationEmployeePlanning[]> => {
  const response = await client.get<OccupationEmployeePlanning[]>(
    `/establishments/${establishmentId}/occupance/planning`,
  );
  return response.data;
};

export default searchOccupanceDepartments;
