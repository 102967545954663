import client from '@/api/client';
import { OccupanceDepartmentData } from '@/api/types';
import { OccupanceDepartment } from '@/common';

const createOccupanceDepartment = async (
  establishmentId: number,
  data: OccupanceDepartmentData,
): Promise<OccupanceDepartment> => {
  const response = await client.post<OccupanceDepartment>(
    `/establishments/${establishmentId}/occupance/departments`,
    data,
  );
  return response.data;
};

export default createOccupanceDepartment;
