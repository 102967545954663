import client from '../client';
import { Receipt, InvoiceableReceipt } from '@/common';

export async function getReceipt(establishmentId: number, dob: string, checkId: string) {
  const result = await client.get<Receipt>(
    `/establishments/${establishmentId}/receipts/${dob}/${checkId}`,
  );
  return result.data;
}

export async function findInvoiceableReceipts(establishmentId: number, d1: string, d2: string) {
  const params = { d1, d2 };
  const result = await client.get<InvoiceableReceipt[]>(
    `/establishments/${establishmentId}/receipts/invoiceable`,
    {
      params,
    },
  );
  return result.data;
}
