
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Daypart, Establishment } from '@/common';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useEstablishmentSettings } from '@/composables';
import DaypartTableRow from './DaypartTableRow.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import SuccessMessage from '@/components/SuccessMessage.vue';

extend('required', required);

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
    DaypartTableRow,
    ErrorMessage,
    SuccessMessage,
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props) {
    const edit = ref(false);
    const formData = ref<Daypart[]>([]);
    const { error, isLoading, updateDaypartSettings } = useEstablishmentSettings();
    const saved = ref<string>();

    watch(isLoading, isLoading => {
      if (!isLoading) {
        saved.value = !error.value.daypart ? 'Succesvol opgeslagen' : undefined;
      } else {
        saved.value = undefined;
      }
    });

    function changeEdit(state?: boolean) {
      if (state) {
        edit.value = state;
      } else {
        edit.value = !edit.value;
      }
    }

    function reset() {
      if ((props.establishment.dayparts?.length ?? 0) > 0) {
        props.establishment.dayparts?.forEach(daypart => {
          formData.value.push({
            id: daypart.id,
            establishmentId: daypart.establishmentId,
            name: daypart.name,
            start: daypart.start,
            end: daypart.end,
          });
        });
      } else {
        formData.value = [
          {
            establishmentId: props.establishment.id,
            name: '',
            start: '',
            end: '',
          },
        ];
        changeEdit(true);
      }
    }
    reset();

    function addRow() {
      formData.value.push({
        establishmentId: props.establishment.id,
        name: '',
        start: '',
        end: '',
      });
      changeEdit(true);
    }

    function removeRow(index: number) {
      formData.value?.splice(index, 1);
    }

    function submit() {
      // formData.value.forEach((data, index) => {
      //   const startTime = data.start.split(':');
      //   const endTime = data.end.split(':');

      //   if (parseInt(endTime[0]) - parseInt(startTime[0]) < 0) {
      //     formData.value[index].end = 24 + +endTime[0] + ':' + endTime[1] + ':' + endTime[2];
      //   }
      // });
      updateDaypartSettings({
        establishmentId: props.establishment.id,
        dayparts: formData.value,
      });
      changeEdit(false);
    }

    return {
      error,
      isLoading,
      saved,
      formData,
      edit,
      props,
      submit,
      addRow,
      removeRow,
      changeEdit,
    };
  },
});
