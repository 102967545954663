




























import { defineComponent, watch, ref } from '@vue/composition-api';
import useJournalNotes from '@/hooks/useJournalNotes';
import JournalNoteForm from './JournalNoteForm.vue';

export default defineComponent({
  components: {
    JournalNoteForm,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const showForm = ref(false);

    const {
      journalNotes,
      isLoading,
      error,
      fetchJournalNotes,
      createJournalNote,
      updateJournalNote,
      deleteJournalNote,
    } = useJournalNotes(props.journalId);

    fetchJournalNotes();

    watch(journalNotes, () => (showForm.value = !journalNotes.value.length));

    return {
      showForm,
      journalNotes,
      isLoading,
      error,
      createJournalNote,
      updateJournalNote,
      deleteJournalNote,
    };
  },
});
