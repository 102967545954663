
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { TagType } from '@/common';
import { useTags } from '@/util/tags';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
    ErrorMessage,
  },
  props: {
    tagType: {
      type: String as PropType<TagType>,
      required: true,
    },
    establishmentId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { isLoading, create, error } = useTags(`settings-${props.tagType}`);
    const newTag = ref('');

    function submit() {
      create(props.establishmentId, newTag.value, props.tagType);
    }

    watch(isLoading, isLoading => {
      if (!isLoading && !error.value.create) {
        newTag.value = '';
      }
    });

    return {
      isLoading,
      newTag,
      submit,
      error,
    };
  },
});
