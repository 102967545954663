import Vue from 'vue';
import './plugins';
import router from './router';
import store from './store';
import './components';
import App from './App.vue';

Vue.config.productionTip = process.env.NODE_ENV === 'development';

const app = new Vue({
  router,
  store,
  render: h => h(App),
});

router.onReady(() => {
  app.$mount('#app');
});
