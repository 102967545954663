








































































































import EmployeeSelect from '@/components/employee/EmployeeSelect.vue';
import IncidentSelect from '@/components/incidentReport/IncidentSelect.vue';
import IncidentReportTagMultiSelect from '@/components/incidentReport/IncidentReportTagMultiSelect.vue';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useRouter } from '@u3u/vue-hooks';
import { IncidentReport, IncidentReportDto } from '@/common';
import useIncidentReports from '@/hooks/useIncidentReports';

extend('required', required);

export default defineComponent({
  components: {
    ValidationProvider,
    ValidationObserver,
    EmployeeSelect,
    IncidentSelect,
    IncidentReportTagMultiSelect,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
    entity: {
      type: Object as PropType<IncidentReport>,
      required: false,
    },
  },
  setup(props, { root }) {
    const validator = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const { route } = useRouter();
    const establishmentId = Number(route.value.params.establishmentId);
    const {
      loading,
      error,
      createIncidentReport,
      updateIncidentReport,
      deleteIncidentReport,
    } = useIncidentReports(props.journalId);

    const description = ref('');
    const action = ref('');
    const selectedIncident = ref<number>();
    const selectedTags = ref<number[]>([]);
    const selectedEmployee = ref<number>();

    watch(
      () => props.entity,
      entity => {
        if (entity) {
          description.value = entity.description ?? '';
          action.value = entity.action ?? '';
          selectedIncident.value = entity.incident?.id;
          selectedTags.value = entity.tags?.map(t => t.id) ?? [];
          selectedEmployee.value = entity.employee?.id;
        }
      },
      { immediate: true },
    );

    function submit() {
      const data: IncidentReportDto = {
        description: description.value,
        action: action.value,
        incidentId: selectedIncident.value,
        employeeId: selectedEmployee.value,
        tags: selectedTags.value,
      };

      if (props.entity) {
        updateIncidentReport(props.entity.id, data);
      } else {
        createIncidentReport(data);
        root.$emit('created');
        validator.value?.reset();
        description.value = '';
        action.value = '';
        selectedIncident.value = undefined;
        selectedTags.value = [];
        selectedEmployee.value = undefined;
      }
    }

    function confirmDelete() {
      root.$bvModal
        .msgBoxConfirm('Weet je zeker het incident wilt verwijderen?', {
          title: 'Bevestig verwijderen',
          okTitle: 'Verwijderen',
          okVariant: 'danger',
          cancelTitle: 'Annuleren',
        })
        .then(async confirmed => {
          if (confirmed && props.entity) {
            await deleteIncidentReport(props.entity.id);
          }
        });
    }

    return {
      validator,
      establishmentId,
      description,
      action,
      selectedIncident,
      selectedTags,
      selectedEmployee,
      loading,
      error,
      submit,
      confirmDelete,
    };
  },
});
