
import { OccupanceRange } from '@/common';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    occupancyRange: {
      type: Object as PropType<OccupanceRange>,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    function changeEdit() {
      emit('change-edit', !props.edit);
    }

    function removeRow() {
      emit('remove-row', props.index);
    }

    return {
      props,
      changeEdit,
      removeRow,
    };
  },
});
