import { Team } from '@/common';
import { ref, computed } from '@vue/composition-api';
import { UsePlanningTeams, UsePlanningTeamsErrors } from '../types';
import { searchTeams } from '@/api/';

const usePlanningTeams = (): UsePlanningTeams => {
  const teams = ref<Team[]>([]);
  const isLoading = ref(false);
  const error = ref<UsePlanningTeamsErrors>({});

  const search = async (establishmentId: number): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      teams.value = await searchTeams(establishmentId);
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    teams: computed(() => teams.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
  };
};

export default usePlanningTeams;
