import client from '@/api/client';

const removeOccupationEmployeePlanning = async (
  establishmentId: number,
  id: number,
): Promise<void> => {
  const response = await client.delete<void>(
    `/establishments/${establishmentId}/occupance/planning/${id}`,
  );
  return response.data;
};

export default removeOccupationEmployeePlanning;
