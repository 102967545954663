
































































































import ScoreIcon, { ScoreIcon as ScoreIconData } from './ScoreIcon.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ShiftReview } from '@/common';
import { format } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import ChefIcon from './icons/chef.svg';
import DishwasherIcon from './icons/dishwasher.svg';
import WaiterIcon from './icons/waiter.svg';
import ManagerIcon from './icons/manager.svg';
import CleaningIcon from './icons/cleaning.svg';
import MiseEnPlaceIcon from './icons/miseenplace.svg';

interface Employees {
  departmentName: string;
  teams: string[];
  employees: EmployeeObject[];
}

interface EmployeeObject {
  employeeId: number | undefined;
  name: string;
  shifts: number;
  rating: number;
  mon?: ScoreIconData;
  tue?: ScoreIconData;
  wed?: ScoreIconData;
  thu?: ScoreIconData;
  fri?: ScoreIconData;
  sat?: ScoreIconData;
  sun?: ScoreIconData;
}

interface TableColumns {
  key: string;
  label: string;
  sortable: boolean;
  variant: string;
  thClass?: string;
  tdClass?: string;
}

@Component({
  components: {
    ScoreIcon,
    ChefIcon,
    DishwasherIcon,
    WaiterIcon,
    ManagerIcon,
    CleaningIcon,
    MiseEnPlaceIcon,
  },
})
export default class EmployeesOverview extends Vue {
  @Prop({ required: true, type: Array as () => ShiftReview[] })
  public shiftReviews!: ShiftReview[];

  public loading = false;
  public error = '';

  public day = true;
  public dinner = false;

  public employeeTitle = 'Managers';

  public departments: Employees[] = [
    {
      departmentName: 'Bediening',
      teams: ['dag', 'avond', 'bar', 'bediening', 'opleiding', 'cursus', 'proefmaand', 'student'],
      employees: [],
    },
    {
      departmentName: 'Management',
      teams: ['management', 'management avond', 'management dag', 'administratie', 'evenementen'],
      employees: [],
    },
    {
      departmentName: 'Keuken',
      teams: ['keuken'],
      employees: [],
    },
    {
      departmentName: 'Afwas',
      teams: ['afwas', 'runner'],
      employees: [],
    },
    {
      departmentName: 'Schoonmaak',
      teams: ['schoonmaak'],
      employees: [],
    },
    {
      departmentName: 'Mise en Place',
      teams: ['mise en place'],
      employees: [],
    },
  ];

  public icon: ScoreIconData = {
    icon: 'face-frown',
    class: 'text-danger',
    comment: '',
  };

  public fields: TableColumns[] = [
    {
      key: 'name',
      label: 'Naam',
      sortable: true,
      variant: '',
    },
    {
      key: 'rating',
      label: 'Score',
      sortable: true,
      variant: '',
      thClass: 'text-right',
      tdClass: 'text-right',
    },
    {
      key: 'mon',
      label: 'Ma',
      sortable: false,
      variant: this.isToday('Mon') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'tue',
      label: 'Di',
      sortable: false,
      variant: this.isToday('Tue') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'wed',
      label: 'Wo',
      sortable: false,
      variant: this.isToday('Wed') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'thu',
      label: 'Do',
      sortable: false,
      variant: this.isToday('Thu') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'fri',
      label: 'Vr',
      sortable: false,
      variant: this.isToday('Fri') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'sat',
      label: 'Za',
      sortable: false,
      variant: this.isToday('Sat') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'sun',
      label: 'Zo',
      sortable: false,
      variant: this.isToday('Sun') ? 'info' : '',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ];

  @Watch('shiftReviews', { immediate: true })
  public onShiftReviewsChange() {
    this.sortShiftReviews();
  }

  public sortShiftReviews() {
    this.departments.forEach(department => {
      const teams = department.teams;
      this.shiftReviews.forEach(review => {
        const team = review.shift?.team?.name;
        if (team && teams.includes(team.toLowerCase())) {
          const empId = review.shift?.employeeId ?? 0;
          const employeeObject: EmployeeObject = {
            employeeId: empId,
            name: review.shift?.employee?.name ?? 'Niet bekend',
            shifts: 0,
            rating: 0,
            mon: undefined,
            tue: undefined,
            wed: undefined,
            thu: undefined,
            fri: undefined,
            sat: undefined,
            sun: undefined,
          };
          let isNew = true;
          department.employees.forEach(emp => {
            if (emp.employeeId === empId) {
              isNew = false;
            }
          });
          if (isNew) {
            department.employees.push(employeeObject);
          }
        }
      });
    });
    this.sortEmployeeReviews();
  }

  public sortEmployeeReviews() {
    this.departments.forEach(department => {
      department.employees.forEach(emp => {
        this.shiftReviews.forEach(review => {
          if (review.shift?.employeeId === emp.employeeId) {
            if (review.shift !== undefined) {
              emp.rating = emp.rating + review.rating;
              emp.shifts += 1;
              const dateDate = new Date(review.shift?.date);
              const date = format(dateDate, 'iii');
              if (date === 'Mon') {
                emp.mon = this.getRatingIcon(review);
              } else if (date === 'Tue') {
                emp.tue = this.getRatingIcon(review);
              } else if (date === 'Wed') {
                emp.wed = this.getRatingIcon(review);
              } else if (date === 'Thu') {
                emp.thu = this.getRatingIcon(review);
              } else if (date === 'Fri') {
                emp.fri = this.getRatingIcon(review);
              } else if (date === 'Sat') {
                emp.sat = this.getRatingIcon(review);
              } else if (date === 'Sun') {
                emp.sun = this.getRatingIcon(review);
              }
            }
          }
        });
        emp.rating = Math.round((emp.rating / emp.shifts) * 10) / 10;
      });
    });
  }

  public sortEmployees(employees: EmployeeObject[]) {
    return [...employees].sort((a, b) => a.name.localeCompare(b.name));
  }

  public getRatingIcon(review: ShiftReview) {
    const result: ScoreIconData = {
      icon: '',
      comment: review.comment !== undefined ? review.comment : '',
    };

    if (review.rating === undefined || review.rating === null) {
      result.icon = 'fa-regular fa-face-smile';
      result.class = 'text-secondary';
    } else if (review.sick) {
      result.icon = 'virus';
      result.class = 'text-sickness';
      result.comment = 'Ziek';
    } else if (review.rating === 1) {
      result.icon = 'fa-regular fa-face-frown';
      result.class = 'text-danger';
    } else if (review.rating === 3) {
      result.icon = 'fa-regular fa-face-smile';
      result.class = 'text-warning';
    } else if (review.rating === 5) {
      result.icon = 'fa-regular fa-face-laugh';
      result.class = 'text-success';
    }
    return result;
  }

  public formatDate(date: Date) {
    return format(date, 'EEEE', { locale });
  }

  public isToday(day: string) {
    const today = format(new Date(), 'iii');
    return today === day;
  }
}
