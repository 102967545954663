
import { defineComponent, computed } from '@vue/composition-api';
import { useIncidents } from '@/util/incidents';
import { Incident } from '@/common';

export default defineComponent({
  model: {
    prop: 'incidentId',
    event: 'update',
  },
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
    incidentId: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { incidents, isLoading, error } = useIncidents('incidentSelect');
    const { isLoading: createIsLoading, error: createError, create } = useIncidents(
      'incidentSelect',
    );

    const model = computed<Incident | undefined>({
      get: () => incidents.value.find(incident => incident.id === props.incidentId),
      set: value => emit('update', value?.id),
    });

    const createIncident = async (incident: string) => {
      const newIncident = await create(props.establishmentId, incident);
      if (newIncident) {
        model.value = newIncident;
      }
    };

    return {
      incidents,
      isLoading: computed(() => isLoading.value || createIsLoading.value),
      error: computed(() => error.value || createError.value),
      model,
      createIncident,
    };
  },
});
