
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useIncidents } from '@/util/incidents';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default defineComponent({
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    ErrorMessage,
  },
  setup(props) {
    const { isLoading, create, error } = useIncidents('settings');

    const newIncident = ref('');

    function submit() {
      create(props.establishmentId, newIncident.value);
    }

    watch(isLoading, isLoading => {
      if (!isLoading && !error.value.create) {
        newIncident.value = '';
      }
    });

    return {
      isLoading,
      newIncident,
      submit,
      error: computed(() => error.value?.create),
    };
  },
});
