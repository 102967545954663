
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import OccupancyView from './components/planning/occupancy/OccupancyView.vue';
import PlanningLimitView from './components/planning/limits/PlanningLimitView.vue';
import DaypartTable from './components/planning/dayparts/DaypartTable.vue';
import { useEstablishments } from '@/util/establishments';
import { useEstablishmentSettings } from '@/composables';
import ErrorMessage from '@/components/ErrorMessage.vue';
import SuccessMessage from '@/components/SuccessMessage.vue';

export default defineComponent({
  components: {
    DaypartTable,
    OccupancyView,
    PlanningLimitView,
    ErrorMessage,
    SuccessMessage,
  },
  setup() {
    const saved = ref<string>();
    const { establishment, isLoading: loadingEstablishment } = useEstablishments();
    const { isLoading, error } = useEstablishmentSettings();

    watch(isLoading, isLoading => {
      if (!isLoading) {
        saved.value = !error.value.occupationEmployee ? 'Succesvol opgeslagen' : undefined;
      } else {
        saved.value = undefined;
      }
    });

    return {
      establishment,
      departments: computed(() => establishment.value?.occupanceDepartments ?? []),
      loadingEstablishment,
      isLoading,
      error,
      saved,
    };
  },
});
