import { parseUTC, formatTime } from '@/util/date';
import { ShiftReview } from '@/common';
import { ShiftReviewDto } from './types';

export function normalize(dto: ShiftReviewDto): ShiftReview {
  let shift;

  if (dto.shift) {
    const shiftDto = dto.shift;
    shift = {
      ...shiftDto,
      date: parseUTC(shiftDto.date),
      start: formatTime(parseUTC(shiftDto.start)),
      end: formatTime(parseUTC(shiftDto.end)),
    };
  }

  return {
    ...dto,
    shift,
  };
}
