












import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import InvoiceForm from './InvoiceForm.vue';
import { InvoiceableReceipt } from '@/common/types';
import { Form } from './types';
import { update } from '@/api/invoices/';
import { InvoiceEditFormDto } from '@/api/invoices/types';
import { format } from 'date-fns';

@Component({
  components: { InvoiceForm },
})
export default class UpdateInvoice extends Vue {
  @Prop({ required: true })
  public receipt!: InvoiceableReceipt;

  @Prop({ required: true })
  public establishmentId!: number;

  public loading = false;
  public error = false;

  public form: Form = {
    establishmentId: this.receipt.invoice?.establishmentId ?? 0,
    checkId: this.receipt.invoice?.checkNo,
    identification: this.receipt.identification,
    amount: this.receipt.amount.formatted,
    reservationId: null,
    debtorNumber: null,
    invoiceDate: this.receipt.invoice?.invoiceDate ?? '',
    invoiceNumber: this.receipt.invoice?.debtorNo ?? '',
    sentDate: this.receipt.invoice?.sentDate ?? '',
    reference: this.receipt.invoice?.reference ?? '',
    contact: this.receipt.invoice?.contactPerson ?? '',
    costCenter: this.receipt.invoice?.costCenter ?? '',
    department: this.receipt.invoice?.department ?? '',
    name: this.receipt.invoice?.debtorName,
    address1: this.receipt.invoice?.debtorAddress1,
    address2: this.receipt.invoice?.debtorAddress2 ?? '',
    houseNumber: this.receipt.invoice?.debtorHousenumber ?? '',
    postalCode: this.receipt.invoice?.debtorPostalCode,
    city: this.receipt.invoice?.debtorCity,
    country: this.receipt.invoice?.debtorCountry ?? '',
    phone: this.receipt.invoice?.debtorPhone,
    email: this.receipt.invoice?.debtorEmail,
  };

  @Emit('refresh')
  public refresh() {
    return true;
  }

  public async updateInvoice(form: Form) {
    if (!this.receipt.invoice) {
      return;
    }
    const lineItems = [];

    const costHigh = this.receipt.totalInclTaxHigh?.amount ?? 0;
    if (costHigh) {
      lineItems.push({
        description: 'Volgens bon (btw hoog)',
        quantity: 1,
        cost: costHigh / 1.21,
        tax: 0.21,
      });
    }

    const costLow = this.receipt.totalInclTaxLow?.amount ?? 0;
    if (costLow) {
      lineItems.push({
        description: 'Volgens bon (btw laag)',
        quantity: 1,
        cost: costLow / 1.09,
        tax: 0.09,
      });
    }

    const invoice: Partial<InvoiceEditFormDto> = {
      invoiceDate: format(new Date(), 'yyyy-MM-dd'),
      establishmentId: form.establishmentId,
      checkNo: form.checkId,
      checkDob: this.receipt.date,
      reference: form.reference ?? '',
      contactPerson: form.contact ?? '',
      costCenter: form.costCenter ?? '',
      department: form.department ?? '',
      debtorName: form.name ?? '',
      debtorAddress1: form.address1 ?? '',
      debtorAddress2: form.address2 ?? '',
      debtorHousenumber: form.houseNumber ?? '',
      debtorPostalCode: form.postalCode ?? '',
      debtorCity: form.city ?? '',
      debtorCountry: form.country ?? '',
      debtorPhone: form.phone ?? '',
      debtorEmail: form.email ?? '',
      lineItems,
    };
    try {
      this.loading = true;
      await update(this.receipt.invoice.id, invoice);
    } catch (e) {
      this.error = e;
    } finally {
      this.refresh();
      this.loading = false;
    }
  }
}
