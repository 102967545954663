



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Prognosis, Establishment, Shift } from '@/common';
import { DailyBalanceDto, DailyBalance } from '@/api/dailyBalanceApi';
import { WageCosts } from '@/api/types';

interface PrognosisObject {
  progTurnover: string;
  realTurnover: string;
  progWageCost: string;
  realWageCost: string;
  progWageCostPerc: string;
  realWageCostPerc: string;
  guestCount: {
    dag: string;
    avond: string;
  };
}

@Component
export default class PrognosisComponent extends Vue {
  @Prop({ required: true, type: Array as () => Prognosis[] })
  public prognosis!: Prognosis[];

  @Prop({ required: true, type: Array as () => Shift[] })
  public shifts!: Shift[];

  @Prop({ type: Object as () => WageCosts, required: true })
  public wageCosts!: WageCosts;

  @Prop({ type: Object as () => DailyBalanceDto, default: undefined })
  public dailyBalance?: DailyBalanceDto;

  @Prop({ required: true, type: Object as () => Establishment })
  public establishment!: Establishment;

  @Prop({ required: true })
  public date!: string;

  public loading = false;
  public error = false;

  public prognosisObject: PrognosisObject = {
    progTurnover: 'nb',
    realTurnover: 'nb',
    progWageCost: 'nb',
    realWageCost: 'nb',
    progWageCostPerc: 'nb',
    realWageCostPerc: 'nb',
    guestCount: {
      dag: 'nb',
      avond: 'nb',
    },
  };

  public async mounted() {
    try {
      this.loading = true;
      await this.sortPrognosis();
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  get shiftHours() {
    return this.shifts.reduce((total, shift) => total + shift.costs, 0);
  }

  public async sortPrognosis() {
    const prog = this.prognosis.find(p => p.type?.name === 'Totaal');
    let realTurnover = 0;
    if (this.dailyBalance) {
      const balance = new DailyBalance(this.dailyBalance);
      realTurnover = balance.getTurnoverAmountExclVat() ?? 0;
    }

    this.prognosisObject = {
      progTurnover: prog?.turnover ? '€' + prog?.turnover?.toFixed(0) : 'nb',
      realTurnover: '€' + realTurnover.toFixed(0) ?? '-',
      progWageCost: this.wageCosts.plannedCosts.toFixed(0) ?? '-',
      realWageCost: this.wageCosts.actualCosts?.toFixed(0) ?? '-',
      progWageCostPerc: this.getWagePerc(this.wageCosts.plannedCosts ?? 0, prog?.turnover ?? 0),
      realWageCostPerc: this.getWagePerc(this.wageCosts.actualCosts ?? 0, realTurnover),
      guestCount: {
        dag: this.getGuests('dag', this.getPrognosisGuestByType('Lunch')),
        avond: this.getGuests('avond', this.getPrognosisGuestByType('Diner')),
      },
    };
  }

  public getWagePerc(wagecost: number, turnover: number) {
    const perc = (wagecost / turnover) * 100;
    if (!isFinite(perc)) {
      return '-';
    } else {
      return perc.toFixed();
    }
  }

  public getPrognosisGuestByType(type: string) {
    const result = this.prognosis.find(prog =>
      prog.type?.name.toLowerCase().startsWith(type.toLowerCase()),
    );
    return result?.guests ?? 0;
  }

  public getGuests(dayPart: string, guests: number) {
    if (dayPart.toLowerCase() === 'dag') {
      if (guests < 0) return '0';
      if (guests < 40) return '0-40';
      if (guests < 70) return '40-70';
      if (guests < 100) return '70-100';
      if (guests < 140) return '100-140';
      if (guests < 180) return '140-180';
      if (guests < 220) return '180-220';
      if (guests < 250) return '220-250';
      if (guests < 300) return '250-300';
      if (guests < 350) return '300-350';
      else return '350+';
    } else {
      if (guests < 0) return '0';
      if (guests < 40) return '0-40';
      if (guests < 70) return '40-70';
      if (guests < 100) return '70-100';
      if (guests < 120) return '100-120';
      if (guests < 150) return '120-150';
      if (guests < 180) return '150-180';
      if (guests < 220) return '180-220';
      if (guests < 260) return '220-260';
      if (guests < 300) return '260-300';
      else return '300+';
    }
  }
}
