
import { Daypart, Establishment, OccupanceRange } from '@/common';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import OccupancyRange from './OccupancyTable.vue';
import { UpdateOccupanceRangeSettingsParams } from '@/api/types';
import { useEstablishmentSettings } from '@/composables';
import ErrorMessage from '@/components/ErrorMessage.vue';
import SuccessMessage from '@/components/SuccessMessage.vue';

interface Data {
  daypart: Daypart;
  occupancyRanges?: OccupanceRange[];
}

export default defineComponent({
  components: { OccupancyRange, ErrorMessage, SuccessMessage },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props) {
    const data = ref<Data[]>([]);
    const { error, isLoading, updateOccupanceRangeSettings } = useEstablishmentSettings();
    const saved = ref<string>();
    const edit = ref<boolean>(false);

    watch(isLoading, isLoading => {
      if (!isLoading) {
        saved.value = !error.value.daypart ? 'Succesvol opgeslagen' : undefined;
      } else {
        saved.value = undefined;
      }
    });

    function reset(): void {
      props.establishment.dayparts?.forEach(daypart => {
        data.value.push({
          daypart: daypart,
          occupancyRanges: props.establishment.occupanceRanges?.filter(
            range => range.daypartId === daypart.id,
          ),
        });
      });
    }
    reset();

    function save(): void {
      const ranges: UpdateOccupanceRangeSettingsParams = {
        establishmentId: props.establishment.id,
        ranges: [],
      };
      data.value.forEach(d => {
        if (d.occupancyRanges) {
          d.occupancyRanges.forEach(range => {
            ranges.ranges.push(range);
          });
        }
      });
      updateOccupanceRangeSettings({
        establishmentId: props.establishment.id,
        ranges: ranges.ranges,
      });

      edit.value = false;
    }

    return {
      props,
      data,
      error,
      saved,
      edit,
      save,
    };
  },
});
