
import { useEstablishments } from '@/util/establishments';
import { defineComponent } from '@vue/composition-api';
import DepartmentTable from './components/departments/DepartmentTable.vue';

export default defineComponent({
  components: {
    DepartmentTable,
  },
  setup() {
    const { establishment } = useEstablishments();

    return {
      establishment,
    };
  },
});
