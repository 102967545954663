var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._l((_vm.formData),function(data,index){return _c('b-row',{key:("item-" + index),staticClass:"mb-2 d-flex justify-content-between align-items-center"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"item","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Item","invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"id":"count-text","type":"text","placeholder":"item","state":!validated || valid ? null : valid},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", $$v)},expression:"data.name"}})],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"aantal","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Aantal","invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"id":"count-quantity","type":"number","placeholder":"aantal","state":!validated || valid ? null : valid},model:{value:(data.expectedNumber),callback:function ($$v) {_vm.$set(data, "expectedNumber", _vm._n($$v))},expression:"data.expectedNumber"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"1"}},[_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('b-icon',{attrs:{"icon":"trash","variant":"danger"}})],1)],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.addRow}},[_c('b-icon',{attrs:{"icon":"plus-circle"}})],1)],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Opslaan ")],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }