import SettingsView from './SettingsView.vue';
import SettingsGeneral from './SettingsGeneralView.vue';
import SettingsDepartments from './SettingsDepartmentsView.vue';
import SettingsTags from './SettingsTagsView.vue';
import SettingsSubjects from './SettingsSubjectsView.vue';
import SettingsDayparts from './SettingsDaypartsView.vue';
import SettingsLimits from './SettingsLimitsView.vue';
import SettingsOccupancy from './SettingsOccupancyView.vue';

export default [
  {
    path: '/instellingen/:establishmentId',
    component: SettingsView,
    name: 'Settings',
    redirect: { name: 'SettingsGeneral' },
    children: [
      {
        path: 'algemeen',
        name: 'SettingsGeneral',
        component: SettingsGeneral,
      },
      {
        path: 'afdelingen',
        name: 'SettingsDepartments',
        component: SettingsDepartments,
      },
      {
        path: 'dagdelen',
        name: 'SettingsDayparts',
        component: SettingsDayparts,
      },
      {
        path: 'bezetting',
        name: 'SettingsOccupancy',
        component: SettingsOccupancy,
      },
      {
        path: 'rooster-limieten',
        name: 'SettingsLimits',
        component: SettingsLimits,
      },
      {
        path: 'tags',
        name: 'SettingsTags',
        component: SettingsTags,
      },
      {
        path: 'onderwerpen',
        name: 'SettingsSubjects',
        component: SettingsSubjects,
      },
    ],
  },
];
