import client from '@/api/client';
import { Establishment } from '@/common';
import { normalizeEstablishment } from '../serialize';
import { UpdateInventoryItemSettingsParams } from '../types';

const updateInventoryItems = async (
  params: UpdateInventoryItemSettingsParams,
): Promise<Establishment> => {
  const { establishmentId, ...data } = params;
  const response = await client.put<Establishment>(
    `/establishments/${establishmentId}/settings/inventory-items`,
    data,
  );
  return normalizeEstablishment(response.data);
};

export default updateInventoryItems;
