













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import ManagerSummary from './ManagerSummary.vue';
import ShiftReviewsSummary from '../shiftReview/ShiftReviewsSummary.vue';
import IncidentReportSummary from '../incidentReports/IncidentReportSummary.vue';
import JournalNotesSummary from '../journalNotes/JournalNotesSummary.vue';

@Component({
  components: {
    ManagerSummary,
    ShiftReviewsSummary,
    IncidentReportSummary,
    JournalNotesSummary,
  },
})
export default class Sidebar extends Vue {
  @Prop({ required: true })
  public journalId!: number;

  public date = '2020-10-15';

  public created() {
    this.date = this.$route.params?.date;
  }

  public formatTime(str: string) {
    const d = parseISO(str);
    return format(d, 'HH:mm');
  }
}
