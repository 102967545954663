
import { defineComponent, ref } from '@vue/composition-api';
import { Incident } from '@/common';
import { useIncidents } from '@/util/incidents';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CreateSubjectForm from './CreateSubjectForm.vue';

const fields = [
  {
    key: 'options',
    label: '',
  },
  {
    key: 'name',
    label: 'Omschrijving',
    sortable: true,
  },
];

export interface TableData {
  items: Incident[];
  fields: Tablefields[];
}

export interface Tablefields {
  key: string;
  label: string;
  sortable?: boolean;
}

export default defineComponent({
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    CreateSubjectForm,
  },
  setup(props) {
    const { isLoading: isSearchLoading, incidents, search } = useIncidents('settings');
    const { isLoading: isCreateLoading, create } = useIncidents('settings');
    search({ establishmentId: props.establishmentId });

    const tableData = ref<TableData>();
    const selectedIncidents = ref<number[]>([]);
    const newIncident = ref('');

    function submit() {
      create(props.establishmentId, newIncident.value);
    }

    return {
      tableData,
      incidents,
      isSearchLoading,
      isCreateLoading,
      fields,
      newIncident,
      selectedIncidents,
      submit,
    };
  },
});
