import { GetPrognosisByDate } from '@/api/prognosis';
import {
  Daypart,
  OccupanceDepartment,
  OccupanceRange,
  PlanningPrognosisRange,
  Prognosis,
} from '@/common';
import { useEstablishments } from '@/util/establishments';
import { computed, ref } from '@vue/composition-api';
import { format } from 'date-fns';
import { UsePlanningPrognosis, UsePlanningPrognosisErrors } from '../types';

const usePlanningPrognosis = (): UsePlanningPrognosis => {
  const planningPrognosis = ref<PlanningPrognosisRange[]>([]);
  const isLoading = ref(false);
  const error = ref<UsePlanningPrognosisErrors>({});
  const { establishment } = useEstablishments();

  function getOccupanceRange(prognosis: Prognosis[], daypart: Daypart) {
    let occupance = 0;
    let range: OccupanceRange | undefined = undefined;
    prognosis.forEach(p => {
      const type = p.type?.name ?? '';
      const daypartName = daypart.name;
      if (type.toLowerCase() === 'lunch gast' && daypartName.toLowerCase() === 'dag') {
        occupance = p.guests;
      } else if (type.toLowerCase() === 'diner gast' && daypartName.toLowerCase() === 'avond') {
        occupance = p.guests;
      }
    });
    establishment.value?.occupanceRanges?.forEach(occupanceRange => {
      if (
        occupanceRange.daypartId === daypart.id &&
        occupanceRange.lower <= occupance &&
        occupanceRange.upper > occupance
      ) {
        range = occupanceRange;
      }
    });
    return range;
  }

  function getLimit(
    occupanceRange: OccupanceRange | undefined,
    department: OccupanceDepartment,
    dob: string,
  ) {
    let limit = undefined;
    const day = format(new Date(dob), 'iii');

    establishment.value?.occupationPlanning?.forEach(prognosis => {
      if (
        prognosis.departmentId === department.id &&
        prognosis.occupanceRangeId === occupanceRange?.id
      ) {
        if (day === 'Sun') limit = prognosis.sun;
        if (day === 'Mon') limit = prognosis.mon;
        if (day === 'Tue') limit = prognosis.tue;
        if (day === 'Wed') limit = prognosis.wed;
        if (day === 'Thu') limit = prognosis.thu;
        if (day === 'Fri') limit = prognosis.fri;
        if (day === 'Sat') limit = prognosis.sat;
      }
    });
    return limit;
  }

  const fetch = async (dob: string, daypart: Daypart): Promise<void> => {
    try {
      isLoading.value = true;
      error.value.fetch = undefined;
      const prognosis = await GetPrognosisByDate(establishment.value?.id ?? 0, dob);
      establishment.value?.occupanceDepartments?.forEach(dep => {
        planningPrognosis.value.push({
          department: dep,
          daypart: daypart,
          limit: getLimit(getOccupanceRange(prognosis, daypart), dep, dob),
        });
      });
    } catch (e) {
      error.value.fetch = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    planningPrognosis: computed(() => planningPrognosis.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    fetch,
  };
};

export default usePlanningPrognosis;
