import { onMounted, onBeforeUnmount } from '@vue/composition-api';

export default function useInterval(func: () => void, delay: number) {
  let intervalId = -1;

  onMounted(() => {
    func();
    intervalId = setInterval(func, delay);
  });

  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
}
