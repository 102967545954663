import client from '../client';
import { normalizeInvoiceDto } from './serialize';
import { InvoiceDto, InvoiceEditFormDto } from './types';
import { Auth } from '@/services/auth';

export async function getById(id: number) {
  const result = await client.get<InvoiceDto>(`/invoices/${id}`);
  return normalizeInvoiceDto(result.data);
}

export async function getByCheckNoAndDob(establishmentId: number, checkNo: number, dob: string) {
  const params = { checkNo, dob };
  const result = await client.get<InvoiceDto>(`/establishments/${establishmentId}/invoices`, {
    params,
  });
  return normalizeInvoiceDto(result.data);
}

export async function create(establishmentId: number, data: Partial<InvoiceEditFormDto>) {
  const result = await client.post<InvoiceDto>(`/establishments/${establishmentId}/invoices`, data);
  return normalizeInvoiceDto(result.data);
}

export async function update(id: number, data: Partial<InvoiceEditFormDto>) {
  const result = await client.put<InvoiceDto>(`/invoices/${id}`, data);
  return normalizeInvoiceDto(result.data);
}

export async function del(id: number) {
  await client.delete<void>(`/invoices/${id}`);
}

export async function send(id: number) {
  const result = await client.post<InvoiceDto>(`/invoices/${id}/actions/send`);
  return normalizeInvoiceDto(result.data);
}

export async function downloadPdf(id: number) {
  const token = await Auth.getToken();
  window.open(`${client.defaults.baseURL}/invoices/${id}?access_token=${token}`, '_blank');
}
