import client from '@/api/client';
import { Establishment } from '@/common';
import { normalizeEstablishment } from '../serialize';
import { UpdateWeatherSettingsParams } from '../types';

const updateWeatherSettings = async (
  params: UpdateWeatherSettingsParams,
): Promise<Establishment> => {
  const { establishmentId, ...data } = params;
  const response = await client.put<Establishment>(
    `/establishments/${establishmentId}/settings/weather`,
    data,
  );
  return normalizeEstablishment(response.data);
};

export default updateWeatherSettings;
