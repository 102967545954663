




















































import { InvoiceableReceipt, Receipt } from '@/common';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getReceipt } from '@/api/receipt';

@Component
export default class CheckInfo extends Vue {
  @Prop({ required: true })
  public establishmentId!: number;

  @Prop({ required: true })
  public dob!: string;

  @Prop({ required: true })
  public invoicableReceipt!: InvoiceableReceipt;

  public loading = false;
  public error = '';
  public receipt: Receipt | null = null;

  public async mounted() {
    try {
      this.loading = true;
      this.receipt = await getReceipt(this.establishmentId, this.dob, this.invoicableReceipt.check);
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  get assetsUrl() {
    return process.env.VUE_APP_JOURNAL_ASSETS_URL;
  }

  public format(amount?: number) {
    const strVal = amount?.toFixed(2) ?? '-';
    return `€ ${strVal}`;
  }
}
