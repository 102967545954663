
import { Establishment } from '@/common';
import useOccupanceDepartments from '@/composables/useOccupanceDepartments';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import DepartmentTableRow from './DepartmentTableRow.vue';
import { useEstablishments } from '@/util/establishments';

export default defineComponent({
  components: {
    DepartmentTableRow,
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props) {
    const edit = ref<boolean>(false);
    const { departments, isLoading, error, search } = useOccupanceDepartments();
    const { reload } = useEstablishments();

    function reset() {
      reload();
      search(props.establishment.id);
    }
    reset();

    function addRow() {
      departments.value.push({
        establishmentId: props.establishment.id,
        name: '',
        teams: [],
      });
      edit.value = true;
    }

    function removeRow(index: number) {
      props.establishment.departments?.splice(index, 1);
    }

    return {
      props,
      edit,
      departments,
      isLoading,
      error,
      reset,
      addRow,
      removeRow,
    };
  },
});
