import client from '../client';
import useAxios from '@/util/axios';
import { Journal } from '@/common';

const { getCancelToken } = useAxios();

export async function findById(id: number, signal?: AbortSignal) {
  const result = await client.get<Journal>(`/journals/${id}`, {
    cancelToken: getCancelToken(signal),
  });
  return result.data;
}

export async function findAllForEstablishment(establishmentId: number) {
  const result = await client.get<Journal[]>(`/establishments/${establishmentId}/journals`);
  return result.data;
}

export async function findAllForEstablishmentByDate(establishmentId: number, dob: string) {
  const params = { dob };
  const result = await client.get<Journal[]>(`/establishments/${establishmentId}/journals`, {
    params,
  });
  return result.data;
}
