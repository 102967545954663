
import { defineComponent } from '@vue/composition-api';
import { useAuth } from '@/composables';

export default defineComponent({
  name: 'Account',
  setup() {
    const { account, logout } = useAuth();

    const initiateLogout = async () => {
      await logout();
    };

    return {
      account,
      initiateLogout,
    };
  },
});
