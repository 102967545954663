



















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { addDays, format, subDays } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import journalApi from '@/api/journalApi';
import { Journal } from '@/common/types';
import JournalOverview from './components/JournalOverview.vue';
import DailyBalance from './components/dailyBalance/DailyBalanceView.vue';
import ExtraView from './components/extra/extraView.vue';
import { RawLocation } from 'vue-router';

const tabs = ['dag', 'avond', 'kassa', 'afsluiten'];

@Component({
  components: {
    JournalOverview,
    DailyBalance,
    ExtraView,
  },
})
export default class JournalView extends Vue {
  public dob = format(new Date(), 'yyyy-MM-dd');
  public establishmentId!: number;
  public loading = false;
  public errorMessage = '';
  public tab = 0;
  public selectedTab = '';

  public journals: Journal[] = [];

  // public created() {
  //   const date = this.$route.params?.date;
  //   const establishmentId = Number(this.$route.params?.establishmentId);
  //   if (!date || !establishmentId) {
  //     this.$router.push({
  //       name: 'JournalsView',
  //       params: { establishmentId: String(establishmentId) },
  //     });
  //   }
  //   this.dob = date;
  //   const hash = this.getRouteHash();
  //   if (hash) {
  //     this.tab = Math.max(tabs.indexOf(hash), 0);
  //   }
  //   this.establishmentId = establishmentId;
  // }

  // public async mounted() {
  //   this.loadData();
  // }

  @Watch('tab')
  public updateUrl() {
    const hash = this.getRouteHash();
    if (hash !== tabs[this.tab]) {
      this.$router.push('#' + tabs[this.tab]);
    }
  }

  get dateDay() {
    return format(new Date(this.dob), 'EEEE d MMMM', { locale });
  }

  get dateWeek() {
    return format(new Date(this.dob), 'II', { locale });
  }

  get establishment() {
    switch (this.establishmentId) {
      case 1:
        return 'Walrus Sneek';
      case 2:
        return 'Walrus Leeuwarden';
      case 3:
        return 'Proeflokaal Sneek';
      case 4:
        return 'Groote Wielen';
      default:
        return '';
    }
  }

  get dayJournal() {
    return this.journals.find(j => j.name === 'dag');
  }

  get eveningJournal() {
    return this.journals.find(j => j.name === 'avond');
  }

  public next() {
    const date = format(addDays(new Date(this.dob), 1), 'yyyy-MM-dd');
    this.navigatedToDate(date);
  }

  public prev() {
    const date = format(subDays(new Date(this.dob), 1), 'yyyy-MM-dd');
    this.navigatedToDate(date);
  }

  public navigatedToDate(date: string) {
    const hash = this.getRouteHash();
    const location: RawLocation = {
      name: 'JournalView',
      params: { date },
    };
    if (hash) {
      location.hash = '#' + this.getRouteHash();
    }
    this.$router.push(location);
  }

  @Watch('$route', { immediate: true, deep: true })
  public async loadData() {
    const date = this.$route.params?.date;
    const establishmentId = Number(this.$route.params?.establishmentId);
    if (!date || !establishmentId) {
      this.$router.push({
        name: 'JournalsView',
        params: { establishmentId: String(establishmentId) },
      });
    }
    this.dob = date;
    const hash = this.getRouteHash();
    if (hash) {
      this.tab = Math.max(tabs.indexOf(hash), 0);
    }
    this.establishmentId = establishmentId;

    try {
      this.loading = true;
      this.journals = await journalApi.findAllForEstablishmentByDate(
        this.establishmentId,
        this.dob,
      );
      this.journals.sort((a, b) => a.name.localeCompare(b.name)).reverse();
    } catch (e) {
      if (typeof e === 'string') {
        this.errorMessage = e;
      } else {
        this.errorMessage = 'er is iets misgegaan';
      }
    } finally {
      this.loading = false;
    }
  }

  public getRouteHash() {
    return this.$route.hash ? this.$route.hash.replace('#', '') : undefined;
  }
}
