import client from '@/api/client';
import { AppliedTender } from '@/api/types';

type Params = {
  establishmentId: number;
  start: string;
  end: string;
};

export async function getTenders(paramObj: Params): Promise<Record<string, AppliedTender[]>> {
  const { establishmentId, ...params } = paramObj;
  const response = await client.get<Record<string, AppliedTender[]>>(
    `/establishments/${establishmentId}/tenders`,
    { params },
  );
  return response.data;
}
