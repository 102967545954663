




import { defineComponent, computed } from '@vue/composition-api';
import { sizableProps, useSizable } from '@/components/composables/sizable';

export default defineComponent({
  props: {
    degree: {
      type: Number,
      default: undefined,
    },
    cardinal: {
      type: String,
      default: undefined,
    },
    to: {
      type: Boolean,
      default: false,
    },
    ...sizableProps,
  },
  setup(props) {
    const { sizableClasses } = useSizable(props);

    const iconName = computed(() => {
      const dir = props.to ? 'towards' : 'from';
      if (props.degree) {
        return `${dir}-${props.degree}-deg`;
      }
      if (props.cardinal) {
        return `wi-${dir}-${props.cardinal}`;
      }
      return 'wi-wind-direction';
    });

    const classes = computed(() => ({
      wi: true,
      'wi-wind': true,
      [iconName.value]: true,
      ...sizableClasses.value,
    }));

    return {
      classes,
    };
  },
});
