






















































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import dailyBalanceApi, {
  DailyBalanceDto,
  DailyBalance,
  DailyBalancePayment,
} from '@/api/dailyBalanceApi';
import { AlohaEstablishment } from '@/common';
import DebtorsList from '@/views/debtors/DebtorsList.vue';
import { addDays, format } from 'date-fns';
import { getTenders } from '@/api/tenders';
import { AppliedTender } from '@/api/types';

const defaultCoins = {
  5: 0,
  10: 0,
  20: 0,
  50: 0,
  100: 0,
  200: 0,
  500: 0,
};

const CurrencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

@Component({
  components: { DebtorsList },
})
export default class DailyBalanceView extends Vue {
  @Prop({ required: true, type: String })
  public dob!: string;
  @Prop({ required: true, type: Number })
  public establishmentId!: number;

  public loading = false;
  public updating = false;
  public errorMessage = '';
  public establishment: AlohaEstablishment =
    this.establishmentId === 1 ? 'WALRUS_SN' : 'WALRUS_LWD';

  public dailyBalanceData: DailyBalanceDto | null = null;
  public dailyBalance: DailyBalance | undefined;
  public tenders: AppliedTender[] | [] = [];

  public coinModel: Record<number, number> = { ...defaultCoins };
  public pinModel: number[] = [];
  public paymentsModel: DailyBalancePayment[] = [];

  @Watch('coinModel')
  @Watch('pinModel', { deep: true })
  @Watch('paymentsModel', { deep: true })
  public onFormDataChange() {
    if (!this.dailyBalanceData) {
      return;
    }
    this.dailyBalance = new DailyBalance({
      ...this.dailyBalanceData,
      userData: {
        ...this.dailyBalanceData.userData,
        coins: this.coinModel,
        pins: this.pinModel,
        payments: this.paymentsModel,
      },
    });
  }

  public created() {
    const date = this.$route.params?.date;
    const establishmentId = parseInt(this.$route.params?.establishmentId);
    if (!date || !establishmentId) {
      this.$router.push({
        name: 'JournalsView',
        params: { establishmentId: String(establishmentId) },
      });
    }
    this.dob = date;
    this.establishmentId = establishmentId;
  }

  public async mounted() {
    try {
      this.loading = true;
      await Promise.all([this.loadDailyBalance(), this.loadTenders()]);
    } catch (e) {
      this.errorMessage = e as string;
    } finally {
      this.loading = false;
    }
  }

  get debtorFrom() {
    return new Date(this.dob);
  }

  get debtorTo() {
    return addDays(this.debtorFrom, 1);
  }

  totalDebtorAmount() {
    return this.dailyBalance?.getDebtorAmount() ?? 0;
  }

  totalCashAmount() {
    return this.dailyBalance?.getCashAmount() ?? 0;
  }

  totalCoinsAmount() {
    return this.dailyBalance?.getCoinsAmount() ?? 0;
  }

  coinsDifferenceAmount() {
    return this.dailyBalance?.getCoinsDifferenceAmount() ?? 0;
  }

  totalPinCashRegister() {
    return this.dailyBalance?.getCoinsDifferenceAmount() ?? 0;
  }

  totalPinAmount() {
    return this.dailyBalance?.getPinAmount() ?? 0;
  }

  totalUserPinAmount() {
    return this.dailyBalance?.getUserPinAmount() ?? 0;
  }

  pinDifferenceAmount() {
    return this.dailyBalance?.getPinDifferenceAmount() ?? 0;
  }

  totalIZettle() {
    return this.dailyBalance?.getIZettleAmount() ?? 0;
  }

  totalPayments() {
    return this.dailyBalance?.getPaymentsAmount() ?? 0;
  }

  totalSpecialPaymentsAmount() {
    return this.dailyBalance?.getSpecialPaymentsAmount() ?? 0;
  }

  totalOfferedAmount() {
    return this.dailyBalance?.getOfferedAmount() ?? 0;
  }

  totalRepresentationAmount() {
    return this.dailyBalance?.getRepresentationAmount() ?? 0;
  }

  turnoverAmount() {
    return this.dailyBalance?.getTurnoverAmount() ?? 0;
  }

  turnoverWithVouchersAmount() {
    return this.dailyBalance?.getTurnoverWithVouchersAmount() ?? 0;
  }

  vouchersAmount() {
    return this.dailyBalance?.getVoucherAmount() ?? 0;
  }

  totalInCashRegisterAmount() {
    return this.dailyBalance?.getInCashRegisterAmount() ?? 0;
  }

  differenceAmount() {
    return this.dailyBalance?.getDifferenceAmount() ?? 0;
  }

  voidedAmount() {
    return this.dailyBalance?.getVoidAmount() ?? 0;
  }

  public addPin() {
    this.pinModel.push(0);
  }

  public addPayment() {
    this.paymentsModel.push({
      name: '',
      amount: 0,
    });
  }

  public async loadDailyBalance() {
    try {
      const dailyBalance = await dailyBalanceApi.search({
        establishmentId: this.establishmentId,
        dob: this.dob,
      });

      if (dailyBalance.length) {
        this.dailyBalanceData = dailyBalance[0];
      }

      this.coinModel = (this.dailyBalanceData?.userData?.coins as Record<number, number>) ?? {
        ...defaultCoins,
      };
      this.pinModel = (this.dailyBalanceData?.userData?.pins as number[]) ?? [];
      this.paymentsModel =
        (this.dailyBalanceData?.userData?.payments as DailyBalancePayment[]) ?? [];
    } catch (e) {
      console.error(e);
    }
  }

  public async loadTenders() {
    try {
      const dob = new Date(this.dob);
      const tenders = await getTenders({
        establishmentId: this.establishmentId,
        start: this.dob,
        end: format(addDays(dob, 1), 'yyyy-MM-dd'),
      });
      this.tenders = tenders?.[this.dob] ?? [];
    } catch (e) {
      console.error(e);
    }
  }

  public async updateDailyBalance() {
    this.updating = true;
    if (this.dailyBalanceData) {
      this.dailyBalanceData.userData.coins = this.coinModel;
      this.dailyBalanceData.userData.pins = this.pinModel;
      this.dailyBalanceData.userData.payments = this.paymentsModel;
      this.dailyBalanceData.userData.diff = String(this.differenceAmount());
      this.dailyBalanceData = await dailyBalanceApi.updateDailyBalance(
        this.establishmentId,
        this.dob,
        this.dailyBalanceData,
      );
    }
    this.updating = false;
  }

  public currency(num: number) {
    return CurrencyFormatter.format(num);
  }
}
