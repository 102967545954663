import Vue from 'vue';

const currencyFmt = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

const percentageFmt = new Intl.NumberFormat('nl-NL', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

Vue.filter('currencyFormat', (v: number) => currencyFmt.format(v));

Vue.filter('percentageFormat', (v: number) => percentageFmt.format(v));
