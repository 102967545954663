import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import { Employee } from '@/common';

export interface EmployeeState {
  employees: Record<number, Employee>;
  cache: Record<string, number[]>;
}

const state: EmployeeState = {
  employees: {},
  cache: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
} as Module<EmployeeState, RootState>;
