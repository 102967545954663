









































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dailyBalanceApi, { DailyBalanceDto } from '@/api/dailyBalanceApi';
import { findById } from '@/api/establishments';
import { Establishment } from '@/common';

interface CountForm {
  coins: Coin[];
  count: Count[];
}

interface Coin {
  coinName: string;
  roleAmount: number;
  count: number;
}

interface Count {
  name: string;
  number: number | undefined;
  count: number;
}

const CurrencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

@Component
export default class ExtraView extends Vue {
  @Prop({ required: true, type: String })
  public dob!: string;
  @Prop({ required: true, type: Number })
  public establishmentId!: number;

  public loading = false;
  public updating = false;
  public errorMessage = '';

  public dailyBalanceData: DailyBalanceDto | null = null;

  public establishment: Establishment | null = null;

  public countForm: CountForm = {
    coins: [
      {
        coinName: '0.05',
        roleAmount: 2.5,
        count: 0,
      },
      {
        coinName: '0.10',
        roleAmount: 4,
        count: 0,
      },
      {
        coinName: '0.20',
        roleAmount: 8,
        count: 0,
      },
      {
        coinName: '0.50',
        roleAmount: 20,
        count: 0,
      },
      {
        coinName: '1.00',
        roleAmount: 25,
        count: 0,
      },
      {
        coinName: '2.00',
        roleAmount: 50,
        count: 0,
      },
      {
        coinName: '5.00',
        roleAmount: 5,
        count: 0,
      },
      {
        coinName: '10.00',
        roleAmount: 10,
        count: 0,
      },
      {
        coinName: '20.00',
        roleAmount: 20,
        count: 0,
      },
      {
        coinName: '50.00',
        roleAmount: 50,
        count: 0,
      },
    ],
    count: [],
  };

  public created() {
    const date = this.$route.params?.date;
    const establishmentId = parseInt(this.$route.params?.establishmentId);
    if (!date || !establishmentId) {
      this.$router.push({
        name: 'JournalsView',
        params: { establishmentId: String(establishmentId) },
      });
    }
    this.dob = date;
    this.establishmentId = establishmentId;
  }

  public async mounted() {
    try {
      this.loading = true;
      await Promise.all([this.loadDailyBalance(), this.loadEstablishment()]);
      this.init();
    } catch (e) {
      if (typeof e === 'string') {
        this.errorMessage = e;
      } else {
        this.errorMessage = 'er is iets misgegaan';
      }
    } finally {
      this.loading = false;
    }
  }

  get changeBin() {
    let changeBin = 1000;
    if (this.establishment) {
      changeBin = this.establishment.changeBin ?? 1000;
    }
    return changeBin;
  }

  public init() {
    if (this.dailyBalanceData?.miscData?.count) {
      this.countForm.count = this.dailyBalanceData.miscData.count as Count[];
    }

    if (this.dailyBalanceData?.miscData?.coins) {
      this.countForm.coins = this.dailyBalanceData.miscData.coins as Coin[];
    }

    this.establishment?.inventoryItems?.forEach(item => {
      const present = this.countForm.count.find(x => x.name === item.name);
      if (!present) {
        this.countForm.count.push({
          name: item.name,
          count: 0,
          number: item.expectedNumber,
        });
      }
    });
  }

  public async loadEstablishment() {
    this.establishment = await findById(this.establishmentId);
  }

  public async loadDailyBalance() {
    const dailyBalance = await dailyBalanceApi.search({
      establishmentId: this.establishmentId,
      dob: this.dob,
    });

    if (dailyBalance.length) {
      this.dailyBalanceData = dailyBalance[0];
    }
  }

  public async updateDailyBalance() {
    if (this.dailyBalanceData) {
      if (this.dailyBalanceData.miscData === null) {
        this.dailyBalanceData.miscData = {};
      }
      this.dailyBalanceData.miscData.count = this.countForm.count;
      this.dailyBalanceData.miscData.coins = this.countForm.coins;
      this.dailyBalanceData = await dailyBalanceApi.updateDailyBalance(
        this.establishmentId,
        this.dob,
        this.dailyBalanceData,
      );
    }
  }

  public totalCoins(coins: Coin[]) {
    let total = 0;
    coins.forEach(coin => {
      total = total + coin.count * coin.roleAmount;
    });
    return total;
  }

  public count(amount: number, count: number) {
    if (count === undefined) {
      return 0;
    } else {
      return amount * count;
    }
  }

  public currency(num: number) {
    return CurrencyFormatter.format(num);
  }
}
