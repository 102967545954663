










































































import { defineComponent, onMounted, computed } from '@vue/composition-api';
import { useAsync } from 'vue-async-function';
import { ShiftReview } from '@/common';
import { findByJournal } from '@/api/shiftReviews';
import { formatTime, parseUTC } from '@/util/date';

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { data: reviews, retry } = useAsync(findByJournal, props.journalId);

    const sortedReviews = computed(() => {
      if (!reviews.value) return [];
      return reviews.value
        .filter(x => x.shift?.employee)
        .sort((a, b) => {
          const d1 = a.shift?.start ?? '';
          const d2 = b.shift?.start ?? '';
          const e1 = a.shift?.employee?.name ?? '';
          const e2 = a.shift?.employee?.name ?? '';
          const r1 = d1.localeCompare(d2);
          return r1 === 0 ? e1.localeCompare(e2) : r1;
        });
    });

    onMounted(async () => {
      await retry();
    });

    function employeeName(review: ShiftReview) {
      return review.shift?.employee?.name ?? 'onbekend';
    }

    function startTime(review: ShiftReview) {
      return review.shift?.start ?? 'onbekend';
    }

    function endTime(review: ShiftReview) {
      return review.shift?.end ?? 'onbekend';
    }

    function showClockedIn(review: ShiftReview) {
      return review.shift?.employee?.clockedIn !== undefined;
    }

    function clockedIn(review: ShiftReview) {
      const clockedIn = review.shift?.employee?.clockedIn;
      if (clockedIn === undefined) {
        return undefined;
      }
      return !clockedIn ? false : true;
    }

    function clockTime(review: ShiftReview) {
      const enter = review.shift?.employee?.clockedIn?.enterTime;
      const exit = review.shift?.employee?.clockedIn?.exitTime;
      if (enter && exit) {
        return `Gewerkt van ${formatTime(parseUTC(enter))} tot ${formatTime(parseUTC(exit))}`;
      }
      if (enter) {
        return `Ingeklokt om ${formatTime(parseUTC(enter))}`;
      }
    }

    return {
      sortedReviews,
      employeeName,
      startTime,
      endTime,
      showClockedIn,
      clockedIn,
      clockTime,
    };
  },
});
