import { computed, ref, watch } from '@vue/composition-api';
import { useGetters, useMutations } from '@u3u/vue-hooks';
import { MutationTypes } from '@/store/modules/incidents/mutation-types';
import { findAllIncidents, createIncident } from '@/api/incidents';
import { Incident } from '@/common';
import { UseIncidents, UseIncidentsErrors, UseIncidentsSearchParams } from './types';

export function useIncidents(cacheId: string): UseIncidents {
  const { all, fromCache } = useGetters('incidents', ['all', 'fromCache']);
  const { addIncidents } = useMutations('incidents', {
    addIncidents: MutationTypes.ADD_INCIDENTS,
  });

  const incidents = ref<Incident[]>([]);
  const isLoading = ref(false);
  const error = ref<UseIncidentsErrors>({});

  watch(all, () => (incidents.value = fromCache.value(cacheId)), { immediate: true });

  const search = async (searchParams: UseIncidentsSearchParams) => {
    try {
      isLoading.value = true;
      const payload = await findAllIncidents(searchParams.establishmentId);
      addIncidents({ cacheId, payload });
      error.value.search = undefined;
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const create = async (establishmentId: number, incident: string) => {
    try {
      isLoading.value = true;
      const payload = await createIncident(establishmentId, incident);
      addIncidents({ cacheId, payload });
      error.value.search = undefined;
      return payload;
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    incidents: computed(() => incidents.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
    create,
  };
}
