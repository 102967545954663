
import ShiftReviewForm from './ShiftReviewForm.vue';
import { defineComponent, computed, ref, onMounted, watch } from '@vue/composition-api';
import { useRouter } from '@u3u/vue-hooks';
import { ShiftReview } from '@/common';
import { findByJournal } from '@/api/shiftReviews';
import { useTags } from '@/util/tags';

export default defineComponent({
  components: {
    ShiftReviewForm,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { route } = useRouter();
    const establishmentId = computed(() => Number(route.value.params.establishmentId));

    const loading = ref(false);
    const errorMessage = ref('');
    const reviews = ref<ShiftReview[]>([]);

    const { search: loadShiftReviewTags } = useTags('shiftReviewTags');
    const { search: loadFloorplanTags } = useTags('floorplanTags');

    watch(
      establishmentId,
      id => {
        loadShiftReviewTags({ establishmentId: id, tagType: 'SHIFT_REVIEW' });
        loadFloorplanTags({ establishmentId: id, tagType: 'FLOORPLAN' });
      },
      { immediate: true },
    );

    onMounted(async () => {
      loading.value = true;
      try {
        reviews.value = await findByJournal(props.journalId);
      } catch (e) {
        if (typeof e === 'string') {
          errorMessage.value = e;
        } else {
          errorMessage.value = 'er is iets misgegaan';
        }
      } finally {
        loading.value = false;
      }
    });

    const sortedReviews = computed(() =>
      reviews.value
        .filter(x => x.shift?.employee)
        .sort((a, b) => {
          const d1 = a.shift?.start ?? '';
          const d2 = b.shift?.start ?? '';
          const e1 = a.shift?.employee?.name ?? '';
          const e2 = a.shift?.employee?.name ?? '';
          const r1 = d1.localeCompare(d2);
          return r1 === 0 ? e1.localeCompare(e2) : r1;
        }),
    );

    const updateReview = (review: ShiftReview) => {
      const idx = reviews.value.findIndex(r => r.id === review.id);
      if (idx !== -1) {
        reviews.value.splice(idx, 1, review);
      }
    };

    return {
      loading,
      errorMessage,
      establishmentId,
      reviews,
      sortedReviews,
      updateReview,
    };
  },
});
