import { computed, ref, watch } from '@vue/composition-api';
import { useGetters, useMutations } from '@u3u/vue-hooks';
import { MutationTypes } from '@/store/modules/employees/mutation-types';
import { findAll } from '@/api/employees';
import { Employee } from '@/common';
import { UseEmployees, UseEmployeesErrors } from './types';

export function useEmployees(cacheId: string): UseEmployees {
  const { all, fromCache } = useGetters('employees', ['all', 'fromCache']);
  const { addEmployees } = useMutations('employees', {
    addEmployees: MutationTypes.ADD_EMPLOYEES,
  });

  const employees = ref<Employee[]>([]);
  const isLoading = ref(false);
  const error = ref<UseEmployeesErrors>({});

  watch(all, () => (employees.value = fromCache.value(cacheId)), { immediate: true });

  const search = async (establishmentId: number) => {
    try {
      isLoading.value = true;
      const payload = await findAll(establishmentId);
      addEmployees({ cacheId, payload });
      error.value.search = undefined;
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    employees: computed(() => employees.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
  };
}
