import { Configuration, LogLevel } from '@azure/msal-browser';

export const config: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_AUTH_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AUTH_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_AUTH_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_AUTH_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    tokenRenewalOffsetSeconds: 1000,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: process.env.NODE_ENV === 'development' ? LogLevel.Verbose : LogLevel.Error,
    },
  },
};

export const scopes = [`${process.env.VUE_APP_AZURE_AUTH_CLIENT_ID}/.default`];
