








































































import RatingInput from '@/components/ratingInput/RatingInput.vue';
import { defineComponent, PropType, watch, ref } from '@vue/composition-api';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { JournalNote } from '@/common';

extend('required', required);

export interface FormData {
  subject?: string;
  comment?: string;
  rating?: number;
}

export default defineComponent({
  components: {
    RatingInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    journalNote: {
      type: Object as PropType<JournalNote>,
      default: undefined,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { root, emit }) {
    const formData = ref<FormData>({});
    const saving = ref(false);
    const deleting = ref(false);

    const reset = () => {
      formData.value = {
        subject: props.journalNote?.subject ?? '',
        comment: props.journalNote?.comment ?? '',
        rating: props.journalNote?.rating,
      };
    };

    reset();

    watch(
      () => props.journalNote,
      () => reset(),
    );

    watch(
      () => props.submitting,
      () => {
        if (!props.submitting) {
          saving.value = false;
          deleting.value = false;
          if (!props.error) {
            reset();
          }
        }
      },
    );

    async function submit() {
      const data = { ...formData.value };
      saving.value = true;
      emit('submit', data);
    }

    function remove() {
      root.$bvModal
        .msgBoxConfirm('Weet je zeker het incident wilt verwijderen?', {
          title: 'Bevestig verwijderen',
          okTitle: 'Verwijderen',
          okVariant: 'danger',
          cancelTitle: 'Annuleren',
        })
        .then(async confirmed => {
          if (confirmed && props.journalNote.id) {
            deleting.value = true;
            emit('delete');
          }
        });
    }

    return {
      formData,
      submit,
      remove,
    };
  },
});
