import client from '@/api/client';
import { OccupanceDepartment } from '@/common';

const searchOccupanceDepartments = async (
  establishmentId: number,
): Promise<OccupanceDepartment[]> => {
  const response = await client.get<OccupanceDepartment[]>(
    `/establishments/${establishmentId}/occupance/departments`,
  );
  return response.data;
};

export default searchOccupanceDepartments;
