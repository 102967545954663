import { toNumber } from 'lodash';

export default function useNumber() {
  function round(val: number | string) {
    return Math.round(toNumber(val));
  }

  function floor(val: number | string) {
    return Math.floor(toNumber(val));
  }

  return {
    round,
    floor,
  };
}
