
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ErrorResponse } from '@/api/types';

export default defineComponent({
  props: {
    error: {
      type: [String, Object, Error] as PropType<string | object | Error | ErrorResponse>,
      default: undefined,
    },
  },
  setup(props) {
    const errorMessage = computed(() => {
      if (!props.error) {
        return null;
      }
      if (typeof props.error === 'string') {
        return props.error;
      }
      if (typeof props.error === 'object') {
        if ('detail' in props.error) {
          return props.error.detail;
        }
        if ('message' in props.error) {
          return props.error.message;
        }
      }
      return 'unsupported error';
    });

    return {
      show: computed(() => Boolean(props.error)),
      errorMessage,
    };
  },
});
