
import { defineComponent, computed, ref, PropType, onMounted } from '@vue/composition-api';
import { parseUTC, formatTime } from '@/util/date';
import { Journal, ShiftReview } from '@/common';

const apiUrl = process.env.VUE_APP_JOURNAL_API_URL;

export default defineComponent({
  props: {
    journal: {
      required: true,
      type: Object as PropType<Journal>,
    },
  },
  setup(props) {
    const loading = ref(false);
    const selected = ref('');
    const error = ref('');
    const groupedReviews = ref<Record<string, ShiftReview[]>>({});

    onMounted(async () => {
      try {
        loading.value = true;

        const reviews = props.journal.shiftReviews ?? [];
        const result: Record<string, ShiftReview[]> = {};

        reviews.reduce((result, review) => {
          if (review.shift?.department?.name) {
            const name = review.shift.department.name;
            const key = name.replace(/\s+/g, '').toLocaleLowerCase();
            const reviews = result[key] || [];
            result[key] = [...reviews, review];
          }
          return result;
        }, result);

        groupedReviews.value = result;

        for (const department of Object.keys(result)) {
          if (result[department].length) {
            selected.value = department;
          }
        }
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    });

    const reviews = computed(() => groupedReviews.value[selected.value] ?? []);

    function employeeName(review: ShiftReview) {
      return review.shift?.employee?.name ?? 'onbekend';
    }

    function floorplan(review: ShiftReview) {
      return review.floorplanTags?.map(t => t.name).join(', ') ?? '';
    }

    function startTime(review: ShiftReview) {
      if (review.shift?.start) {
        return formatTime(parseUTC(review.shift.start));
      }
      return 'onbekend';
    }

    function endTime(review: ShiftReview) {
      if (review.shift?.end) {
        return formatTime(parseUTC(review.shift.end));
      }
      return 'onbekend';
    }

    return {
      startTime,
      endTime,
      employeeName,
      floorplan,
      floorplanUrl: computed(() => `${apiUrl}/journals/${props.journal.id}/floorplan`),
      reviews,
      selected,
      groupedReviews,
    };
  },
});
