






















import { defineComponent, onMounted, ref, computed } from '@vue/composition-api';
import { findById } from '@/api/journals';
import { Journal, Shift } from '@/common';

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const journal = ref<Journal>();

    onMounted(async () => {
      journal.value = await findById(props.journalId);
    });

    const shifts = computed(() => {
      if (!journal.value?.shiftReviews) {
        return [];
      }

      const reviews = journal.value.shiftReviews ?? [];

      return reviews
        .filter(r => r.shift?.team?.name.toLowerCase().startsWith('management'))
        .map(s => s.shift)
        .reduce((res, s) => {
          if (s && !res.find(r => r.employeeId === s?.employeeId)) {
            res.push(s);
          }
          return res;
        }, [] as Shift[])
        .sort((a, b) => {
          const d1 = a?.start ?? '';
          const d2 = b?.start ?? '';
          const e1 = a?.employee?.name ?? '';
          const e2 = a?.employee?.name ?? '';
          const r1 = d1.localeCompare(d2);
          return r1 === 0 ? e1.localeCompare(e2) : r1;
        }) as Shift[];
    });

    function employeeName(shift: Shift) {
      return shift.employee?.name ?? 'onbekend';
    }

    return {
      journal,
      shifts,
      employeeName,
    };
  },
});
