















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { InvoiceableReceipt } from '@/common/types';
import CreateInvoice from './components/CreateInvoice.vue';
import UpdateInvoice from './components/UpdateInvoice.vue';

@Component({
  components: { CreateInvoice, UpdateInvoice },
})
export default class InvoiceView extends Vue {
  @Prop({ required: true })
  public establishmentId!: number;

  @Prop({ required: true })
  public receipt!: InvoiceableReceipt;

  public error = '';
  public loading = false;

  @Emit('refresh')
  public refresh() {
    return true;
  }
}
