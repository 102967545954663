import client from '@/api/client';

const removeOccupanceDepartments = async (establishmentId: number, id: number): Promise<void> => {
  const response = await client.delete<void>(
    `/establishments/${establishmentId}/occupance/departments/${id}`,
  );
  return response.data;
};

export default removeOccupanceDepartments;
