import client from './client';

const resource = 'daily-balance';

export const DailyBalancePropertyTypes = {
  TURNOVER: 1,
  TURNOVER_EXCL_VAT: 2,
  CR_CASH: 3,
  CR_PIN: 4,
  CR_IZETTLE: 5,
  CASH: 6,
  PIN: 7,
  IZETTLE: 8,
  VOIDED: 9,
  VOUCHERS: 10,
  DEBTOR: 11,
  EMPLOYEE_PAYMENTS: 12,
  SPECIAL_PAYMENTS: 13,
  OFFERED: 14,
  REPRESENTATION: 15,
  TOTAL_REGISTER_AMOUNT: 16,
  DIFFERENCE_AMOUNT: 17,
} as const;

export type DailyBalancePropertyType = typeof DailyBalancePropertyTypes[keyof typeof DailyBalancePropertyTypes];

export class DailyBalance {
  public establishmentId: number;
  public dob: string;
  public properties: Map<DailyBalancePropertyType, DailyBalancePropertyDto> = new Map();
  public userData: Record<string, object | string[] | string>;
  public miscData: Record<string, object | string[] | string>;

  constructor(data: DailyBalanceDto) {
    this.establishmentId = data.establishmentId;
    this.dob = data.dob;
    data.properties.forEach(p => {
      this.properties.set(p.type, p);
    });
    this.userData = data.userData;
    this.miscData = data.miscData;
  }

  getTurnoverAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.TURNOVER)?.amount;
  }

  getTurnoverWithVouchersAmount(): number | undefined {
    const turnover = this.getTurnoverAmount();
    const voucherAmount = this.getVoucherAmount() ?? 0;
    if (turnover !== undefined) {
      return turnover + voucherAmount;
    }
    return undefined;
  }

  getTurnoverAmountExclVat(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.TURNOVER_EXCL_VAT)?.amount;
  }

  getTurnoverWithVouchersAmountExclVat(): number | undefined {
    const turnover = this.getTurnoverAmountExclVat();
    const voucherAmount = this.getVoucherAmount() ?? 0;
    if (turnover !== undefined) {
      return turnover + voucherAmount;
    }
    return undefined;
  }

  getCashAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.CR_CASH)?.amount;
  }

  getCoinsAmount(): number {
    const coins = this.getCoins();
    let result = 0;
    coins?.forEach((amount, coin) => (result += coin * amount));
    return result;
  }

  getCoinsDifferenceAmount(): number {
    return this.getCoinsAmount() - (this.getCashAmount() ?? 0);
  }

  getPinAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.CR_PIN)?.amount;
  }

  getUserPinAmount(): number {
    const pins = this.getPins() ?? [];
    return pins.reduce((agg, amount) => agg + amount, 0);
  }

  getPinDifferenceAmount(): number {
    return this.getUserPinAmount() - (this.getPinAmount() ?? 0);
  }

  getIZettleAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.IZETTLE)?.amount;
  }

  getPaymentsAmount(): number {
    const payments = this.getPayments() ?? [];
    return payments.reduce((agg, payment) => agg + +payment.amount, 0);
  }

  getSpecialPaymentsAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.SPECIAL_PAYMENTS)?.amount;
  }

  getDebtorAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.DEBTOR)?.amount;
  }

  getVoidAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.VOIDED)?.amount;
  }

  getVoucherAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.VOUCHERS)?.amount;
  }

  getOfferedAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.OFFERED)?.amount;
  }

  getRepresentationAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.REPRESENTATION)?.amount;
  }

  getInCashRegisterAmount(): number {
    return (
      this.getCoinsAmount() +
      this.getUserPinAmount() +
      (this.getIZettleAmount() ?? 0) +
      this.getPaymentsAmount() +
      (this.getSpecialPaymentsAmount() ?? 0) +
      (this.getDebtorAmount() ?? 0)
    );
  }

  getDifferenceAmount(): number {
    return this.getInCashRegisterAmount() - (this.getTurnoverAmount() ?? 0);
  }

  hasStoredDiff(): boolean {
    return !!this.getStoredDiffAmount();
  }

  getStoredDiffAmount(): number {
    return parseInt(this.userData?.diff as string);
  }

  getCoins(): Map<number, number> {
    const data = this.userData?.coins as Record<number, number | string>;
    const result = new Map<number, number>();
    Object.entries(data).forEach(([key, value]) => result.set(Number(key), Number(value)));
    return result;
  }

  getPins(): number[] {
    const data = (this.userData?.pins as string[]) ?? [];
    return data?.map(Number);
  }

  getPayments(): DailyBalancePayment[] {
    return (this.userData?.payments as DailyBalancePayment[]) ?? [];
  }
}

export type DailyBalanceDto = {
  establishmentId: number;
  dob: string;
  properties: DailyBalancePropertyDto[];
  userData: Record<string, object | string[] | string>;
  miscData: Record<string, object | string[] | string>;
};

export type DailyBalancePropertyDto = {
  establishmentId: number;
  dob: string;
  type: DailyBalancePropertyType;
  amount: number;
};

export interface DailyBalanceTender {
  name: string;
  amount: number;
}

export interface DailyBalancePayment {
  name: string;
  amount: number;
}

export interface DailyBalanceSearchParams {
  establishmentId: number;
  dob?: string;
  from?: string;
  to?: string;
}

export default {
  async search(searchParams: DailyBalanceSearchParams): Promise<DailyBalanceDto[]> {
    const { establishmentId, ...params } = searchParams;
    const response = await client.get<DailyBalanceDto[]>(
      `/establishments/${establishmentId}/${resource}`,
      { params },
    );
    return response.data;
  },

  async updateDailyBalance(establishment: number, dob: string, data: DailyBalanceDto) {
    const response = await client.put<DailyBalanceDto>(
      `/establishments/${establishment}/${resource}/${dob}`,
      data,
    );
    return response.data;
  },
};
