
import { defineComponent, PropType, ref } from '@vue/composition-api';
import useEstablishmentSettings from '@/composables/useEstablishmentSettings';
import { Establishment } from '@/common';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, numeric } from 'vee-validate/dist/rules';

extend('required', required);
extend('numeric', numeric);

export interface FormData {
  name: string;
  expectedNumber?: number;
}

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props) {
    const { error, isLoading, updateInventoryItemSettings } = useEstablishmentSettings();
    const formData = ref<FormData[]>([
      {
        name: '',
        expectedNumber: undefined,
      },
    ]);

    function reset() {
      if (props.establishment.inventoryItems?.length) {
        formData.value = props.establishment.inventoryItems;
      }
    }
    reset();

    function addRow() {
      formData.value.push({
        name: '',
        expectedNumber: undefined,
      });
    }

    function removeRow(index: number) {
      formData.value.splice(index, 1);
    }

    function submit() {
      updateInventoryItemSettings({
        establishmentId: props.establishment.id,
        items: formData.value,
      });
    }

    return {
      error,
      isLoading,
      formData,
      reset,
      submit,
      addRow,
      removeRow,
    };
  },
});
