import client from '@/api/client';
import { ref } from '@vue/composition-api';
import { IncidentReport, IncidentReportDto } from '@/common';

const incidentReports = ref<IncidentReport[]>([]);

export default function useIncidentReports(journalId: number) {
  const loading = ref(false);
  const error = ref('');
  const incidentReport = ref<IncidentReport | null>(null);

  const fetchIncidentReports = async () => {
    if (loading.value) {
      return;
    }

    loading.value = true;
    error.value = '';
    incidentReports.value = [];

    try {
      const response = await client.get(`/journals/${journalId}/incidents`);
      incidentReports.value = response.data;
    } catch (error) {
      error.value = `Error fetching data. ${error.message}`;
    } finally {
      loading.value = false;
    }
  };

  const createIncidentReport = async (data: Partial<IncidentReportDto>) => {
    if (loading.value) {
      return;
    }

    loading.value = true;
    error.value = '';

    try {
      const response = await client.post<IncidentReport>(`/journals/${journalId}/incidents`, data);
      incidentReport.value = response.data;
      incidentReports.value.push(incidentReport.value);
    } catch (error) {
      error.value = `Error posting data. ${error.message}`;
    } finally {
      loading.value = false;
    }
  };

  const updateIncidentReport = async (id: number, data: Partial<IncidentReportDto>) => {
    if (loading.value) {
      return;
    }

    loading.value = true;
    error.value = '';

    try {
      const response = await client.put<IncidentReport>(`/incident-reports/${id}`, data);
      incidentReport.value = response.data;
      const idx = incidentReports.value.findIndex(x => x.id === incidentReport.value?.id);
      if (idx !== -1) {
        incidentReports.value.splice(idx, 1, incidentReport.value);
      }
    } catch (error) {
      error.value = `Error putting data. ${error.message}`;
    } finally {
      loading.value = false;
    }
  };

  const deleteIncidentReport = async (id: number) => {
    if (loading.value) {
      return;
    }

    loading.value = true;
    error.value = '';

    try {
      await client.delete(`/incident-reports/${id}`);
      const idx = incidentReports.value.findIndex(x => x.id === id);
      if (idx !== -1) {
        incidentReports.value.splice(idx, 1);
      }
    } catch (error) {
      error.value = `Error deleting data. ${error.message}`;
    } finally {
      loading.value = false;
    }
  };

  if (incidentReports.value.length === 0) {
    fetchIncidentReports();
  }

  // watch(
  //   typeof journalId === 'function' ? journalId : () => journalId,
  //   () => fetchIncidentReports,
  //   { immediate: true },
  // );

  return {
    loading,
    error,
    incidentReport,
    incidentReports,
    fetchIncidentReports,
    createIncidentReport,
    updateIncidentReport,
    deleteIncidentReport,
  };
}
