import {
  createOccupationEmployeePlanning,
  searchOccupationEmployeePlanning,
  updateOccupationEmployeePlanning,
  removeOccupationEmployeePlanning,
} from '@/api/occupationEmployeePlanning';
import { OccupationEmployeePlanning } from '@/common';
import { ref, computed } from '@vue/composition-api';
import { UseOccupationEmployeePlanning, UseOccupationEmployeePlanningErrors } from '../types';

const useOccupationEmployeePlanning = (): UseOccupationEmployeePlanning => {
  const occupationEmployeePlannings = ref<OccupationEmployeePlanning[]>([]);
  const isLoading = ref(false);
  const error = ref<UseOccupationEmployeePlanningErrors>({});

  const search = async (establishmentId: number): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      occupationEmployeePlannings.value = await searchOccupationEmployeePlanning(establishmentId);
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const create = async (
    establishmentId: number,
    data: OccupationEmployeePlanning,
  ): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      const result = await createOccupationEmployeePlanning(establishmentId, data);
      occupationEmployeePlannings.value = [result];
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const update = async (
    establishmentId: number,
    data: OccupationEmployeePlanning,
  ): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      const result = await updateOccupationEmployeePlanning(establishmentId, data);
      occupationEmployeePlannings.value = [result];
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const remove = async (establishmentId: number, id: number): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      await removeOccupationEmployeePlanning(establishmentId, id);
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    occupationEmployeePlannings: computed(() => occupationEmployeePlannings.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
    create,
    update,
    remove,
  };
};

export default useOccupationEmployeePlanning;
