













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Establishment, Prognosis, Reservation, ReservationType, Shift } from '@/common';
import { GetPrognosisByDateRange } from '@/api/prognosis';
import { getReservations } from '@/api/reservations';
import { getCurrentWeather, getHistoricalWeather } from '@/api/weather/index';
import { CachedWeatherData, CurrentForecastWeatherData } from '@/api/weather/types';
import { DailyBalanceDto } from '@/api/dailyBalanceApi';
import { search as searchShifts } from '@/api/shifts';
import { addDays, addWeeks, format, isSameDay, startOfWeek } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import PrognosisComponent from './components/PrognosisComponent.vue';
import ReservationComponent from './components/ReservationComponent.vue';
import WeatherComponent from './components/WeatherComponent.vue';
import { WageCosts } from '@/api/types';
import { searchWageCosts } from '@/api';

interface DayOverviewObject {
  date: Date;
  dayName: string;
  weather?: WeatherData;
  reservations?: ReservationData;
  prognosis?: Prognosis[];
  dailyBalance?: DailyBalanceDto;
  shifts?: Shift[];
  wageCosts?: WageCosts;
}

interface WeatherData {
  tempDay: string;
  tempEve: string;
  weatherIcon: string;
  wind: number;
  windIcon: number;
  precipitation: string;
}

interface ReservationData {
  dinner: number;
  lunch: number;
  drinks: number;
  highTea: number;
  highWineBeer: number;
  other: number;
  reservations: Reservation[];
}

@Component({
  components: { PrognosisComponent, ReservationComponent, WeatherComponent },
})
export default class WeekOverview extends Vue {
  @Prop({ required: true, type: Date })
  public date!: Date;
  @Prop({ required: true, type: Object as () => Establishment })
  public establishment!: Establishment;

  @Prop({ required: true })
  public dailyBalanceData!: DailyBalanceDto[];

  public today = new Date();

  public loading = false;
  public error = '';

  public weekOverview: DayOverviewObject[] = [];

  public weatherForecast!: CurrentForecastWeatherData;
  public weatherHistorical: CachedWeatherData[] = [];
  public shifts: Shift[] = [];
  public wageCosts: WageCosts[] = [];
  public allReservations: Reservation[] = [];
  public allPrognosis: Prognosis[] = [];

  public async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.getWeatherData(),
        this.getReservationsData(),
        this.getPrognosisData(),
        this.getShiftData(),
        this.getWageCostsData(),
      ]);
      await this.loadData();
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  get startOfWeek() {
    return startOfWeek(this.date, { weekStartsOn: 1 });
  }

  get startOfWeekStr() {
    return format(this.startOfWeek, 'yyyy-MM-dd');
  }

  get startOfNextWeek() {
    return startOfWeek(addWeeks(this.startOfWeek, 1), { weekStartsOn: 1 });
  }

  get startOfNextWeekStr() {
    return format(this.startOfNextWeek, 'yyyy-MM-dd');
  }

  public async loadData() {
    let date = this.startOfWeek;
    const endDate = this.startOfNextWeek;

    while (date < endDate) {
      const dayOverviewObject: DayOverviewObject = {
        date: date,
        dayName: format(date, 'iii', { locale }),
      };
      this.weekOverview.push(dayOverviewObject);
      date = addDays(date, 1);
    }

    for (let i = 0; i < this.weekOverview.length; i++) {
      const date = this.weekOverview[i].date;
      this.weekOverview[i].weather = this.getWeather(date);
      this.weekOverview[i].reservations = this.getReservations(date);
      this.weekOverview[i].prognosis = this.getPrognosis(date);
      this.weekOverview[i].dailyBalance = this.getDailyBalance(date);
      this.weekOverview[i].shifts = this.getShifts(date);
      this.weekOverview[i].wageCosts = this.getWageCosts(date);
    }
  }

  public async getWeatherData() {
    try {
      this.weatherForecast = await getCurrentWeather(this.establishment.id);
      this.weatherHistorical = await getHistoricalWeather(
        this.establishment.id,
        this.startOfWeek,
        this.startOfNextWeek,
      );
    } catch (e) {
      this.error = e;
    }
  }

  public async getReservationsData() {
    try {
      this.allReservations = await getReservations(
        this.establishment.id,
        this.startOfWeekStr,
        this.startOfNextWeekStr,
      );
      this.allReservations.sort((a, b) => (a.date > b.date ? 1 : -1));
    } catch (e) {
      this.error = e;
    }
  }

  public async getPrognosisData() {
    try {
      this.allPrognosis = await GetPrognosisByDateRange(
        this.establishment.id,
        this.startOfWeekStr,
        this.startOfNextWeekStr,
      );
    } catch (e) {
      this.error = e;
    }
  }

  public async getShiftData() {
    try {
      this.shifts = await searchShifts({
        establishmentId: this.establishment.id,
        from: this.startOfWeekStr,
        to: this.startOfNextWeekStr,
      });
    } catch (e) {
      this.error = e;
    }
  }

  public async getWageCostsData() {
    try {
      this.wageCosts = await searchWageCosts({
        establishmentId: this.establishment.id,
        from: this.startOfWeekStr,
        to: this.startOfNextWeekStr,
      });
    } catch (e) {
      this.error = e;
    }
  }

  public getShifts(date: Date) {
    return this.shifts.filter(s => isSameDay(date, s.date));
  }

  public getWageCosts(date: Date) {
    return (
      this.wageCosts.find(wc => isSameDay(date, wc.dob)) ?? {
        dob: date,
        plannedHours: 0,
        plannedCosts: 0,
      }
    );
  }

  public getDailyBalance(date: Date) {
    const dob = format(date, 'yyyy-MM-dd') + 'T00:00:00.000Z';
    return this.dailyBalanceData.filter(d => d.dob === dob)?.[0];
  }

  public getPrognosis(date: Date) {
    if (date !== undefined) {
      const selectedPrognosis: Prognosis[] = [];
      const dateFormatted = format(new Date(date), 'yyyy-MM-dd');

      this.allPrognosis.forEach(prognosis => {
        const prognosisDate = format(new Date(prognosis.date), 'yyyy-MM-dd');
        if (prognosisDate === dateFormatted) {
          selectedPrognosis.push(prognosis);
        }
      });
      return selectedPrognosis;
    } else {
      return undefined;
    }
  }

  public getWeather(date: Date) {
    const dateFormatted = format(date, 'yyyy-MM-dd');
    const today = format(new Date(), 'yyyy-MM-dd');

    let weatherData: WeatherData = {
      tempDay: 'nb',
      tempEve: 'nb',
      weatherIcon: 'nb',
      wind: 0,
      windIcon: 0,
      precipitation: '0',
    };

    if (dateFormatted < today) {
      this.weatherHistorical.forEach(data => {
        let precipitation = 0;
        if (data.data.hourly[12].rain !== undefined) {
          precipitation = data.data.hourly[12].rain['1h'];
        } else if (data.data.hourly[12].snow !== undefined) {
          precipitation = data.data.hourly[12].snow['1h'];
        }
        const weatherDate = format(new Date(data.date), 'yyyy-MM-dd');
        if (weatherDate === dateFormatted) {
          weatherData = {
            tempDay: data.data.hourly[12].temp.toFixed(0),
            tempEve: data.data.hourly[20].temp.toFixed(0),
            weatherIcon: this.formatWeatherIcon(data.data.hourly[12].weather[0].id),
            wind: data.data.hourly[12].wind_speed,
            windIcon: data.data.hourly[12].wind_deg,
            precipitation: precipitation.toFixed(0),
          };
        }
      });
    } else {
      this.weatherForecast.daily.forEach(data => {
        let precipitation = 0;
        const weatherDate = format(new Date(data.dt * 1000), 'yyyy-MM-dd');
        if (data.rain !== undefined) {
          precipitation = data.rain;
        } else if (data.snow !== undefined) {
          precipitation = data.snow;
        }
        if (weatherDate === dateFormatted) {
          weatherData = {
            tempDay: data.temp.day.toFixed(0),
            tempEve: data.temp.eve.toFixed(0),
            weatherIcon: this.formatWeatherIcon(data.weather[0].id),
            wind: data.wind_speed,
            windIcon: data.wind_deg,
            precipitation: precipitation.toFixed(0),
          };
        }
      });
    }

    return weatherData;
  }

  public formatWeatherIcon(iconId: number) {
    if (iconId === null) {
      return '';
    }
    return 'owm-' + iconId;
  }

  public getReservations(date: Date) {
    const searchDate = format(date, 'yyyy-MM-dd');
    const reservations: ReservationData = {
      dinner: 0,
      lunch: 0,
      drinks: 0,
      highTea: 0,
      highWineBeer: 0,
      other: 0,
      reservations: [],
    };

    this.allReservations.forEach(reservation => {
      const reservationDate = format(new Date(reservation.date), 'yyyy-MM-dd');
      if (searchDate === reservationDate) {
        reservations.reservations.push(reservation);
        switch (reservation.type) {
          case ReservationType.DINNER:
            reservations.dinner += reservation.amountOfPeople;
            break;
          case ReservationType.LUNCH:
            reservations.lunch += reservation.amountOfPeople;
            break;
          case ReservationType.DRINKS:
            reservations.drinks += reservation.amountOfPeople;
            break;
          case ReservationType.HIGH_TEA:
            reservations.highTea += reservation.amountOfPeople;
            break;
          case ReservationType.HIGH_WINE_BEER:
            reservations.highWineBeer += reservation.amountOfPeople;
            break;
          case ReservationType.OTHER:
            reservations.other += reservation.amountOfPeople;
            break;
        }
      }
    });
    return reservations;
  }

  public getIcon(type: string) {
    if (type === ReservationType.DINNER) {
      return 'utensils';
    }
    if (type === ReservationType.LUNCH) {
      return 'utensils';
    }
    if (type === ReservationType.DRINKS) {
      return 'glass-cheers';
    }
    if (type === ReservationType.HIGH_WINE_BEER) {
      return 'beer';
    }
    if (type === ReservationType.HIGH_TEA) {
      return 'coffee';
    }
    if (type === ReservationType.OTHER) {
      return 'question';
    }
  }

  public formatTime(rawTime: string | null) {
    if (!rawTime) return;
    let timeNumber = parseInt(rawTime.slice(0, 2), 10);
    if (timeNumber > 23) {
      timeNumber = timeNumber - 24;
      rawTime = timeNumber < 10 ? '0' + timeNumber.toString() : timeNumber.toString();
      rawTime = rawTime + ':00:00';
    }
    return rawTime.substring(0, 5);
  }

  public formatDate(date: Date) {
    return format(date, 'dd-MM-yyyy');
  }

  public isToday(date: Date) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const checkDate = format(new Date(date), 'yyyy-MM-dd');
    return today === checkDate;
  }
}
