import client from '../client';
import useAxios from '@/util/axios';
import { normalize } from './serialize';
import { ShiftDto } from './types';

const { getCancelToken } = useAxios();

export interface ShiftSearchParams {
  establishmentId: number;
  date?: string;
  from?: string;
  to?: string;
}

export async function search(searchParams: ShiftSearchParams, signal?: AbortSignal) {
  const { establishmentId, ...params } = searchParams;
  const result = await client.get<ShiftDto[]>(`/establishments/${establishmentId}/shifts`, {
    params,
    cancelToken: getCancelToken(signal),
  });
  return result.data.map(normalize);
}
