import client from '../client';
import useAxios from '@/util/axios';
import { Establishment } from '@/common';
import { normalizeEstablishment } from '../serialize';

const { getCancelToken } = useAxios();

export async function findById(id: number, signal?: AbortSignal) {
  const result = await client.get<Establishment>(`/establishments/${id}`, {
    cancelToken: getCancelToken(signal),
  });
  return normalizeEstablishment(result.data);
}

export async function findAll(signal?: AbortSignal) {
  const result = await client.get<Establishment[]>(`/establishments`, {
    cancelToken: getCancelToken(signal),
  });
  return result.data.map(normalizeEstablishment);
}

export async function update(id: number, data: Partial<Establishment>) {
  const result = await client.put<Establishment>(`/establishments/${id}`, data);
  return normalizeEstablishment(result.data);
}
