
import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import { useRouter } from '@u3u/vue-hooks';
import { useTags } from '@/util/tags';
import useIncidentReports from '@/hooks/useIncidentReports';
import IncidentReportForm from './IncidentReportForm.vue';
import { useEmployees } from '@/util/employees';
import { useIncidents } from '@/util/incidents';

export default defineComponent({
  components: {
    IncidentReportForm,
  },
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { route } = useRouter();
    const establishmentId = computed(() => Number(route.value.params.establishmentId));

    const showForm = ref(false);
    const { search: loadIncidents } = useIncidents('incidentSelect');
    const { search: loadTags } = useTags('incidentReportTags');
    const { search: loadEmployees } = useEmployees('employeeSelect');
    const { incidentReports } = useIncidentReports(props.journalId);

    watch(
      establishmentId,
      establishmentId => {
        loadIncidents({ establishmentId });
        loadTags({ establishmentId, tagType: 'INCIDENT_REPORT' });
        loadEmployees(establishmentId);
      },
      { immediate: true },
    );
    watch(incidentReports, () => (showForm.value = incidentReports.value.length === 0));

    return {
      showForm,
      incidentReports,
    };
  },
});
