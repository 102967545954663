
import { defineComponent } from '@vue/composition-api';
import IconComponent from './IconComponent.vue';

export default defineComponent({
  components: { IconComponent },
  props: {
    department: {
      type: String,
      required: true,
    },
    planned: {
      type: Number,
      required: true,
    },
    needed: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    function getColor(needed: number | undefined, planned: number) {
      if (!needed) {
        return 'text-warning';
      }
      if (needed != planned) {
        return 'text-danger';
      }
    }

    function format(number: number | undefined) {
      if (!number) {
        return 'nb';
      } else {
        return number.toString();
      }
    }

    return {
      props,
      getColor,
      format,
    };
  },
});
