import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import { Establishment } from '@/common';

export interface EstablishmentSelectState {
  establishments: Record<number, Establishment>;
}

const state: EstablishmentSelectState = {
  establishments: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
} as Module<EstablishmentSelectState, RootState>;
