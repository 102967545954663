import client from '@/api/client';
import { ref } from '@vue/composition-api';
import { JournalNote } from '@/common';

export default function useJournalNotes(journalId: number) {
  const isLoading = ref(false);
  const error = ref('');
  const journalNotes = ref<JournalNote[]>([]);

  const fetchJournalNotes = async () => {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;
    error.value = '';
    journalNotes.value = [];

    try {
      const response = await client.get(`/journals/${journalId}/notes`);
      journalNotes.value = response.data;
    } catch (error) {
      error.value = `Error fetching data. ${error.message}`;
    } finally {
      isLoading.value = false;
    }
  };

  const createJournalNote = async (data: Partial<JournalNote>) => {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;
    error.value = '';

    try {
      const response = await client.post<JournalNote>(`/journals/${journalId}/notes`, data);
      journalNotes.value.push(response.data);
    } catch (error) {
      error.value = `Error posting data. ${error.message}`;
    } finally {
      isLoading.value = false;
    }
  };

  const updateJournalNote = async (id: number, data: Partial<JournalNote>) => {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;
    error.value = '';

    try {
      const response = await client.put<JournalNote>(`/journal-notes/${id}`, data);
      const idx = journalNotes.value.findIndex(x => x.id === response.data.id);
      if (idx !== -1) {
        journalNotes.value.splice(idx, 1, response.data);
      }
    } catch (error) {
      error.value = `Error putting data. ${error.message}`;
    } finally {
      isLoading.value = false;
    }
  };

  const deleteJournalNote = async (id: number) => {
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;
    error.value = '';

    try {
      await client.delete(`/journal-notes/${id}`);
      const idx = journalNotes.value.findIndex(x => x.id === id);
      if (idx !== -1) {
        journalNotes.value.splice(idx, 1);
      }
    } catch (error) {
      error.value = `Error deleting data. ${error.message}`;
    } finally {
      isLoading.value = false;
    }
  };

  if (journalNotes.value.length === 0) {
    fetchJournalNotes();
  }

  return {
    isLoading,
    error,
    journalNotes,
    fetchJournalNotes,
    createJournalNote,
    updateJournalNote,
    deleteJournalNote,
  };
}
