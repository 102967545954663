
import { OccupanceRange } from '@/common';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import OccupancyTableRow from './OccupancyTableRow.vue';

export default defineComponent({
  components: {
    OccupancyTableRow,
  },
  emits: ['enable-edit'],
  props: {
    occupancyRanges: {
      type: Array as PropType<OccupanceRange[]>,
      required: true,
    },
    daypartId: {
      type: Number,
      required: true,
    },
    establishmentId: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const edit = ref<boolean>(false);
    function addRow() {
      props.occupancyRanges.push({
        establishmentId: props.establishmentId,
        daypartId: props.daypartId,
        name: '',
        lower: 0,
        upper: 0,
      });
    }

    watch(
      () => props.edit,
      editValue => {
        edit.value = editValue;
      },
    );

    function enableEdit() {
      emit('enable-edit', true);
    }

    function submit() {
      emit('save');
    }

    function removeRow(index: number) {
      props.occupancyRanges.splice(index, 1);
    }

    return {
      props,
      addRow,
      submit,
      removeRow,
      enableEdit,
    };
  },
});
