
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { useTags } from '@/util/tags';
import { Tag } from '@/common';

export default defineComponent({
  model: {
    prop: 'tagIds',
    event: 'update',
  },
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
    tagIds: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { tags, isLoading, error } = useTags('incidentReportTags');
    const { isLoading: createIsLoading, error: createError, create } = useTags(
      'incidentReportTags',
    );

    const model = computed<Tag[]>({
      get: () => tags.value.filter(tag => props.tagIds.find(id => id === tag.id)),
      set: value =>
        emit(
          'update',
          value.map(t => t.id),
        ),
    });

    const createTag = async (tag: string) => {
      const newTag = await create(props.establishmentId, tag, 'INCIDENT_REPORT');
      if (newTag) {
        model.value = [...model.value, newTag];
      }
    };

    return {
      tags,
      isLoading: computed(() => isLoading.value || createIsLoading.value),
      error: computed(() => error.value || createError.value),
      model,
      createTag,
    };
  },
});
