

















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { addWeeks, startOfWeek, format } from 'date-fns';
import { Establishment, Journal, ShiftReview } from '@/common/types';

import journalApi from '@/api/journalApi';

import WeekOverview from './components/weekOverview/weekOverview.vue';
import HeaderWidget from './components/header/HeaderWidget.vue';

import StartupOverview from './components/startup/StartupOverview.vue';
import EmployeesOverview from './components/employees/EmployeeOverview.vue';
import IncidentsOverview from './components/incidents/IncidentsOverview.vue';
import NotesOverview from './components/notes/NotesOverview.vue';
import QuestionsOverview from './components/questions/QuestionsOveview.vue';
import DebtorsList from '@/views/debtors/DebtorsList.vue';

import JournalsOverview from './components/journals/JournalsOverview.vue';

import dailyBalanceApi, { DailyBalanceDto } from '@/api/dailyBalanceApi';

const establishment = namespace('establishments');

@Component({
  components: {
    HeaderWidget,
    WeekOverview,
    StartupOverview,
    EmployeesOverview,
    DebtorsList,
    IncidentsOverview,
    NotesOverview,
    QuestionsOverview,
    JournalsOverview,
  },
})
export default class DashboardView extends Vue {
  @establishment.Getter('byId')
  public findEstablishmentById!: (id: number) => Establishment;
  @establishment.Getter('sortedEstablishments')
  public establishments!: Establishment[];

  @Prop({ required: true, type: Number })
  public establishmentId!: number;
  @Prop({ required: true, type: Date })
  public date!: Date;

  public dailyBalanceData!: DailyBalanceDto[];

  public loading = false;
  public errorMessage = '';

  public journals: Journal[] = [];

  get dateAndEstablishment() {
    return this.establishmentId + ':' + format(this.date, 'yyyy-MM-dd');
  }

  get establishment() {
    return this.establishments.find(e => e.id === this.establishmentId);
  }

  get shiftReviews() {
    return this.journals.reduce((result, journal) => {
      return result.concat(journal.shiftReviews ?? []);
    }, [] as ShiftReview[]);
  }

  get startOfWeek() {
    return startOfWeek(this.date, { weekStartsOn: 1 });
  }

  get startOfWeekStr() {
    return format(this.startOfWeek, 'yyyy-MM-dd');
  }

  get startOfNextWeek() {
    return startOfWeek(addWeeks(this.startOfWeek, 1), { weekStartsOn: 1 });
  }

  get startOfNextWeekStr() {
    return format(this.startOfNextWeek, 'yyyy-MM-dd');
  }

  @Watch('dateAndEstablishment', { immediate: true })
  public async onDateChange() {
    const from = format(startOfWeek(this.date, { weekStartsOn: 1 }), 'yyyy-MM-dd');
    const to = format(startOfWeek(addWeeks(this.date, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd');

    try {
      this.loading = true;
      this.journals = await journalApi.getByDateRange(this.establishmentId, from, to);
      await this.getDailyBalanceData();
    } catch (e) {
      if (typeof e === 'string') {
        this.errorMessage = e;
      } else {
        this.errorMessage = 'er is iets misgegaan';
      }
    } finally {
      this.loading = false;
    }
  }

  public async getDailyBalanceData() {
    const searchParams = {
      establishmentId: this.establishmentId,
      from: this.startOfWeekStr,
      to: this.startOfNextWeekStr,
    };

    this.dailyBalanceData = await dailyBalanceApi.search(searchParams);
    this.dailyBalanceData.sort((a, b) => (a.dob > b.dob ? 1 : -1));
  }
}
