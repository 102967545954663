import { parseUTC } from '@/util/date';
import { Prognosis } from '@/common';
import { PrognosisDto } from './types';

export function normalize(dto: PrognosisDto): Prognosis {
  return {
    ...dto,
    date: parseUTC(dto.date),
  };
}
