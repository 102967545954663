


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    header: {
      type: String,
    },
    footer: {
      type: String,
    },
  },
  setup() {
    return {};
  },
});
