










import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { InvoiceableReceipt } from '@/common/types';
import { create } from '@/api/invoices';
import { InvoiceEditFormDto } from '@/api/invoices/types';
import InvoiceForm from './InvoiceForm.vue';
import { Form } from './types';
import { format } from 'date-fns';

@Component({
  components: { InvoiceForm },
})
export default class CreateInvoice extends Vue {
  @Prop({ required: true })
  public establishmentId!: number;

  @Prop({ required: true })
  public receipt!: InvoiceableReceipt;

  public error = '';
  public loading = false;

  public form: Form = {
    establishmentId: this.establishmentId,
    checkId: String(this.receipt.check),
    identification: this.receipt.identification,
    amount: this.receipt.amount.formatted,
    reservationId: null,
    debtorNumber: null,
    invoiceDate: new Date(),
    invoiceNumber: '',
    sentDate: null,
    reference: '',
    contact: '',
    costCenter: '',
    department: '',
    name: [this.receipt?.firstname, this.receipt?.middlename, this.receipt?.lastname]
      .filter(Boolean)
      .join(' '),
    address1: this.receipt.address1,
    address2: '',
    houseNumber: '',
    postalCode: this.receipt.zipcode,
    city: this.receipt.city,
    country: 'NL',
    phone: this.receipt.phone,
    email: '',
  };

  @Emit('refresh')
  public refresh() {
    return true;
  }

  public async createInvoice(form: Form) {
    const lineItems = [];

    const costHigh = this.receipt.totalInclTaxHigh?.amount ?? 0;
    if (costHigh) {
      lineItems.push({
        description: 'Volgens bon (btw hoog)',
        quantity: 1,
        cost: costHigh / 1.21,
        tax: 0.21,
      });
    }

    const costLow = this.receipt.totalInclTaxLow?.amount ?? 0;
    if (costLow) {
      lineItems.push({
        description: 'Volgens bon (btw laag)',
        quantity: 1,
        cost: costLow / 1.09,
        tax: 0.09,
      });
    }

    const invoice: Partial<InvoiceEditFormDto> = {
      invoiceDate: format(new Date(), 'yyyy-MM-dd'),
      establishmentId: form.establishmentId,
      checkNo: form.checkId,
      checkDob: this.receipt.date,
      reference: form.reference ?? '',
      contactPerson: form.contact ?? '',
      costCenter: form.costCenter ?? '',
      department: form.department ?? '',
      debtorName: form.name ?? '',
      debtorAddress1: form.address1 ?? '',
      debtorAddress2: form.address2 ?? '',
      debtorHousenumber: form.houseNumber ?? '',
      debtorPostalCode: form.postalCode ?? '',
      debtorCity: form.city ?? '',
      debtorCountry: form.country ?? '',
      debtorPhone: form.phone ?? '',
      debtorEmail: form.email ?? '',
      lineItems,
    };

    try {
      this.loading = true;
      await create(this.establishmentId, invoice);
    } catch (e) {
      this.error = e;
    } finally {
      this.refresh();
      this.loading = false;
    }
  }
}
