
import RatingInput from '@/components/ratingInput/RatingInput.vue';
import ShiftReviewTagMultiSelect from '@/components/shiftReview/ShiftReviewTagMultiSelect.vue';
import FloorplanTagMultiSelect from '@/components/shiftReview/FloorplanTagMultiSelect.vue';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { update } from '@/api/shiftReviews';
import { ShiftReview, Employee, Shift } from '@/common';
import { ShiftReviewEditFormDto } from '@/api/shiftReviews/types';

extend('required', required);

export default defineComponent({
  components: {
    RatingInput,
    ShiftReviewTagMultiSelect,
    FloorplanTagMultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  emits: ['updated'],
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
    shiftReview: {
      type: Object as PropType<ShiftReview>,
      required: true,
    },
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    shift: {
      type: Object as PropType<Shift>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const error = ref('');
    const sicknessModel = ref(props.shiftReview.sick);
    const sicknessCommentModel = ref(props.shiftReview.sicknessComment);
    const form = ref<ShiftReviewEditFormDto>({
      rating: props.shiftReview.rating,
      comment: props.shiftReview.comment,
      sick: props.shiftReview.sick,
      sicknessComment: props.shiftReview.sicknessComment,
      tags: props.shiftReview.tags?.map(t => t.id) ?? [],
      floorplanTags: props.shiftReview.floorplanTags?.map(t => t.id) ?? [],
    });

    const resetForm = () => {
      sicknessModel.value = props.shiftReview.sick;
      sicknessCommentModel.value = props.shiftReview.sicknessComment;
      form.value = {
        rating: props.shiftReview.rating,
        comment: props.shiftReview.comment,
        sick: props.shiftReview.sick,
        sicknessComment: props.shiftReview.sicknessComment,
        tags: props.shiftReview.tags?.map(t => t.id) ?? [],
        floorplanTags: props.shiftReview.floorplanTags?.map(t => t.id) ?? [],
      };
    };

    watch(
      () => props.shiftReview,
      () => resetForm(),
    );

    async function submit() {
      if (loading.value) return;

      error.value = '';
      loading.value = true;
      try {
        form.value.sick = sicknessModel.value;
        form.value.sicknessComment = sicknessModel.value ? sicknessCommentModel.value : null;
        const result = await update(props.shiftReview.id, form.value);
        emit('updated', result);
      } catch (e) {
        if (typeof e === 'string') {
          error.value = e;
        } else {
          error.value = 'er is iets misgegaan';
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      loading,
      error,
      sicknessModel,
      sicknessCommentModel,
      form,
      submit,
    };
  },
});
