
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Tag, TagType } from '@/common';
import { useTags } from '@/util/tags';
import CreateTagForm from './CreateTagForm.vue';
import TagsEditForm from './TagsEditForm.vue';
import TagsMergeForm from './TagsMergeForm.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

const fields = [
  {
    key: 'options',
    label: '',
    class: ['wrap'],
  },
  {
    key: 'name',
    label: 'Omschrijving',
    sortable: true,
  },
];

export interface TableData {
  items: Tag[];
  fields: Tablefields[];
}

export interface Tablefields {
  key: string;
  label: string;
  sortable?: boolean;
}

export default defineComponent({
  props: {
    tagType: {
      type: String as PropType<TagType>,
      required: true,
    },
    establishmentId: {
      type: Number,
      required: true,
    },
  },
  components: {
    CreateTagForm,
    TagsEditForm,
    TagsMergeForm,
    ErrorMessage,
  },
  setup(props) {
    const { isLoading: isSearchLoading, tags, search } = useTags(`settings-${props.tagType}`);
    search({ establishmentId: props.establishmentId, tagType: props.tagType });

    const tableData = ref<TableData>();
    const selectedTagIds = ref<number[]>([]);
    const selectedTags = computed(() =>
      selectedTagIds.value.map(id => tags.value.find(tag => tag.id === id)),
    );
    const selectedTag = ref<Tag>();
    const newTag = ref('');
    const showMergeModal = ref(false);

    const selectTag = (item: Tag) => {
      const idx = selectedTagIds.value.indexOf(item.id);
      if (idx === -1) {
        selectedTagIds.value.push(item.id);
      } else {
        selectedTagIds.value.splice(idx, 1);
      }
    };

    const onMerged = () => {
      selectedTagIds.value = [];
      showMergeModal.value = false;
    };

    return {
      tableData,
      tags,
      isSearchLoading,
      fields,
      selectedTagIds,
      newTag,
      selectedTags,
      selectedTag,
      showMergeModal,
      selectTag,
      onMerged,
    };
  },
});
