import { OccupanceDepartment } from '@/common';
import { ref, computed } from '@vue/composition-api';
import { UseOccupanceDepartments, UseOccupanceDepartmentsErrors } from '../types';
import {
  searchOccupanceDepartment,
  createOccupanceDepartment,
  updateOccupanceDepartment,
  removeOccupanceDepartment,
} from '@/api/';
import { OccupanceDepartmentData } from '@/api/types';

const useOccupanceDepartments = (): UseOccupanceDepartments => {
  const departments = ref<OccupanceDepartment[]>([]);
  const isLoading = ref(false);
  const error = ref<UseOccupanceDepartmentsErrors>({});

  const search = async (establishmentId: number): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      departments.value = await searchOccupanceDepartment(establishmentId);
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const create = async (establishmentId: number, data: OccupanceDepartmentData): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      const response = await createOccupanceDepartment(establishmentId, data);
      departments.value = [response];
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const update = async (establishmentId: number, data: OccupanceDepartmentData): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      departments.value = await updateOccupanceDepartment(establishmentId, data);
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  const remove = async (establishmentId: number, id: number): Promise<void> => {
    try {
      error.value.search = undefined;
      isLoading.value = true;
      await removeOccupanceDepartment(establishmentId, id);
      departments.value = [];
    } catch (e) {
      error.value.search = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    departments: computed(() => departments.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    search,
    create,
    update,
    remove,
  };
};

export default useOccupanceDepartments;
