import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import { Tag } from '@/common';

export interface TagState {
  tags: Record<number, Tag>;
  cache: Record<string, number[]>;
}

const state: TagState = {
  tags: {},
  cache: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
} as Module<TagState, RootState>;
