import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

const strict = process.env.NODE_ENV !== 'production';

export interface RootState {
  version: string;
}

export default new Vuex.Store<RootState>({
  state: {
    version: '1.0.0',
  },
  strict,
  modules,
});
