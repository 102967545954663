
import { defineComponent } from '@vue/composition-api';
import ChefIcon from '../icons/chef.svg';
import DishwasherIcon from '../icons/dishwasher.svg';
import WaiterIcon from '../icons/waiter.svg';
import ManagerIcon from '../icons/manager.svg';
import CleaningIcon from '../icons/cleaning.svg';
import MiseEnPlaceIcon from '../icons/miseenplace.svg';

export default defineComponent({
  components: {
    ChefIcon,
    DishwasherIcon,
    WaiterIcon,
    ManagerIcon,
    CleaningIcon,
    MiseEnPlaceIcon,
  },
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
