import Vue from 'vue';
import { MutationTree } from 'vuex';
import { EmployeeState } from '.';
import { MutationTypes } from './mutation-types';
import { Employee } from '@/common';

export default {
  [MutationTypes.ADD_EMPLOYEES](
    state,
    { cacheId, payload }: { cacheId: string; payload: Employee | Employee[] },
  ) {
    const cache = new Set(state.cache[cacheId]);
    payload = ([] as Employee[]).concat(payload);

    payload.forEach(employee => {
      Vue.set(state.employees, employee.id, employee);
      cache.add(employee.id);
    });
    state.cache[cacheId] = Array.from(cache);
  },

  [MutationTypes.REMOVE_EMPLOYEES](
    state,
    { cacheId, payload }: { cacheId: string; payload: number | number[] },
  ) {
    const cache = new Set(state.cache[cacheId]);
    payload = ([] as number[]).concat(payload);

    payload.forEach(id => cache.delete(id));
    state.cache[cacheId] = Array.from(cache);

    const cached = new Set();
    Object.values(state.cache).forEach(cache => cache.forEach(id => cached.add(id)));
    const orphans = Object.keys(state.employees).filter(id => !cached.has(Number(id)));
    orphans.forEach(id => Vue.delete(state.employees, id));
  },
} as MutationTree<EmployeeState>;
