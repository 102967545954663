import { useEstablishments } from '@/util/establishments';
import { ref } from '@vue/composition-api';
import { UseShifts, UseShiftsErrors } from '../types';
import { search } from '@/api/shifts';
import { Daypart, Shift } from '@/common';

const useShifts = (): UseShifts => {
  const shifts = ref<Shift[]>([]);
  const isLoading = ref(false);
  const error = ref<UseShiftsErrors>({});
  const { establishment } = useEstablishments();

  const get = async (dob: string): Promise<void> => {
    const searchParams = {
      establishmentId: establishment.value?.id ?? 0,
      date: dob,
    };

    try {
      error.value.get = undefined;
      isLoading.value = true;
      if (establishment.value) {
        shifts.value = await search(searchParams);
      }
      shifts.value.forEach((shift, key) => {
        const workingDayparts: Daypart[] = [];
        const dayparts = establishment.value?.dayparts ? [...establishment.value.dayparts] : [];

        dayparts.sort(
          (a, b) =>
            new Date('1970-01-01' + a.start).getSeconds() -
            new Date('1970-01-01' + b.start).getSeconds(),
        );

        dayparts.forEach((daypart, key) => {
          const daypartStart = new Date('1970-01-01 ' + daypart.start);
          const daypartEnd = new Date('1970-01-01 ' + daypart.end);
          // const shiftStart = new Date('1970-01-01' + shift.start);
          const shiftEnd = new Date('1970-01-01 ' + shift.end);

          if (key === 0 && shiftEnd <= daypartEnd) {
            workingDayparts.push(daypart);
          } else if (shiftEnd > daypartStart) {
            workingDayparts.push(daypart);
          }
        });

        shifts.value[key].dayparts = workingDayparts;
      });
    } catch (e) {
      error.value.get = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    shifts,
    isLoading,
    error,
    get,
  };
};

export default useShifts;
