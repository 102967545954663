


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import WeatherIcon from '@/components/weather/WeatherIcon.vue';
import WeatherWindIcon from '@/components/weather/WeatherWindIcon.vue';
import { Establishment } from '@/common';
import useWeather from '@/util/weather';

interface WeatherData {
  tempDay: string;
  tempEve: string;
  weatherIcon: string;
  wind: number;
  windIcon: number;
  precipitation: string;
}

const { ms2Bft, ms2Kmh } = useWeather();

@Component({
  components: { WeatherIcon, WeatherWindIcon },
})
export default class WeatherComponent extends Vue {
  @Prop({ required: true })
  public weather!: WeatherData;

  @Prop({ required: true })
  public establishment!: Establishment;

  get windWarning() {
    if (this.establishment.weatherWindThreshold) {
      return this.weather.wind >= this.establishment.weatherWindThreshold;
    } else {
      return false;
    }
  }

  get wind() {
    if (!this.establishment.weatherWindUnit) {
      return this.weather.wind;
    }

    switch (this.establishment.weatherWindUnit) {
      case 'KMH':
        return ms2Kmh(this.weather.wind);
      case 'BFT':
        return ms2Bft(this.weather.wind);
      default:
        return Math.round(this.weather.wind * 10) / 10;
    }
  }

  get windUnit() {
    if (!this.establishment.weatherWindUnit) {
      return 'ms';
    }

    switch (this.establishment.weatherWindUnit) {
      case 'KMH':
        return 'km/h';
      case 'BFT':
        return 'bft';
      default:
        return 'ms';
    }
  }

  public error = '';
}
