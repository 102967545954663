
import { defineComponent, computed } from '@vue/composition-api';
import { useEmployees } from '@/util/employees';
import { Employee } from '@/common';

export default defineComponent({
  model: {
    prop: 'employeeId',
    event: 'update',
  },
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
    employeeId: {
      type: Number,
      default: undefined,
    },
    groupClockedIn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { employees, isLoading, error } = useEmployees('employeeSelect');

    const model = computed<Employee | undefined>({
      get: () => employees.value.find(employee => employee.id === props.employeeId),
      set: value => emit('update', value?.id),
    });

    const groupedEmployees = computed(() => {
      return [
        {
          group: 'Ingeroosterd',
          employees: employees.value.filter(e => e.clockedIn),
        },
        {
          group: 'Overige',
          employees: employees.value.filter(e => !e.clockedIn),
        },
      ];
    });

    return {
      employees,
      groupedEmployees,
      isLoading,
      error,
      model,
    };
  },
});
