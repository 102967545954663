import client from '@/api/client';
import { OccupationEmployeePlanning } from '@/common';

const createOccupationEmployeePlanning = async (
  establishmentId: number,
  data: OccupationEmployeePlanning,
): Promise<OccupationEmployeePlanning> => {
  const response = await client.post<OccupationEmployeePlanning>(
    `/establishments/${establishmentId}/occupance/planning`,
    data,
  );
  return response.data;
};

export default createOccupationEmployeePlanning;
