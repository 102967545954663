














































import { Vue, Component, Prop } from 'vue-property-decorator';
import DebtorItem from './components/DebtorItem.vue';
import { findInvoiceableReceipts } from '@/api/receipt';
import { InvoiceableReceipt } from '@/common/types';
import { format } from 'date-fns';

@Component({
  components: { DebtorItem },
})
export default class DebtorsList extends Vue {
  @Prop({ required: true, type: Date })
  public from!: Date;

  @Prop({ required: true, type: Date })
  public to!: Date;

  @Prop({ required: true, type: Number })
  public establishmentId!: number;

  @Prop({ type: Boolean, default: false })
  public allowSend!: boolean;

  public debtors: InvoiceableReceipt[] = [];

  public loading = false;
  public error = '';

  get sorted() {
    return this.debtors.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
  }

  public async mounted() {
    await this.loadDebtors();
  }

  public refresh() {
    this.loadDebtors();
  }

  public async loadDebtors() {
    if (this.loading) return;
    try {
      this.loading = true;
      this.debtors = await findInvoiceableReceipts(
        this.establishmentId,
        format(this.from, 'yyyy-MM-dd'),
        format(this.to, 'yyyy-MM-dd'),
      );
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }
}
