



























































































































































































































import { defineComponent, reactive, ref, watch, onMounted } from '@vue/composition-api';
import client from '@/api/client';
import { useAsync } from 'vue-async-function';
import { Journal } from '@/common/types';

interface QuestionsForm {
  closeStaffComment: string;
  closeStaffRating: number | null;
  closeKitchenComment: string;
  closeKitchenRating: number | null;
  closeGuestsComment: string;
  closeGuestsRating: number | null;
  closeMiscComment: string;
  closeMiscRating: number | null;
}

const API_URL = process.env.VUE_APP_JOURNAL_API_URL;

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const staffMessage = ref();
    const kitchenMessage = ref();
    const guestsMessage = ref();
    const miscMessage = ref();
    const questionsData = ref<Partial<QuestionsForm> | null>(null);
    const form = reactive<QuestionsForm>({
      closeStaffComment: '',
      closeStaffRating: null,
      closeKitchenComment: '',
      closeKitchenRating: null,
      closeGuestsComment: '',
      closeGuestsRating: null,
      closeMiscComment: '',
      closeMiscRating: null,
    });

    onMounted(async () => {
      const response = await client.get<Journal>(`${API_URL}/journals/${props.journalId}`);

      form.closeStaffComment = response.data.closeStaffComment ?? '';
      form.closeStaffRating = response.data.closeStaffRating ?? null;
      form.closeKitchenComment = response.data.closeKitchenComment ?? '';
      form.closeKitchenRating = response.data.closeKitchenRating ?? null;
      form.closeGuestsComment = response.data.closeGuestsComment ?? '';
      form.closeGuestsRating = response.data.closeGuestsRating ?? null;
      form.closeMiscComment = response.data.closeMiscComment ?? '';
      form.closeMiscRating = response.data.closeMiscRating ?? null;
    });

    async function save(data: Partial<QuestionsForm> | null) {
      if (!data) return;

      const response = await client.put<Journal>(`${API_URL}/journals/${props.journalId}`, data);
      questionsData.value = null;
      return response.data;
    }

    const { isLoading, error, data } = useAsync(save, questionsData);

    watch(data, () => {
      if (data.value?.closeStaffRating) {
        form.closeStaffRating = data.value.closeStaffRating;
      }
      if (data.value?.closeStaffComment) {
        form.closeStaffComment = data.value.closeStaffComment ?? '';
      }
      if (data.value?.closeKitchenRating) {
        form.closeKitchenRating = data.value.closeKitchenRating;
      }
      if (data.value?.closeKitchenComment) {
        form.closeKitchenComment = data.value.closeKitchenComment ?? '';
      }
      if (data.value?.closeGuestsRating) {
        form.closeGuestsRating = data.value.closeGuestsRating;
      }
      if (data.value?.closeGuestsComment) {
        form.closeGuestsComment = data.value.closeGuestsComment ?? '';
      }
      if (data.value?.closeMiscRating) {
        form.closeMiscRating = data.value.closeMiscRating;
      }
      if (data.value?.closeMiscComment) {
        form.closeMiscComment = data.value.closeMiscComment ?? '';
      }
    });

    function submitStaff() {
      const { closeStaffRating, closeStaffComment } = form;
      if (closeStaffRating === null) {
        staffMessage.value = 'Selecteer een smiley om de opmerking te kunnen verzenden';
      } else if (!closeStaffComment) {
        kitchenMessage.value = 'Voer een tekst in om te kunnen verzenden';
      } else {
        staffMessage.value = '';
        questionsData.value = {
          closeStaffRating,
          closeStaffComment,
        };
      }
    }

    function submitKitchen() {
      const { closeKitchenRating, closeKitchenComment } = form;
      if (closeKitchenRating === null) {
        kitchenMessage.value = 'Selecteer een smiley om te kunnen verzenden';
      } else if (!closeKitchenComment) {
        kitchenMessage.value = 'Voer een tekst in om te kunnen verzenden';
      } else {
        kitchenMessage.value = '';
        questionsData.value = {
          closeKitchenRating,
          closeKitchenComment,
        };
      }
    }

    function submitGuests() {
      const { closeGuestsRating, closeGuestsComment } = form;
      if (closeGuestsRating === null) {
        guestsMessage.value = 'Selecteer een smiley om de opmerking te kunnen verzenden';
      } else if (!closeGuestsComment) {
        kitchenMessage.value = 'Voer een tekst in om te kunnen verzenden';
      } else {
        guestsMessage.value = '';
        questionsData.value = {
          closeGuestsRating,
          closeGuestsComment,
        };
      }
    }

    function submitMisc() {
      const { closeMiscRating, closeMiscComment } = form;
      if (closeMiscRating === null) {
        miscMessage.value = 'Selecteer een smiley om de opmerking te kunnen verzenden';
      } else if (!closeMiscComment) {
        kitchenMessage.value = 'Voer een tekst in om te kunnen verzenden';
      } else {
        miscMessage.value = '';
        questionsData.value = {
          closeMiscRating,
          closeMiscComment,
        };
      }
    }

    return {
      isLoading,
      error,
      staffMessage,
      kitchenMessage,
      guestsMessage,
      miscMessage,
      submitStaff,
      submitKitchen,
      submitGuests,
      submitMisc,
      form,
    };
  },
});
