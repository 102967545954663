import {
  UpdateInventoryItemSettingsParams,
  UpdateOccupanceRangeSettingsParams,
  UpdateReservationBookSettingsParams,
  UpdateWeatherSettingsParams,
  UpdateDaypartSettingsParams,
} from '@/api/types';
import { computed, ref } from '@vue/composition-api';
import { UseEstablishmentSettings, UseEstablishmentSettingsErrors } from '../types';
import {
  updateWeatherSettings as updateWeatherSettingsApi,
  updateReservationBookSettings as updateReservationBookSettingsApi,
  updateInventoryItemSettings as updateInventoryItemSettingsApi,
  updateOccupanceRangeSettings as updateOccupanceRangeSettingsApi,
  updateDaypartSettings as updateDaypartSettingsApi,
} from '@/api';
import { Establishment } from '@/common';
import { useMutations } from '@u3u/vue-hooks';
import { MutationTypes } from '@/store/modules/establishments/mutation-types';

const useEstablishmentSettings = (): UseEstablishmentSettings => {
  const { SET_ESTABLISHMENTS } = useMutations('establishments', [MutationTypes.SET_ESTABLISHMENTS]);

  const establishment = ref<Establishment | undefined>();
  const isLoading = ref(false);
  const error = ref<UseEstablishmentSettingsErrors>({});

  const updateWeatherSettings = async (params: UpdateWeatherSettingsParams): Promise<void> => {
    try {
      error.value.weather = undefined;
      isLoading.value = true;
      establishment.value = await updateWeatherSettingsApi(params);
      SET_ESTABLISHMENTS(establishment.value);
    } catch (e) {
      error.value.weather = e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateReservationBookSettings = async (
    params: UpdateReservationBookSettingsParams,
  ): Promise<void> => {
    try {
      error.value.reservationBook = undefined;
      isLoading.value = true;
      establishment.value = await updateReservationBookSettingsApi(params);
      SET_ESTABLISHMENTS(establishment.value);
    } catch (e) {
      error.value.reservationBook = e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateInventoryItemSettings = async (
    params: UpdateInventoryItemSettingsParams,
  ): Promise<void> => {
    try {
      error.value.inventoryItems = undefined;
      isLoading.value = true;
      establishment.value = await updateInventoryItemSettingsApi(params);
      SET_ESTABLISHMENTS(establishment.value);
    } catch (e) {
      error.value.inventoryItems = e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateOccupanceRangeSettings = async (
    params: UpdateOccupanceRangeSettingsParams,
  ): Promise<void> => {
    try {
      error.value.occupanceRanges = undefined;
      isLoading.value = true;
      establishment.value = await updateOccupanceRangeSettingsApi(params);
      SET_ESTABLISHMENTS(establishment.value);
    } catch (e) {
      error.value.occupanceRanges = e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateDaypartSettings = async (params: UpdateDaypartSettingsParams): Promise<void> => {
    try {
      error.value.daypart = undefined;
      isLoading.value = true;
      establishment.value = await updateDaypartSettingsApi(params);
      SET_ESTABLISHMENTS(establishment.value);
    } catch (e) {
      error.value.daypart = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    establishment: computed(() => establishment.value),
    isLoading: computed(() => isLoading.value),
    error: computed(() => error.value),
    updateWeatherSettings,
    updateReservationBookSettings,
    updateInventoryItemSettings,
    updateOccupanceRangeSettings,
    updateDaypartSettings,
  };
};

export default useEstablishmentSettings;
