import client from './client';
import { Journal, JournalNote, JournalDto, IncidentReport } from '@/common/types';

const resource = 'journals';

export default {
  async findAll() {
    const response = await client.get<Journal[]>(`/${resource}`);
    return response.data;
  },

  async findAllForEstablishment(establishmentId: number) {
    const response = await client.get<Journal[]>(`/establishments/${establishmentId}/journals`);
    return response.data;
  },

  async findAllForEstablishmentByDate(establishmentId: number, dob: string) {
    const params = { dob };
    const response = await client.get<Journal[]>(`establishments/${establishmentId}/journals`, {
      params,
    });
    return response.data;
  },

  async getByDateRange(establishmentId: number, from: string, to: string) {
    const params = { from, to };
    const response = await client.get<Journal[]>(`/establishments/${establishmentId}/journals`, {
      params,
    });
    return response.data;
  },

  async createJournal(establishmentId: number, journal: Partial<JournalDto>) {
    const response = await client.post<Journal>(
      `/establishments/${establishmentId}/journals`,
      journal,
    );
    return response.data;
  },

  async updateJournal(journalId: number, journal: Partial<Journal>) {
    const response = await client.put<Journal>(`/${resource}/${journalId}`, journal);
    return response.data;
  },

  // NOTES
  async getNotes(journalId: number) {
    const response = await client.get<JournalNote[]>(`/${resource}/${journalId}/notes`);
    return response.data;
  },

  async createNote(journalId: number, note: JournalNote) {
    const response = await client.post<JournalNote>(`/${resource}/${journalId}/notes`, note);
    return response.data;
  },

  // INCIDENTS
  async getIncidents(journalId: number) {
    const response = await client.get<IncidentReport[]>(`/${resource}/${journalId}/incidents`);
    return response.data;
  },
};
