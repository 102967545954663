import { findById } from '@/api';
import { Journal } from '@/common';
import { useEstablishments } from '@/util/establishments';
import { ref } from '@vue/composition-api';
import { UseJournal, UseJournalErrors } from '../types';

const useJournal = (): UseJournal => {
  const journal = ref<Journal | undefined>();
  const isLoading = ref(false);
  const error = ref<UseJournalErrors>({});
  const { establishment } = useEstablishments();

  const fetch = async (journalId: number): Promise<void> => {
    try {
      isLoading.value = true;
      journal.value = await findById(journalId);
      journal.value.daypart = establishment.value?.dayparts?.find(
        daypart => daypart.name.toLowerCase() === journal.value?.name.toLowerCase(),
      );
    } catch (e) {
      error.value.fetch = e;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    journal,
    isLoading,
    error,
    fetch,
  };
};

export default useJournal;
