import { MutationTree } from 'vuex';
import { EstablishmentSelectState } from '.';
import { MutationTypes } from './mutation-types';
import { Establishment } from '@/common';
import Vue from 'vue';
import { isArray } from 'lodash';

export default {
  [MutationTypes.SET_ESTABLISHMENTS](state, payload: Establishment | Establishment[]) {
    payload = !isArray(payload) ? [payload] : payload;
    for (const e of payload) {
      Vue.set(state.establishments, e.id, e);
    }
  },
} as MutationTree<EstablishmentSelectState>;
