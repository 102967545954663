import { Daypart, Establishment } from '@/common';
import { format, parseUTC } from '@/util/date';

const normalizeDayparts = (dto: Daypart): Daypart => {
  return {
    ...dto,
    start: format(parseUTC(dto.start), 'HH:mm:ss'),
    end: format(parseUTC(dto.end), 'HH:mm:ss'),
  };
};

const normalizeEstablishment = (dto: Establishment): Establishment => {
  return {
    ...dto,
    dayparts: dto.dayparts?.map(normalizeDayparts),
  };
};

export default normalizeEstablishment;
