

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Establishment } from '@/common/types';
import { getISOWeek, startOfWeek, endOfWeek, format } from 'date-fns';
import { addWeeks, subWeeks } from 'date-fns/esm';

@Component
export default class HeaderWidget extends Vue {
  @Prop({ required: true, type: Date })
  public date!: Date;
  @Prop({ required: true, type: Object as () => Establishment })
  public establishment!: Establishment;

  public showCalendar = false;

  get innerDate() {
    return format(this.date, 'yyyy-MM-dd');
  }

  set innerDate(date: string) {
    this.$router.replace({
      name: 'dashboard',
      params: { establishmentId: String(this.establishment.id) },
      query: { date },
    });
    this.showCalendar = false;
  }

  get weekNumber() {
    return getISOWeek(this.date);
  }

  get formatStartOfWeek() {
    return format(startOfWeek(this.date, { weekStartsOn: 1 }), 'dd-MM-yyyy');
  }

  get formatEndOfWeek() {
    return format(endOfWeek(this.date, { weekStartsOn: 1 }), 'dd-MM-yyyy');
  }

  public next() {
    this.innerDate = format(startOfWeek(addWeeks(this.date, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd');
  }

  public prev() {
    this.innerDate = format(startOfWeek(subWeeks(this.date, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd');
  }
}
