
import WeatherUpdateForm from './components/general/WeatherUpdateForm.vue';
import InventoryItemsEditForm from './components/general/InventoryItemsEditForm.vue';
import { defineComponent } from '@vue/composition-api';
import { useEstablishments } from '@/util/establishments';

export default defineComponent({
  components: {
    WeatherUpdateForm,
    InventoryItemsEditForm,
  },
  setup() {
    const { establishment } = useEstablishments();

    return {
      establishment,
    };
  },
});
