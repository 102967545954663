import client from '../client';
import useAxios from '@/util/axios';
import { formatDateISO } from '@/util/date';
import { CachedWeatherData, City, CurrentForecastWeatherData } from './types';

const { getCancelToken } = useAxios();

export async function getCity(id: number, signal?: AbortSignal) {
  const response = await client.get<City>(`/weather/cities/${id}`, {
    cancelToken: getCancelToken(signal),
  });
  return response.data;
}

export async function getCities(q: string, signal?: AbortSignal) {
  const params = { q };
  const response = await client.get<City[]>('/weather/cities', {
    params,
    cancelToken: getCancelToken(signal),
  });
  return response.data;
}

export async function getCurrentWeather(establishmentId: number, signal?: AbortSignal) {
  const response = await client.get<CurrentForecastWeatherData>(
    `/establishments/${establishmentId}/weather/current`,
    {
      cancelToken: getCancelToken(signal),
    },
  );
  return response.data;
}

export async function getForecastWeather(
  establishmentId: number,
  date: Date,
  signal?: AbortSignal,
) {
  const dateStr = formatDateISO(date);
  const response = await client.get<CachedWeatherData>(
    `/establishments/${establishmentId}/weather/forecast/${dateStr}`,
    {
      cancelToken: getCancelToken(signal),
    },
  );
  return response.data;
}

export async function getHistoricalWeather(
  establishmentId: number,
  start: Date,
  end: Date,
  signal?: AbortSignal,
) {
  const params = { start: formatDateISO(start), end: formatDateISO(end) };
  const response = await client.get<CachedWeatherData[]>(
    `/establishments/${establishmentId}/weather/historical`,
    {
      params,
      cancelToken: getCancelToken(signal),
    },
  );
  return response.data;
}

export async function currentWeather(id: number, signal?: AbortSignal) {
  const response = await client.get<CurrentForecastWeatherData>(`/weather/${id}/current`, {
    cancelToken: getCancelToken(signal),
  });
  return response.data;
}

export async function historicalWeatherDates(
  id: number | string,
  start: Date,
  end: Date,
  signal?: AbortSignal,
) {
  const params = { start: formatDateISO(start), end: formatDateISO(end) };
  const response = await client.get<CachedWeatherData[]>(`/weather/${id}/historical`, {
    params,
    cancelToken: getCancelToken(signal),
  });
  return response.data;
}
