import axios, { AxiosError } from 'axios';

export function useCancelToken(signal?: AbortSignal, message = 'Request Cancelled') {
  if (!signal) {
    return undefined;
  }
  const cancelToken = axios.CancelToken.source();
  signal.addEventListener('abort', () => cancelToken.cancel(message));
  return cancelToken.token;
}

export default function useAxios() {
  function isCancelError(error: AxiosError) {
    return axios.isCancel(error);
  }

  function getCancelToken(signal?: AbortSignal, message = 'Request Cancelled') {
    return useCancelToken(signal, message);
  }

  return {
    isCancelError,
    getCancelToken,
  };
}
