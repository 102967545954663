import client from '@/api/client';
import { OccupationEmployeePlanning } from '@/common';

const updateOccupanceDepartments = async (
  establishmentId: number,
  data: OccupationEmployeePlanning,
): Promise<OccupationEmployeePlanning> => {
  const { id, ...body } = data;
  const response = await client.put<OccupationEmployeePlanning>(
    `/establishments/${establishmentId}/occupance/planning/${id}`,
    body,
  );
  return response.data;
};

export default updateOccupanceDepartments;
