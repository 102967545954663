

















































































































import { defineComponent, computed } from '@vue/composition-api';
import { useAsync } from 'vue-async-function';
import useInterval from '@/util/interval';
import useJournals from '@/hooks/useJournals';
import { getReservations } from '@/api/reservations';
import { GetPrognosisByDate } from '@/api/prognosis';
import { ReservationType } from '@/common';
import ReservationsIcon from './icons/reservation.svg';

interface FetchReservationParams {
  establishment: number;
  from: string;
  to: string;
}

interface FetchPrognosisParams {
  establishment: number;
  dob: string;
}

interface ReservationTypeStats {
  type: ReservationType;
  reservations: number;
  people: number;
}

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  components: {
    ReservationsIcon,
  },
  setup(props) {
    const { journal, fetchJournal } = useJournals();

    fetchJournal(props.journalId);

    const reservationParams = computed<FetchReservationParams | null>(() => {
      if (!journal.value) return null;

      return {
        establishment: journal.value.establishmentId,
        from: journal.value.dob.substring(0, 10),
        to: journal.value.dob.substring(0, 10),
      };
    });

    async function fetchReservations(params: FetchReservationParams | null) {
      if (!params) return;
      const { establishment, from, to } = params;

      return await getReservations(establishment, from, to);
    }

    const {
      isLoading: isReservationsLoading,
      error: reservationsError,
      data: reservations,
      retry: reloadReservations,
    } = useAsync(fetchReservations, reservationParams);

    const prognosisParams = computed<FetchPrognosisParams | null>(() => {
      if (!journal.value) return null;

      return {
        establishment: journal.value.establishmentId,
        dob: journal.value.dob.substring(0, 10),
      };
    });

    async function fetchPrognosis(params: FetchPrognosisParams | null) {
      if (!params) return;
      const { establishment, dob } = params;
      return await GetPrognosisByDate(establishment, dob);
    }

    const {
      isLoading: isPrognosisLoading,
      error: prognosisError,
      data: prognosisData,
      retry: reloadPrognosis,
    } = useAsync(fetchPrognosis, prognosisParams);

    useInterval(() => {
      reloadReservations();
      reloadPrognosis();
    }, 1000 * 60);

    const statsMap = computed(() => {
      const result = new Map<ReservationType, ReservationTypeStats>();
      if (!reservations.value) return result;

      return reservations.value.reduce((result, reservation) => {
        const type = reservation.type;
        const stat = result.get(type) ?? { type: reservation.type, reservations: 0, people: 0 };
        stat.reservations += 1;
        stat.people += reservation.amountOfPeople;
        result.set(type, stat);
        return result;
      }, new Map<ReservationType, ReservationTypeStats>());
    });

    const total = computed(() => {
      if (!journal.value?.name) {
        return 0;
      }

      if (journal.value.name.toLowerCase() === 'dag') {
        return statsMap.value.get(ReservationType.LUNCH)?.people ?? 0;
      } else {
        return statsMap.value.get(ReservationType.DINNER)?.people ?? 0;
      }
    });

    const range = computed(() => {
      if (journal.value?.name.toLowerCase() === 'dag') {
        const guests = prognosisData.value?.find(x => x.type?.name === 'Lunch Gast')?.guests ?? -1;

        if (guests < 0) return '-';
        if (guests < 40) return '0-40';
        if (guests < 70) return '40-70';
        if (guests < 100) return '70-100';
        if (guests < 140) return '100-140';
        if (guests < 180) return '140-180';
        if (guests < 220) return '180-220';
        if (guests < 250) return '220-250';
        if (guests < 300) return '250-300';
        if (guests < 350) return '300-350';
        else return '350+';
      } else {
        const guests = prognosisData.value?.find(x => x.type?.name === 'Diner Gast')?.guests ?? -1;

        if (guests < 0) return '-';
        if (guests < 40) return '0-40';
        if (guests < 70) return '40-70';
        if (guests < 100) return '70-100';
        if (guests < 120) return '100-120';
        if (guests < 150) return '120-150';
        if (guests < 180) return '150-180';
        if (guests < 220) return '180-220';
        if (guests < 260) return '220-260';
        if (guests < 300) return '260-300';
        else return '300+';
      }
    });

    const loading = computed(() => {
      return isReservationsLoading.value || isPrognosisLoading.value;
    });

    const error = computed(() => {
      return reservationsError.value || prognosisError.value;
    });

    function getIcon(type: string) {
      if (type === ReservationType.DINNER) {
        return 'utensils';
      }
      if (type === ReservationType.LUNCH) {
        return 'utensils';
      }
      if (type === ReservationType.DRINKS) {
        return 'glass-cheers';
      }
      if (type === ReservationType.HIGH_WINE_BEER) {
        return 'beer';
      }
      if (type === ReservationType.HIGH_TEA) {
        return 'coffee';
      }
      if (type === ReservationType.OTHER) {
        return 'question';
      }
    }

    function formatTime(rawTime: string | null) {
      if (!rawTime) return;
      let timeNumber = parseInt(rawTime.slice(0, 2), 10);
      if (timeNumber > 23) {
        timeNumber = timeNumber - 24;
        rawTime = timeNumber < 10 ? '0' + timeNumber.toString() : timeNumber.toString();
        rawTime = rawTime + ':00:00';
      }
      return rawTime.substring(0, 5);
    }

    return {
      loading,
      error,
      reservations,
      total,
      range,
      getIcon,
      formatTime,
    };
  },
});
