
























import { defineComponent } from '@vue/composition-api';
import useIncidentReports from '@/hooks/useIncidentReports';

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { incidentReports } = useIncidentReports(props.journalId);
    return {
      incidentReports,
    };
  },
});
