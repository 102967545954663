import client from '../client';
import { ReservationBook, Reservation } from '@/common';

export async function getBooks() {
  const response = await client.get<ReservationBook[]>(`/reservations/books`);
  return response.data;
}

export async function getReservations(establishment: number, from: string, to: string) {
  const params = { from, to };
  const response = await client.get<Reservation[]>(
    `/establishments/${establishment}/reservations`,
    {
      params,
    },
  );
  return response.data;
}
