import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/views/routes';
import { CustomNavigationClient } from './navigationClient';
import { useAuth } from '@/composables';

Vue.use(Router);

const unmatched = '/:pathMatch(.*)*';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: unmatched,
      redirect: '/',
    },
  ],
});

const client = new CustomNavigationClient(router);

router.beforeEach(async (to, from, next) => {
  // 404
  if (to.matched[0]?.path === unmatched) {
    next();
  }

  const requiresAuth = to.meta?.requiresAuth ?? true;
  if (requiresAuth) {
    const { account, initialized, initialize, login } = useAuth();

    // initialize authentication
    if (!initialized.value) {
      await initialize(client);
    }

    // authorised
    if (account.value) {
      return next();
    }

    // unauthorized
    try {
      await login();
      return next();
    } catch (e) {
      return next(false);
    }
  }

  next();
});

export default router;
