
import { Daypart } from '@/common';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    daypart: {
      type: Object as PropType<Daypart>,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    function removeRow() {
      emit('remove-row', props.index);
    }

    return {
      props,
      removeRow,
    };
  },
});
