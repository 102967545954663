import { parseUTC, formatTime } from '@/util/date';
import { Shift } from '@/common';
import { ShiftDto } from './types';

export function normalize(dto: ShiftDto): Shift {
  return {
    ...dto,
    date: parseUTC(dto.date),
    start: formatTime(parseUTC(dto.start)),
    end: formatTime(parseUTC(dto.end)),
  };
}
