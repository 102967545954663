export type AlohaEstablishment = 'WALRUS_SN' | 'WALRUS_LWD';

export enum WindUnit {
  KMH = 'KMH',
  MS = 'MS',
  BFT = 'BFT',
}
export interface Establishment {
  id: number;
  name: string;
  nostradamusId?: string;
  weatherCityId?: number;
  weatherWindUnit?: WindUnit;
  weatherWindThreshold?: number;
  maxWindspeed?: number;
  reservationsBookId?: number;
  changeBin?: number;
  count?: Count[];
  departments?: Department[];
  teams?: Team[];
  dayparts?: Daypart[];
  inventoryItems?: InventoryItem[];
  occupanceRanges?: OccupanceRange[];
  occupationPlanning?: OccupationEmployeePlanning[];
  occupanceDepartments?: OccupanceDepartment[];
}

export interface Daypart {
  id?: number;
  establishmentId: number;
  name: string;
  start: string;
  end: string;
  establishment?: Establishment;
}

export interface InventoryItem {
  id: number;
  establishmentId: number;
  name: string;
  expectedNumber?: number;
  establishment?: Establishment;
}

export interface OccupanceRange {
  id?: number;
  establishmentId: number;
  daypartId: number;
  name: string;
  lower: number;
  upper: number;
  establishment?: Establishment;
  daypart?: Daypart;
}

export interface OccupanceDepartment {
  id?: number;
  establishmentId: number;
  name: string;
  teams: Team[];
}

export interface OccupationEmployeePlanning {
  id?: number;
  establishmentId: number;
  departmentId: number;
  occupanceRangeId: number;
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  establishment?: Establishment;
  department?: Department;
  occupanceRange?: OccupanceRange;
}

interface Count {
  id?: number;
  name: string;
  value: number;
}

export interface Department {
  id?: number;
  establishmentId: number;
  name: string;
  establishment?: Establishment;
  teams?: Team[];
}

export interface Team {
  id: number;
  establishmentId: number;
  departmentId: number;
  name: string;
  establishment?: Establishment;
  department?: Department[];
}

export interface Employee {
  id: number;
  establishmentId: number;
  departmentId?: number;
  externalId: string;
  name: string;
  dateOfBirth?: string;
  gender: string;
  start: string;
  end?: string;
  function?: number;
  hourlySalary: number;
  salaryRate: number;
  clockedIn?: {
    enterTime: string;
    exitTime?: string;
  };
  establishment?: Establishment;
  department?: Department;
}

export interface Shift {
  id: number;
  establishmentId?: number;
  employeeId?: number;
  departmentId?: number;
  teamId?: number;
  workspace?: string;
  externalId?: string;
  date: Date;
  start: string;
  end: string;
  hours: number;
  costs: number;
  establishment?: Establishment;
  employee?: Employee;
  department?: Department;
  team?: Team;
  dayparts?: Partial<Daypart[]>;
}

export interface Journal {
  id: number;
  establishmentId: number;
  dob: string;
  daypart?: Daypart;
  name: string;
  rating?: number;
  closedAt?: string;
  startUpKitchenComment?: string;
  startUpKitchenRating?: number;
  startUpServiceComment?: string;
  startUpServiceRating?: number;
  startUpComment?: string;
  startUpRating?: number;
  closeStaffComment?: string;
  closeStaffRating?: number;
  closeKitchenComment?: string;
  closeKitchenRating?: number;
  closeGuestsComment?: string;
  closeGuestsRating?: number;
  closeMiscComment?: string;
  closeMiscRating?: number;
  establishment?: Establishment;
  shiftReviews?: ShiftReview[];
  journalNotes?: JournalNote[];
  incidentReports?: IncidentReport[];
}

export interface PlanningPrognosisRange {
  department: Department;
  daypart: Daypart;
  occupanceRange?: string;
  limit: number | undefined;
}
export interface Prognosis {
  establishmentId: number;
  typeId: number;
  date: Date;
  guests: number;
  spending: number;
  turnover: number;
  establishment?: Establishment;
  type?: PrognosisType;
}

export interface PrognosisType {
  id: number;
  establishmentId: number;
  parentId: number;
  name: string;
  establishment?: Establishment;
  parent?: PrognosisType;
}

export interface JournalDto {
  id: number;
  dob: string;
  name: string;
  rating: number;
  closedAt: string;
  establishment?: Establishment;
}

export interface ShiftReview {
  id: number;
  journalId: number;
  shiftId: number;
  rating: number;
  comment?: string;
  sick: boolean;
  sicknessComment?: string;
  journal?: Journal;
  shift?: Shift;
  tags?: Tag[];
  floorplanTags?: Tag[];
}

export type TagType = 'INCIDENT_REPORT' | 'SHIFT_REVIEW' | 'FLOORPLAN';

export interface Tag {
  id: number;
  establishmentId: number;
  type: TagType;
  name: string;
}

export interface Incident {
  id: number;
  establishmentId: number;
  name: string;
  establishment?: Establishment;
  incidentReports?: IncidentReport[];
}

export interface IncidentReport {
  id: number;
  journalId: number;
  incidentId: number;
  employeeId?: number;
  description?: string;
  action?: string;
  journal?: Journal;
  incident?: Incident;
  employee?: Employee;
  tags?: Tag[];
}

export interface IncidentReportDto {
  description?: string;
  action?: string;
  incidentId?: number;
  employeeId?: number;
  tags?: number[];
}

export interface JournalNote {
  id: number;
  journalId: number;
  subject: string;
  comment: string;
  rating: number;
  journal?: Journal;
}

export interface Question {
  id: number;
  question: string;
  mandatory: boolean;
  rateable: boolean;
  startDate: string;
  endDate: string | null;
  anwser: QuestionAnwser;
}

export interface QuestionAnwser {
  id: number;
  anwser: string;
  rating: number | null;
}

export interface ReservationBook {
  id: number;
  name: string;
}

export interface Reservations {
  type: string;
  dayPart: Daypart;
  amount: number;
  range?: OccupanceRange;
  list?: Reservation[];
}

export interface Reservation {
  type: ReservationType;
  establishment: string;
  reservationType: string;
  amountOfPeople: number;
  date: string;
  timeFrom: string;
  timeTo: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string;
  email: string;
  phoneNumber: string;
  remarks: string | null;
  companyName: string | null;
  companyInvoice: string | null;
  costCenter: string | null;
  department: string | null;
  tableNumber: string | null;
  employee: string;
}

export interface MonetaryAmount {
  amount: number | null;
  currency: string;
  formatted: string;
}

export enum ReservationType {
  DINNER = 'DINNER',
  LUNCH = 'LUNCH',
  HIGH_WINE_BEER = 'HIGH_WINE_BEER',
  HIGH_TEA = 'HIGH_TEA',
  DRINKS = 'DRINKS',
  OTHER = 'OTHER',
}

export interface Input {
  name: string | null;
  value: number | null;
}

export interface EmployeeForm {
  id: string | null;
  name: string | null;
  log: string | null;
  rating: string | null;
  tag: string | null;
}

export interface ManagerForm {
  id: string | null;
  name: string | null;
}

export interface ActionForm {
  subject: number | null;
  tag: string | null;
  log: string | null;
}

export interface MiscForm {
  log: string | null;
  subject: string | null;
  rating: number | null;
}

export interface IssueForm {
  log: string | null;
  type: string | null;
  subject: string | null;
}

export interface DayForm {
  log: string | null;
  rating: number | null;
}

// HACKKIIIEIEIEIE
export enum DepartmentsLeeuwarden {
  kitchen = '8',
  service = '7',
  manager = '3012',
  dishes = '11',
  cleaning = '9',
  preparation = '3011',
}

export enum DepartmentsSneek {
  kitchen = '2',
  service = '1',
  manager = '3018',
  dishes = '10',
  cleaning = '3',
  preparation = '3011',
}

export interface DateObject {
  today: Date;
  week: {
    number: number;
    startDate: Date;
    endDate: Date;
  };
}

export interface Invoice {
  id: number;
  establishmentId: number;
  checkNo: string;
  checkDob: Date;
  reservationNo?: string;
  debtorNo?: string;
  invoiceNo: string;
  invoiceDate: Date;
  createdDate: Date;
  sentDate?: Date;
  reference?: string;
  contactPerson?: string;
  costCenter?: string;
  department?: string;
  debtorName?: string;
  debtorAddress1?: string;
  debtorAddress2?: string;
  debtorHousenumber?: string;
  debtorPostalCode?: string;
  debtorCity?: string;
  debtorCountry?: string;
  debtorPhone?: string;
  debtorEmail?: string;

  establishment?: Establishment;
  lineItems?: InvoiceLineItem[];
}

export interface InvoiceLineItem {
  id?: number;
  invoiceId?: number;
  description: string;
  quantity: number;
  cost: number;
  tax: number;
}

export interface Receipt {
  date: string;
  check: string;
  guests: number;
  amount: number;
  taxSegments: ReceiptTaxSegment[];
  items: ReceiptItem[];
  printItems: ReceiptItem[];
}

export interface ReceiptItem {
  entryId: number;
  id: number;
  name: string;
  price: number;
  discPrice: number;
  tax: number;
  quantity: number;
  time: string;
}

export interface ReceiptPrintItem {
  name: string;
  price: number;
  quantity: number;
}

export interface ReceiptTaxSegment {
  id: number;
  amount: number;
  rate: number;
}

export interface InvoiceableReceipt {
  id: number;
  employee: string;
  manager: string;
  amount: MonetaryAmount;
  identification: string;
  check: string;
  date: string;
  time: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phone?: string;
  totalInclTaxHigh?: MonetaryAmount;
  totalInclTaxLow?: MonetaryAmount;
  invoice?: Invoice;
}
