import client from '../client';
import { useCancelToken } from '@/util/axios';
import { Employee } from '@/common';

export async function getById(id: number, signal?: AbortSignal) {
  const response = await client.get<Employee>(`/employees/${id}`, {
    cancelToken: useCancelToken(signal),
  });
  return response.data;
}

export async function findAll(establishmentId: number, signal?: AbortSignal) {
  const response = await client.get<Employee[]>(`establishments/${establishmentId}/employees`, {
    cancelToken: useCancelToken(signal),
  });
  return response.data;
}
