import { parseUTC } from '@/util/date';
import { Invoice, InvoiceLineItem } from '@/common';
import { InvoiceDto, InvoiceLineItemDto } from './types';

export function normalizeInvoiceLineItemDto(dto: InvoiceLineItemDto): InvoiceLineItem {
  return {
    ...dto,
  };
}

export function normalizeInvoiceDto(dto: InvoiceDto): Invoice {
  return {
    ...dto,
    checkDob: parseUTC(dto.checkDob),
    invoiceDate: parseUTC(dto.invoiceDate),
    createdDate: parseUTC(dto.createdDate),
    sentDate: dto.sentDate ? parseUTC(dto.sentDate) : undefined,
    lineItems: dto.lineItems?.map(normalizeInvoiceLineItemDto),
  };
}
