import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import Router from 'vue-router';

export class CustomNavigationClient extends NavigationClient {
  private router: Router;

  constructor(router: Router) {
    super();
    this.router = router;
  }

  /**
   * Only called during redirects
   */
  navigateExternal(url: string, options: NavigationOptions): Promise<boolean> {
    return super.navigateExternal(url, options);
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      await this.router.replace(relativePath);
    } else {
      await this.router.push(relativePath);
    }

    return true;
  }
}
