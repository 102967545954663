




































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api';
import MultiSelect from 'vue-multiselect';
import { useAsync } from 'vue-async-function';
import useDebounceRef from '@/util/debounceRef';
import { getCity, getCities } from '@/api/weather';
import { City } from '@/api/weather/types';
import { Establishment, WindUnit } from '@/common';
import { useEstablishmentSettings } from '@/composables';
import { useEstablishments } from '@/util/establishments';

export interface Select {
  value: string;
  text: string;
}

export default defineComponent({
  components: {
    MultiSelect,
  },
  model: {
    prop: 'cityId',
    event: 'update',
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const cityId = ref();
    const searchVal = useDebounceRef('');
    const cities = ref<City[]>([]);
    const { error, isLoading, updateWeatherSettings } = useEstablishmentSettings();
    const { reload: reloadEstablishments } = useEstablishments();

    const { data: city } = useAsync(getCity, cityId);
    watch(city, city => {
      if (city) {
        cities.value.push(city);
      }
    });

    const formData = ref<Partial<Establishment>>({
      weatherCityId: props.establishment.weatherCityId ?? 0,
      weatherWindUnit: props.establishment.weatherWindUnit ?? WindUnit.KMH,
      weatherWindThreshold: props.establishment.weatherWindThreshold ?? 0,
    });

    const unitOptions = ref<Select[]>([
      {
        value: WindUnit.KMH,
        text: 'KM/U',
      },
      {
        value: WindUnit.MS,
        text: 'M/s',
      },
      {
        value: WindUnit.BFT,
        text: 'bft',
      },
    ]);

    watch(
      () => props.establishment.weatherCityId,
      id => {
        if (id && !cities.value.find(c => c.id === id)) {
          cityId.value = id;
        }
      },
      { immediate: true },
    );

    async function search(val: string) {
      if (searchVal.value && val.startsWith(searchVal.value)) {
        return;
      }
      if (val.length > 1) {
        searchVal.value = val;
      }
    }

    const { data, isLoading: isLoadingCities } = useAsync(getCities, searchVal);
    watch(data, data => (cities.value = data ?? []));

    const model = computed({
      get: () => {
        return cities.value.find(b => b.id === props.establishment.weatherCityId);
      },
      set: value => {
        emit('update', value?.id);
      },
    });

    function handleSubmit() {
      updateWeatherSettings({
        establishmentId: props.establishment.id,
        weatherCityId: formData.value?.weatherCityId ?? 0,
        weatherWindUnit: formData.value?.weatherWindUnit ?? WindUnit.KMH,
        weatherWindThreshold: Number(formData.value?.weatherWindThreshold) ?? 0,
      });
    }

    watch(isLoading, isLoading => {
      if (!isLoading && !error.value.weather) {
        reloadEstablishments();
      }
    });

    return {
      search,
      cities,
      isLoading,
      model,
      error,
      isLoadingCities,
      formData,
      unitOptions,
      handleSubmit,
    };
  },
});
