import client from '../client';
import { useCancelToken } from '@/util/axios';
import { Incident } from '@/common';

export async function findAllIncidents(establishmentId: number, signal?: AbortSignal) {
  const result = await client.get<Incident[]>(`/establishments/${establishmentId}/incidents`, {
    cancelToken: useCancelToken(signal),
  });
  return result.data;
}

export async function createIncident(establishmentId: number, incident: string) {
  const result = await client.post<Incident>(`/establishments/${establishmentId}/incidents`, {
    name: incident,
  });
  return result.data;
}
