
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { Daypart, OccupanceDepartment, Team } from '@/common';
import PlanningSidebar from './planningWidget/PlanningSidebar.vue';
import DepartmentComponent from './planningWidget/DepartmentComponent.vue';
import useOccupanceDepartments from '@/composables/useOccupanceDepartments';
import { useJournal, usePlanningPrognosis, useShifts } from '@/composables';

interface Department {
  name: string;
  teams: Team[];
  needed?: number | undefined;
  planned?: number;
}

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  components: {
    PlanningSidebar,
    DepartmentComponent,
  },
  setup(props) {
    const loading = ref(false);
    const error = ref<string | unknown>('');
    const { shifts, get: getShifts } = useShifts();
    const { journal, fetch: fetchJournal } = useJournal();
    const { planningPrognosis, fetch: fetchPlanningPrognosis } = usePlanningPrognosis();
    const {
      departments: occupanceDepartments,
      search: searchOccupanceDepartments,
    } = useOccupanceDepartments();
    const departments = ref<Department[]>([]);

    function getPlanned(teams: Team[], daypart: Daypart | undefined) {
      let amount = 0;

      if (!daypart) {
        return undefined;
      }

      const plannedShifts = shifts.value.filter(shift => {
        if (!shift.dayparts) {
          return;
        }
        if (shift.dayparts.some(shiftDaypart => shiftDaypart?.id === daypart.id)) {
          return shift;
        }
      });

      teams.forEach(team => {
        if (journal.value?.shiftReviews) {
          plannedShifts.forEach(shift => {
            if (shift.team?.id === team.id) {
              amount = amount + 1;
            }
          });
        }
      });
      return amount;
    }

    function getNeeded(occupanceDepartment: OccupanceDepartment) {
      let needed: number | undefined = undefined;
      if (planningPrognosis) {
        planningPrognosis.value.forEach(prog => {
          if (prog.department.id === occupanceDepartment.id) {
            needed = prog.limit;
          }
        });
      }

      return needed;
    }

    onMounted(async () => {
      try {
        loading.value = true;

        await Promise.all([
          await fetchJournal(props.journalId),
          await searchOccupanceDepartments(journal.value?.establishmentId ?? 0),
          await fetchPlanningPrognosis(journal.value?.dob, journal.value?.daypart),
          await getShifts(journal.value?.dob ?? ''),
        ]);

        occupanceDepartments.value.forEach(occupanceDepartment => {
          departments.value.push({
            name: occupanceDepartment.name,
            teams: occupanceDepartment.teams,
            needed: getNeeded(occupanceDepartment),
            planned: getPlanned(occupanceDepartment.teams, journal.value?.daypart),
          });
        });
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      error,
      journal,
      props,
      departments,
    };
  },
});
