import client from '@/api/client';
import { OccupanceDepartmentData } from '@/api/types';
import { OccupanceDepartment } from '@/common';

const updateOccupanceDepartments = async (
  establishmentId: number,
  data: OccupanceDepartmentData,
): Promise<OccupanceDepartment[]> => {
  const { id, ...body } = data;
  const response = await client.put<OccupanceDepartment[]>(
    `/establishments/${establishmentId}/occupance/departments/${id}`,
    body,
  );
  return response.data;
};

export default updateOccupanceDepartments;
