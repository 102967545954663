var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.error !== ''}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"onderwerp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Onderwerp","invalid-feedback":errors[0],"state":!validated || valid ? null : valid}},[_c('incident-select',{attrs:{"establishment-id":_vm.establishmentId},model:{value:(_vm.selectedIncident),callback:function ($$v) {_vm.selectedIncident=$$v},expression:"selectedIncident"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"tags","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Tags","invalid-feedback":errors[0],"state":!validated || valid ? null : valid}},[_c('incident-report-tag-multi-select',{attrs:{"establishment-id":_vm.establishmentId},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"omschrijving","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Omschrijving","invalid-feedback":errors[0],"state":!validated || valid ? null : valid}},[_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Omschrijving","state":!validated || valid ? null : valid},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"door","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Uitgevoerd door","invalid-feedback":errors[0],"state":!validated || valid ? null : valid}},[_c('employee-select',{attrs:{"establishment-id":_vm.establishmentId,"group-clocked-in":""},model:{value:(_vm.selectedEmployee),callback:function ($$v) {_vm.selectedEmployee=$$v},expression:"selectedEmployee"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"actie"}},[_c('b-form-group',{attrs:{"label":"Ondernomen actie"}},[_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Ondernomen actie"},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}})],1)],1)],1)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Opslaan ")],1),(_vm.entity)?_c('b-button',{attrs:{"variant":"danger","disabled":invalid || _vm.loading},on:{"click":_vm.confirmDelete}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Verwijderen ")],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }