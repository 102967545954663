
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { useTags } from '@/util/tags';
import { UseTagsMergeCommand } from '@/util/types';
import ErrorMessage from '@/components/ErrorMessage.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Tag, TagType } from '@/common';

export default defineComponent({
  props: {
    establishmentId: {
      type: Number,
      required: true,
    },
    tagType: {
      type: String as PropType<TagType>,
      required: true,
    },
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
  },
  components: {
    ErrorMessage,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, { emit }) {
    const { isLoading, merge, error } = useTags(`settings-${props.tagType}`);

    const formData = ref<UseTagsMergeCommand>();

    function reset() {
      formData.value = {
        establishmentId: props.establishmentId,
        destinationTagId: 0,
        tagIds: props.tags.map(tag => tag.id),
      };
    }
    reset();

    function submit() {
      if (formData.value) {
        const command = {
          ...formData.value,
          tagIds: formData.value.tagIds.filter(id => id !== formData.value?.destinationTagId),
        };
        merge(command);
      }
    }

    watch(isLoading, isLoading => {
      if (!isLoading && !error.value.merge) {
        emit('merged');
      }
    });

    return {
      isLoading,
      error,
      formData,
      props,
      submit,
    };
  },
});
