














import useJournalNotes from '@/hooks/useJournalNotes';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { journalNotes } = useJournalNotes(props.journalId);
    return {
      journalNotes,
    };
  },
});
