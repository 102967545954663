
import { OccupanceDepartmentData } from '@/api/types';
import { Department, Establishment, Team } from '@/common';
import { useOccupanceDepartments, usePlanningTeams } from '@/composables';
import { defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    department: {
      type: Object as PropType<Department>,
      required: true,
    },
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selected = ref<Team[]>();
    const edit = ref(false);
    const data = ref<OccupanceDepartmentData>({
      id: props.department.id ?? undefined,
      establishmentId: props.establishment.id,
      name: props.department.name,
      teams: props.department.teams?.map(team => {
        return team.id;
      }),
    });

    const { error, isLoading, search, teams } = usePlanningTeams();
    const { update, create, remove } = useOccupanceDepartments();

    function reset() {
      if (props.establishment.id) {
        search(props.establishment.id);
      }
      selected.value = props.department.teams;
    }
    reset();

    function removeRow() {
      if (props.department.id) {
        remove(props.establishment.id, props.department.id);
      }
    }

    function submit() {
      if (!selected.value) {
        return;
      }

      data.value.teams = selected.value.map(team => {
        return team.id;
      });

      if (data.value) {
        if (props.department.id) {
          update(props.establishment.id, data.value);
          edit.value = false;
          emit('update', true);
        } else {
          create(props.establishment.id, data.value);
          edit.value = false;
          emit('update', true);
        }
      }
    }

    return {
      props,
      teams,
      error,
      isLoading,
      selected,
      data,
      edit,
      removeRow,
      submit,
    };
  },
});
