import { parseISO } from 'date-fns';
import { Route } from 'vue-router';
import DashboardView from './DashboardView.vue';

export default [
  {
    path: '/dashboard/:establishmentId',
    name: 'dashboard',
    component: DashboardView,
    props: (route: Route) => {
      const date = parseISO(route.query.date as string);
      return {
        establishmentId: Number(route.params.establishmentId),
        date: isNaN(date.getTime()) ? new Date() : date,
      };
    },
    meta: {
      sidebar: false,
    },
  },
];
