import client from '@/api/client';
import { SearchWageCostsParams, WageCosts, WageCostsDto } from '../types';
import { normalize } from './serialize';

const searchWageCosts = async (searchParams: SearchWageCostsParams): Promise<WageCosts[]> => {
  const { establishmentId, ...params } = searchParams;
  const response = await client.get<WageCostsDto[]>(
    `/establishments/${establishmentId}/wage-costs`,
    { params },
  );
  return response.data.map(normalize);
};

export default searchWageCosts;
