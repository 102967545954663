import client from '../client';
import { useCancelToken } from '@/util/axios';
import { Tag } from '@/common';
import { TagsMergeParams } from '../types';

export async function findAllIncidentReportTags(establishmentId: number, signal?: AbortSignal) {
  const result = await client.get<Tag[]>(
    `/establishments/${establishmentId}/incident-reports/tags`,
    {
      cancelToken: useCancelToken(signal),
    },
  );
  return result.data;
}

export async function createIncidentReportTag(establishmentId: number, tag: string) {
  const result = await client.post<Tag>(
    `/establishments/${establishmentId}/incident-reports/tags`,
    { name: tag },
  );
  return result.data;
}

export async function findAllShiftReviewTags(establishmentId: number, signal?: AbortSignal) {
  const result = await client.get<Tag[]>(`/establishments/${establishmentId}/shift-reviews/tags`, {
    cancelToken: useCancelToken(signal),
  });
  return result.data;
}

export async function createShiftReviewTag(establishmentId: number, tag: string) {
  const result = await client.post<Tag>(`/establishments/${establishmentId}/shift-reviews/tags`, {
    name: tag,
  });
  return result.data;
}

export async function findAllFloorplanTags(establishmentId: number, signal?: AbortSignal) {
  const result = await client.get<Tag[]>(
    `/establishments/${establishmentId}/shift-reviews/floorplan-tags`,
    {
      cancelToken: useCancelToken(signal),
    },
  );
  return result.data;
}

export async function createFloorplanTag(establishmentId: number, tag: string) {
  const result = await client.post<Tag>(
    `/establishments/${establishmentId}/shift-reviews/floorplan-tags`,
    {
      name: tag,
    },
  );
  return result.data;
}

export async function mergeTags(params: TagsMergeParams) {
  const { establishmentId, ...data } = params;

  const result = await client.post<Tag>(`/establishments/${establishmentId}/tags/merge`, data);
  return result.data;
}
